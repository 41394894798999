<div class="innerpage-banner">
   <img src="assets/images/innerpage-banner/awards-banner.jpg">
   <div class="banner-heading">
      <h1>Walk in Centers
      </h1>
      <div class="breadcrumbs">
         <ul class="">
            <li class=""><a [routerLink]="['/']">Home</a></li>
            <li class=""><a [routerLink]="['/']">Residential PNG</a></li>
            <li class=""><a [routerLink]="['/']">Customer Care</a></li>
            <li class="active" aria-current="page">Walk in Centers</li>
         </ul>
      </div>
   </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
   <div class="container custom">
      <div class="row">
         <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
            <app-recent-post></app-recent-post>

            <app-tips></app-tips>

            <div class="mt-30">

               <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                  mahanagargas</a>

            </div>
         </div>
         <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 mb-30" *ngFor="let item of walkcenterArray">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>{{item.title}}</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i> {{item.description}}
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <!-- <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Andheri (W)</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i> Landmark Mourya 2,
                              Unit no. Gr2 and Gr3,
                              CTS No.93, Near infinity mall
                              Link Road, Oshiwara, Mumbai- 400058
                              Landmark: Near Infinity Mall.
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Borivali</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i> B003, Upper Basement Area,
                              Western Edge -2,
                              Western Express Highway,
                              Behind Metro Mall, Borivali (East),
                              Mumbai - 400066
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>

               <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Bandra</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>Pay & Accounts Office,
                              Ground Floor, Near Family Court,
                              Bandra Kurla Complex, Bandra (East)
                              Mumbai - 400051
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Grant Road</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>66K, Ground floor,
                              Soonaji Mansion,
                              BSH Agiary Compound,
                              Off Forjett Street, Gowalia Tank,
                              Grant Road (West),
                              Mumbai - 400007
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>

               <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Kalyan</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>Cafe House,
                              Opp. Sarvodaya CHSL,
                              Vali-Peer Road,
                              Near Bhanu Theatre,
                              Kalyan (West)
                              Dist. Thane - 421301
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>


               <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Mira Road</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>Unit No. G-001 & G-102
                              C Cube Building
                              Harias Dream Park,
                              Near Vedant School
                              Mira Bhayander Road
                              Mira Road (East)
                              Mumbai - 401107
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Mulund</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>Shop No- 10, Ground Floor,
                              Arunodaya Shopping Complex,
                              ArHunodaya Nagar, Mulund (East),
                              Mumbai- 400081
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Sanpada</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>Siddhant, Plot No. 291,
                              Behind Devis Residency,
                              Near NMMC Ward Office,
                              Turbhe Village, Sector- 22,
                              Sanpada, Navi Mumbai - 400705
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Thane</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>101 & 102, Landmark Arcade,
                              Nitin Company Junction,
                              Louis Wadi Service Road,
                              Above Bafna Surgical,
                              Thane (West),
                              Thane - 400604
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Uran</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>Om Sai Apartment
                              Shop No.3 & 4,
                              Opp. Kamal Park,
                              Near Uran Nagar Parishad Office,
                              Mhativali Nagaon,
                              Uran, Maharashtra - 400702                              
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Badlapur</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>Plot C 2/1, 
                              MIDC Badlapur, 
                              1st Floor, 
                              Kharvali CNG Station, 
                              Thane – 421 204                              
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
                 <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                  <div class="share-location-section">
                     <div class="location-details w-100">
                        <h6>Kharghar</h6>
                        <ul>
                           <li><i class="demo-icon uil-location-point"></i>Zion -10th Floor,
                            Golden Highway,
                            Plot No 273, Sector 10,
                            Kharghar,
                            Navi Mumbai                              
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
 -->            </div>
         </div>
      </div>
   </div>
</div>