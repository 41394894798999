import { HttpClient } from '@angular/common/http';
import { map, Observable} from 'rxjs';
import { ComplaintResp } from 'src/app/models/complaint';
import { CategoryResp } from 'src/app/models/cat';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class logcomplaint {

  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  
  getAllGasified(){
    return this.http.get(this.baseUrl+'gasified/getAllGasified')
  }


  postDomConnGasifiedBuild(data:any){
    return this.http.post(this.baseUrl+'gasified/postGasifiedBuild',data)
  }


   postNonGasifiedBuild(data:any){
    return this.http.post(this.baseUrl+'gasified/postNonGasifiedBuild',data)
  }

  UpdateDomConnGasifiedBuildDoc(data:any){
    return this.http.post(this.baseUrl+'gasified/updateGasifiedBuild',data)
  }

  updateNonGasifiedBuild(data:any){
    return this.http.post(this.baseUrl+'gasified/updateNonGasifiedBuild',data)
  }

   UpdateDomConnGasifiedBuildDocNew(data:any){
    return this.http.post(this.baseUrl+'gasified/updateGasifiedBuildNew',data)
  }

   UpdateEmiproof(data:any){
    return this.http.post(this.baseUrl+'gasified/updateEmiproof',data)
  }

   UpdateEmiproofNew(data:any){
    return this.http.post(this.baseUrl+'gasified/updateEmiproofNew',data)
  }

  updatePayamt(data:any){
    return this.http.post(this.baseUrl+'gasified/updatePayamt',data)
  }


  getAddressByPincode(data:any){
    return this.http.post(this.baseUrl+'area/getAddressByPincode',data)
  }



  getAllOutlet(){
    return this.http.get(this.baseUrl+'outlet/get_AllOutlet')
  }


  getAllCng_DealerFeedBack(){
    return this.http.get(this.baseUrl+'outlet/get_AllCng_DealerFeedBack')
  }

  postCngDealerFeedBack(data:any){
    return this.http.post(this.baseUrl+'outlet/postCngDealerFeedback',data)
  }


 getAll(bpnum: any): Observable<ComplaintResp[]> {
    return this.http.post<ComplaintResp[]>(this.baseUrl + 'complaint/get_complaint', { bpnum: bpnum })
    .pipe(map(user => {
      return user;
    }))
  }
 
  getcomp():Observable<CategoryResp[]> {
    return this.http.get<CategoryResp[]>(this.baseUrl+'catergory/get_complainttype');
  }

  getcomplaint(Type_of_complaint: string): Observable<CategoryResp[]> {
    return this.http.post<CategoryResp[]>(this.baseUrl+'catergory/get_compact',{Type_of_complaint:Type_of_complaint})
    .pipe(map(user => {
      return user;
    }))
}

	insertcomplaint(form:any) {
		return this.http.post<ComplaintResp>(`${this.baseUrl}complaint/insert_complaint`,form)
	}


	insertwithoutimage(form:any) {
		return this.http.post<ComplaintResp>(`${this.baseUrl}complaint/insert_withoutimage`,form)
	}
	
	
  getGasifiedCount(){
    return this.http.get(this.baseUrl+'gasified/getGasifiedCount')
  }

  getSingleGasified(data:any){
    return this.http.post(this.baseUrl+'gasified/getSingleDomestic',data)
  }

  getSingleGasifiedTransaction(data:any){
    return this.http.post(this.baseUrl+'gasified/getSingleDomesticTransaction',data)
  }

 get_All_cng_complaint_statuses(data:any){
    return this.http.post(this.baseUrl+'outlet/get_All_cng_complaint_statuses',data)
  }

  
  //**************Code start for Institution***********//

    getAddressByPincode_Institution(data:any){
    return this.http.post(this.baseUrl+'area/getAddressByPincode_Institution',data)
  }


  postGasifiedBuild_Institution(data:any){
    return this.http.post(this.baseUrl+'gasified/postGasifiedBuild_Institution',data)
  }

   updateGasifiedBuild_Institution(data:any){
    return this.http.post(this.baseUrl+'gasified/updateGasifiedBuild_Institution',data)
  }

  getSingleDomestic_Institution(data:any){
    return this.http.post(this.baseUrl+'gasified/getSingleDomestic_Institution',data)
  }

  //**************Code end for Institution***********//
}