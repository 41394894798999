<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>FAQS</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a  [routerLink]="['/']">Home</a></li>
                <li class=""><a > Residential PNG/Industrial & Commercial   </a></li>
                <li class=""><a  >Commercial PNG</a></li>
                <li class="active" aria-current="page"><a>FAQS</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <div class="latestnews-section d-none d-lg-block">
                    <div class="latest-posts customer-aw-faq">

                        <div class="widget-title mb-20">
                            <h3 class="title">Select FAQS</h3>
                        </div>
                        <div class="select-faqs">
                            <a *ngFor="let i of allFaqDetail" (click)="this.FaqService.filterFaq(i.caption)"><i
                                    class="left-icon demo-icon uil-basketball-hoop"></i> {{i.caption}} <i
                                    class="right-icon demo-icon uil-angle-right"></i></a>
                        </div>
                    </div>
                </div>
                <app-tips></app-tips>

                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="row y-middle justify-content-center">
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <label><b>Select FAQS Category</b> </label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="select-area">
                            <select #val (change)="this.FaqService.filterFaq(val.value)" name="">
                                <option value="">--Select Category--</option>
                                <option *ngFor="let i of allFaqDetail" value="{{i.caption}}">{{i.caption}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="light-blue-bg mt-20">
                    <h6>{{this.FaqService.title==''? 'All FAQS' : this.FaqService.title}}</h6>
                    <div class="rs-faq billing-faq">
                        <div class="faq-content">
                            <div *ngIf="this.FaqService.title !== 'No Data'" id="accordion" class="accordion">
                                <div *ngFor="let i of this.FaqService.allFaq,index as j" class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse{{j}}" data-toggle="collapse"
                                            aria-expanded="true">{{i.question}}</a>
                                    </div>
                                    <div id="collapse{{j}}" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span [innerHTML]="i.description"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->