<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/awards-banner.jpg">
  <div class="banner-heading">
    <h1>Life @ MGL</h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a >MGL Corporate</a></li>
        <li class=""><a >Careers</a></li>
        <li class="active" aria-current="page"><a >Life @ MGL</a></li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
        <app-recent-post></app-recent-post>
        <app-tips></app-tips>
        <div class="mt-30">
          <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
            mahanagargas</a>
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
        <div class="mt-40">
          <div class="con">
            <p>We focus on attracting and retaining the young talent in the emerging competitive scenario. Our impetus
              is on Campus selection of fresh Engineering Graduates and sourcing of quality talent through multiple
              channels.</p>
              <div class="blue-tick-list">
                <ul >
                  <li>Transparent and effective communication is given due priority for ensuring team based working and
                    promoting a culture based on trust and confidence. </li>
                  <li>Structured training initiatives are taken in the interest of overall employee’s development.</li>
                  <li>Periodic awareness sessions are arranged for employee’s well-being and good health.</li>
                </ul>
              </div>
            <p>MGL, today has also implemented ESS in SAP working environment.</p>
            <p>At MGL we maintain a congenial working environment by providing stable employment, safe working
              conditions and job satisfaction which encourages the employees to contribute their best.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>