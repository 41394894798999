<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Safety Features</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Safety, Health And Environment</a></li>
                <li class="active" aria-current="page"><a >Safety Features</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>


                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="mt-40">
                    <div class="con">
                        <p>Our Safety Features</p>
                        <div class="blue-tick-list">
                        <ul>
                            <li>Inbuilt Safety Features have been implemented in Basic Steel Piping Grid and MDPE Gas Distribution Network.</li>
                            <li>District Regulating Station (DRS): Device used to reduce the pressure from 19 bar to 4 bar.</li>
                            <li>Service Regulator (SR): Device used to reduce the pressure from 4 bar to 0.1 bar.</li>
                            <li>Meter Regulator: Device used to reduce the pressure from 0.1 bar to 21 mbar and fitted in kitchen.</li>
                            <li>Pressure in the appliance is 21 m bar as against the pressure of gas received at City Gate Station at 19000 mbar.</li>
                            <li>Pipelines have been laid as per MGL's code of Practice.</li>
                            <li>World's best available corrosion protection technique applied in form of 3 layers of PE coating in addition to Cathodic Protection in steel pipe line.</li>
                            <li>Warning tape with "Danger, Gas Main Below" placed 300mm above gas pipeline.</li>
                            <li>Gas Isolation Valves are installed at strategic locations.</li>
                            <li>Regular patrolling of gas network by motorcycle riders to avoid third party damage to pipeline.</li>
                            <li>Automatic overpressure and under pressure shutoff has been installed at City Gate Station, District Regulating Stations and Service Regulators.</li>
                        </ul>
                        </div>
                        <div class="mt-30"><img src="assets/images/safety-features-pic.png" class="w-100"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Main content End -->
</div>