<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Consumer Safety Checklists</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class="active" aria-current="page"><a>Consumer Safety Checklists</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <!-- <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Description"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>

                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="mt-20 mb-lg-0 mb-30">
                    <div class="con">
                        <p >Your Safety is our Concern</p>
                        <p>We want you to enjoy benefits of Natural Gas, the gas which is convenient, clean and
                            versatile fuel. But like all fuels it must be handled wisely to ensure its safety and
                            efficiency</p>

                        <h6>Precautions for daily operation</h6>
                        <div class="blue-tick-list">
                            <ul>
                                <li>Before igniting the hot plate, ensure that there is no gas smell.</li>
                                <li>Ensure that the rubber tube is in good condition and it is properly fixed with hot
                                    plate/ gas stove and appliance valve.</li>
                                <li>Open the appliance valve first, then the knob of the hot plate/ gas stove and ignite
                                    the
                                    burner immediately.</li>
                                <li>Hot utensils/ hot items should not touch rubber tube.</li>
                                <li>After cooking make a practice to close the appliance valve.</li>
                                <li>Don't use the PNG converted burner on LPG cylinder.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Main content End -->
</div>