<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/awards-banner.jpg">
  <div class="banner-heading">
    <h1>MGL Tez</h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a>CNG</a></li>
        <li class=""><a>Customer Zone</a></li>
        <li class="active" aria-current="page"><a href="#">MGL Tez</a></li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->

<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
        <app-recent-post></app-recent-post>

        <app-tips></app-tips>

        <div class="mt-30">

          <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
            mahanagargas</a>


        </div>
      </div>

      <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">

                        <div class="mb-30">
                            <img src="assets/images/Leaflet-MGL-TEZ-Etoken.jpg" class="w-100">
                        </div>
                       
                    </div>
                </div>
            </div>
    </div>
  </div>
</div>