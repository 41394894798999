<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Current Opportunities</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
              <li class=""><a >MGL Corporate</a></li>
              <li class=""><a >Careers</a></li>
                <li class="active" aria-current="page"><a href="#">Current Opportunities
                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>
                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <h5><p>Why career <span style="display: inline-block;"> with us</span></p></h5>
                    <p>MGL is an equal opportunity employer and ensures an inclusive work environment. We believe that
                        our greatest asset is our employees, who play a critical role in creating a long-term value for
                        our stakeholders.</p>
                    <p>Our core values include Excellence, Accountability, Integrity, Innovation and Customer
                        Centricity. Each one of us is committed to add value to MGL and the society. </p>
                    <p>To perform competitively in an ever-evolving industry landscape, MGL requires competent and
                        proficient people to work safely across all its operations.</p>
                    
                    <div class="rs-process style2 pt-10 pb-30 md-pt-70 md-pb-70">
                        <div class="light-blue-bg">
                            <h3>Current Openings</h3>
                            <div class="row">
                                <div *ngIf="Currents.length == 0">0 Current Openings</div>
                                <div class="col-lg-4 col-md-6 mb-30" *ngFor="let i of Currents;let l = index">
                                    <div class="rs-addon-number">
                                        <div class="number-part">
                                            <div class="number-area"> <span class="number-prefix">{{l+1}}.</span></div>
                                            <div class="number-title">
                                                <h3 class="title">{{i.JobPosition}}</h3>
                                            </div>
                                            <div class="number-txt">
                                                <p class="desc" [innerHTML]="i.KeyRoles"></p>
                                                <strong>Location:</strong>{{i.Location}}
                                            </div>
                                            <div class="btn-part">
                                                <a
                                                    [routerLink]="['/MGL-corporate/career/current-opp/carriers-job-apply']">Apply
                                                    Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row y-middle">
                        <div class="col-lg-6 col-md-6">
                            <img src="assets/images/careers/life-at-mgl.png" class="w-100">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h2 class="dual-heading">Ready to take the next step <span style="display: inline;"> and
                                    work together?</span></h2>
                            <a [routerLink]="['/MGL-corporate/career/life-Mgl']" class="bluebtn2">Life @ MGL <i
                                    class="demo-icon uil-arrow-right"></i></a>
                        </div>
                    </div>

                    <h6><b>Disclaimer</b></h6>
                    <p>MGL does not solicit any fees nor money deposits from any job seeker nor requests personal
                        details like Bank account details, etc. We shall not be responsible for any loss or damage
                        caused to the job seekers directly or indirectly while dealing with any such fraudulent
                        entities.</p>
                </div>
            </div>
        </div>
    </div>
</div>    