<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Consumer Safety Checklists</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class="active" aria-current="page"><a>Consumer Safety Checklists</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="mt-40">
                    <div class="box882_left">

                        <div class="con">

                                <div class="accordian_industries">
                                    <!--<h4 style="text-transform: none; line-height: 22px; font-weight: 400;">After Sales Rate (Labour rates) for job inside the customer's premises effective from 15th May, 2017  (This service is available from 10 AM to 6 PM on all days except Sundays and Holidays)</h4>-->
                                    <h4 class="card-link" href="#collapseOne" data-toggle="collapse" aria-expanded="true"
                                        style="text-transform: none; line-height: 22px; font-weight: 400;" class="">After
                                        Sales charges (Labour
                                        charges) for job inside the customer's premises effective from 01st September 2022
                                        to 31st August 2025
                                        (This service is available from 10 AM to 6 PM on all days except Sundays and
                                        Holidays)</h4>
                                    <div id="collapseOne" data-bs-parent="#customer-aw" class="serviceTable collapse show"
                                        style="width: 100%; margin-right: 0px;">
                                        <ul>
                                            <li class="service-charges-box"
                                                style="font-size: 14px; padding: 13px; color: #616161;">Labour
                                                charges for After Sales Services</li>
                                            <li class="service-charges-box"
                                                style="background: #f3f3f3 none repeat scroll 0% 0%;"> <span
                                                    class="service-charges-srno"
                                                    style="color: #616161; text-transform: capitalize; font-weight: 600;">Sr
                                                    no</span> <span class="activity"
                                                    style="color: #616161; text-align: center; text-transform: capitalize; font-weight: 600;">SOR
                                                    description</span> <span class="uom"
                                                    style="color: #616161; text-transform: capitalize; font-weight: 600;">UOM</span>
                                                <span class="amount-rs"
                                                    style="color: #616161; text-transform: capitalize; font-weight: 600;">Rate</span>
                                            </li>
                                            <li> <span class="service-charges-srno">1</span><span class="activity">Removal
                                                    of Meter without
                                                    alteration&nbsp;</span><span class="uom">Each</span><span
                                                    class="amount-rs">320</span> </li>
                                            <li> <span class="service-charges-srno">2</span><span
                                                    class="activity">Installation of Meter without
                                                    alteration/ Meter replacement work</span><span
                                                    class="uom">Each</span><span class="amount-rs">460</span> </li>
                                            <li> <span class="service-charges-srno">3</span><span class="activity">Addition
                                                    / Alteration of
                                                    Copper Tubing work (for Meter shifting/ Gas Geyser work)</span><span
                                                    class="uom">Meter&nbsp;</span><span class="amount-rs">85</span> </li>
                                            <li> <span class="service-charges-srno">4</span><span class="activity">Addition
                                                    / Alteration of GI
                                                    Pipe </span><span class="uom">Meter&nbsp;</span><span
                                                    class="amount-rs">140</span> </li>
                                            <li> <span class="service-charges-srno">5</span><span class="activity">Copper
                                                    tube work up to 5
                                                    meters length (for Gas meter shifting / Installation and /or Removal of
                                                    gas geyser). For
                                                    more than 05 meters length, SOR 3 will also be applicable.</span><span
                                                    class="uom">Each</span><span class="amount-rs">575</span> </li>
                                            <li> <span class="service-charges-srno">6</span><span class="activity">Removal
                                                    of Hole
                                                    piece&nbsp;</span><span class="uom">Each</span><span
                                                    class="amount-rs">400</span> </li>
                                            <li> <span class="service-charges-srno">7</span><span
                                                    class="activity">Installation of Hole
                                                    piece&nbsp;</span><span class="uom">Each</span><span
                                                    class="amount-rs">400</span> </li>
                                            <li> <span class="service-charges-srno">8</span><span class="activity">Temporary
                                                    Disconnection</span><span class="uom">Each</span><span
                                                    class="amount-rs">230</span> </li>
                                            <li> <span class="service-charges-srno">9</span><span
                                                    class="activity">Reconnection of Temp.
                                                    disconnected cases/ Reconnection of reticulated cases</span><span
                                                    class="uom">Each</span><span class="amount-rs">260</span> </li>
                                            <li> <span class="service-charges-srno">10</span><span class="activity">Fixing
                                                    of PVC Clamps
                                                    only</span><span class="uom">Each</span><span
                                                    class="amount-rs">200</span> </li>
                                            <li> <span class="service-charges-srno">11</span><span
                                                    class="activity">Inspection of MGL assets
                                                    against service request</span><span class="uom">Each&nbsp;</span><span
                                                    class="amount-rs">290</span> </li>
                                            <li> <span class="service-charges-srno">12</span><span class="activity">LIV
                                                    Installation /
                                                    Replacement work:</span><span class="uom">Each</span><span
                                                    class="amount-rs">200</span>
                                            </li>
                                            <li> <span class="service-charges-srno">13</span><span class="activity">Visit
                                                    charge (applicable on
                                                    third visit/ technical support request from customer/ for Gas Geyser
                                                    related
                                                    work)</span><span class="uom">Each</span><span
                                                    class="amount-rs">175</span> </li>
                                            <li> <span class="service-charges-srno">14</span><span
                                                    class="activity">Installation/replacement of
                                                    appliance valve</span><span class="uom">Each</span><span
                                                    class="amount-rs">200</span> </li>
                                            <li> <span class="service-charges-srno">15</span><span
                                                    class="activity">Installation/replacement of
                                                    meter brackets</span><span class="uom">Each</span><span
                                                    class="amount-rs">345</span> </li>
                                            <li> <span class="service-charges-srno">16</span><span class="activity">Painting
                                                    of GI riser &amp;
                                                    laterals.</span><span class="uom">Meter&nbsp;</span><span
                                                    class="amount-rs">166</span> </li>
                                            <li> <span class="service-charges-srno">17.A</span><span class="activity">Riser
                                                    testing, leakage
                                                    rectification &amp; clamping of riser.0 to 4th Floor</span><span
                                                    class="uom">per
                                                    meter</span><span class="amount-rs">50</span> </li>
                                            <li> <span class="service-charges-srno">17.B</span><span class="activity">Riser
                                                    testing, leakage
                                                    rectification &amp; clamping of riser 05th floor to 7th
                                                    Floor</span><span class="uom">Per
                                                    meter</span><span class="amount-rs">40</span> </li>
                                            <li> <span class="service-charges-srno">17.C</span><span class="activity">Riser
                                                    testing, leakage
                                                    rectification &amp; clamping of riser 08th Floor to 15th
                                                    Floor</span><span class="uom">per
                                                    meter</span><span class="amount-rs">30</span> </li>
                                            <li> <span class="service-charges-srno">17.D</span><span class="activity">Riser
                                                    testing, leakage
                                                    rectification &amp; clamping of riser- 15th Floor &amp;
                                                    above</span><span class="uom">per
                                                    meter</span><span class="amount-rs">30</span> </li>
                                            <li> <span class="service-charges-srno">18</span><span class="activity">Shifting
                                                    of Service
                                                    regulator up to 3 meter</span><span class="uom">No</span><span
                                                    class="amount-rs">3450</span>
                                            </li>
                                            <li> <span class="service-charges-srno">19.a</span><span class="activity">Laying
                                                    of 32/63 mm PE
                                                    pipeline without reinstatement</span><span
                                                    class="uom">Meter&nbsp;</span><span class="amount-rs">575</span> </li>
                                            <li> <span class="service-charges-srno">19.b</span><span class="activity">Laying
                                                    of 32/63 mm PE
                                                    pipeline with reinstatement</span><span
                                                    class="uom">Meter&nbsp;</span><span class="amount-rs">1035</span> </li>
                                            <li> <span class="service-charges-srno">20</span><span
                                                    class="activity">Installation of Welded
                                                    Riser</span><span class="uom">Meter&nbsp;</span><span
                                                    class="amount-rs">400</span> </li>
                                            <li> <span class="service-charges-srno">21</span><span
                                                    class="activity">Installation of Half round
                                                    concrete guard - labour charges</span><span class="uom">Each</span><span
                                                    class="amount-rs">175</span> </li>
                                            <li> <span class="service-charges-srno">22 a&nbsp;</span><span
                                                    class="activity">GI Lateral work up
                                                    to 2 meters for customer connectivity</span> <span
                                                    class="uom">Each</span><span class="amount-rs">1200</span> </li>
                                            <li> <span class="service-charges-srno">22 b</span><span class="activity">GI
                                                    Lateral work from 2
                                                    mtrs to 3 meters for customer connectivity</span><span
                                                    class="uom">Each</span><span class="amount-rs">1300</span> </li>
                                            <li> <span class="service-charges-srno">22 c</span><span class="activity">GI
                                                    Lateral work from 3
                                                    mtrs to 4 meters for customer connectivity</span><span
                                                    class="uom">Each</span><span class="amount-rs">1380</span> </li>
                                            <li> <span class="service-charges-srno">23</span><span
                                                    class="activity">Replacement of Riser
                                                    isolation valve</span><span class="uom">Each</span><span
                                                    class="amount-rs">290</span> </li>
                                            <p>1. CGST &amp; SGST will be applicable extra.<br>
                                                2.The cost of material is as per the MGL purchased price/rate of MGL
                                                approved vendors.<br>
                                                3. 5% extra charges will be applicable on labour charges for Service
                                                Providers operating in
                                                South Zone i.e. from SION to CSMT &amp; CHURCHGATE</p>
                                        </ul>
                                    </div>
    
    
    
                                    <h4 class="card-link" href="#collapseTwo" data-toggle="collapse" aria-expanded="true"
                                        style="text-transform: none; line-height: 22px; font-weight: 400;" class="">After
                                        Charges to be taken by service providers for stove related services from PNG
                                        customers</h4>
                                        <div id="collapseTwo" data-bs-parent="#customer-aw" class="serviceTable collapse"
                                        style="width: 100%;
                                    margin-right: 0px;">
                                        <ul>
                                            
                                            <li class="service-charges-box"
                                                style="background: #f3f3f3 none repeat scroll 0% 0%;"> <span
                                                    class="service-charges-srno"
                                                    style="color: #616161; text-transform: capitalize; font-weight: 600;">Sr
                                                    no</span> <span class="activity"
                                                    style="color: #616161; text-align: center; text-transform: capitalize; font-weight: 600;">SOR
                                                    NATURE OF SERVICES</span> <span class="uom"
                                                    style="color: #616161; text-transform: capitalize; font-weight: 600;">UNIT</span>
                                                <span class="amount-rs"
                                                    style="color: #616161; text-transform: capitalize; font-weight: 600;">CLASSIFICATION OF GOODS/ SERVICES</span>
                                            </li>
                                            <li> <span class="service-charges-srno">1</span><span class="activity">Removal
                                                    of Meter without
                                                    alteration&nbsp;</span><span class="uom">Each</span><span
                                                    class="amount-rs">320</span> </li>
                                            <li> <span class="service-charges-srno">2</span><span
                                                    class="activity">Installation of Meter without
                                                    alteration/ Meter replacement work</span><span
                                                    class="uom">Each</span><span class="amount-rs">460</span> </li>
                                            <li> <span class="service-charges-srno">3</span><span class="activity">Addition
                                                    / Alteration of
                                                    Copper Tubing work (for Meter shifting/ Gas Geyser work)</span><span
                                                    class="uom">Meter&nbsp;</span><span class="amount-rs">85</span> </li>
                                            <li> <span class="service-charges-srno">4</span><span class="activity">Addition
                                                    / Alteration of GI
                                                    Pipe </span><span class="uom">Meter&nbsp;</span><span
                                                    class="amount-rs">140</span> </li>
                                            <li> <span class="service-charges-srno">5</span><span class="activity">Copper
                                                    tube work up to 5
                                                    meters length (for Gas meter shifting / Installation and /or Removal of
                                                    gas geyser). For
                                                    more than 05 meters length, SOR 3 will also be applicable.</span><span
                                                    class="uom">Each</span><span class="amount-rs">575</span> </li>
                                            <li> <span class="service-charges-srno">6</span><span class="activity">Removal
                                                    of Hole
                                                    piece&nbsp;</span><span class="uom">Each</span><span
                                                    class="amount-rs">400</span> </li>
                                            <li> <span class="service-charges-srno">7</span><span
                                                    class="activity">Installation of Hole
                                                    piece&nbsp;</span><span class="uom">Each</span><span
                                                    class="amount-rs">400</span> </li>
                                            <li> <span class="service-charges-srno">8</span><span class="activity">Temporary
                                                    Disconnection</span><span class="uom">Each</span><span
                                                    class="amount-rs">230</span> </li>
                                            <li> <span class="service-charges-srno">9</span><span
                                                    class="activity">Reconnection of Temp.
                                                    disconnected cases/ Reconnection of reticulated cases</span><span
                                                    class="uom">Each</span><span class="amount-rs">260</span> </li>
                                            <li> <span class="service-charges-srno">10</span><span class="activity">Fixing
                                                    of PVC Clamps
                                                    only</span><span class="uom">Each</span><span
                                                    class="amount-rs">200</span> </li>
                                            <li> <span class="service-charges-srno">11</span><span
                                                    class="activity">Inspection of MGL assets
                                                    against service request</span><span class="uom">Each&nbsp;</span><span
                                                    class="amount-rs">290</span> </li>
                                            <li> <span class="service-charges-srno">12</span><span class="activity">LIV
                                                    Installation /
                                                    Replacement work:</span><span class="uom">Each</span><span
                                                    class="amount-rs">200</span>
                                            </li>
                                            <li> <span class="service-charges-srno">13</span><span class="activity">Visit
                                                    charge (applicable on
                                                    third visit/ technical support request from customer/ for Gas Geyser
                                                    related
                                                    work)</span><span class="uom">Each</span><span
                                                    class="amount-rs">175</span> </li>
                                            <li> <span class="service-charges-srno">14</span><span
                                                    class="activity">Installation/replacement of
                                                    appliance valve</span><span class="uom">Each</span><span
                                                    class="amount-rs">200</span> </li>
                                            <li> <span class="service-charges-srno">15</span><span
                                                    class="activity">Installation/replacement of
                                                    meter brackets</span><span class="uom">Each</span><span
                                                    class="amount-rs">345</span> </li>
                                            <li> <span class="service-charges-srno">16</span><span class="activity">Painting
                                                    of GI riser &amp;
                                                    laterals.</span><span class="uom">Meter&nbsp;</span><span
                                                    class="amount-rs">166</span> </li>
                                            <li> <span class="service-charges-srno">17.A</span><span class="activity">Riser
                                                    testing, leakage
                                                    rectification &amp; clamping of riser.0 to 4th Floor</span><span
                                                    class="uom">per
                                                    meter</span><span class="amount-rs">50</span> </li>
                                            <li> <span class="service-charges-srno">17.B</span><span class="activity">Riser
                                                    testing, leakage
                                                    rectification &amp; clamping of riser 05th floor to 7th
                                                    Floor</span><span class="uom">Per
                                                    meter</span><span class="amount-rs">40</span> </li>
                                            <li> <span class="service-charges-srno">17.C</span><span class="activity">Riser
                                                    testing, leakage
                                                    rectification &amp; clamping of riser 08th Floor to 15th
                                                    Floor</span><span class="uom">per
                                                    meter</span><span class="amount-rs">30</span> </li>
                                            <li> <span class="service-charges-srno">17.D</span><span class="activity">Riser
                                                    testing, leakage
                                                    rectification &amp; clamping of riser- 15th Floor &amp;
                                                    above</span><span class="uom">per
                                                    meter</span><span class="amount-rs">30</span> </li>
                                            <li> <span class="service-charges-srno">18</span><span class="activity">Shifting
                                                    of Service
                                                    regulator up to 3 meter</span><span class="uom">No</span><span
                                                    class="amount-rs">3450</span>
                                            </li>
                                            <li> <span class="service-charges-srno">19.a</span><span class="activity">Laying
                                                    of 32/63 mm PE
                                                    pipeline without reinstatement</span><span
                                                    class="uom">Meter&nbsp;</span><span class="amount-rs">575</span> </li>
                                            <li> <span class="service-charges-srno">19.b</span><span class="activity">Laying
                                                    of 32/63 mm PE
                                                    pipeline with reinstatement</span><span
                                                    class="uom">Meter&nbsp;</span><span class="amount-rs">1035</span> </li>
                                            <li> <span class="service-charges-srno">20</span><span
                                                    class="activity">Installation of Welded
                                                    Riser</span><span class="uom">Meter&nbsp;</span><span
                                                    class="amount-rs">400</span> </li>
                                            <li> <span class="service-charges-srno">21</span><span
                                                    class="activity">Installation of Half round
                                                    concrete guard - labour charges</span><span class="uom">Each</span><span
                                                    class="amount-rs">175</span> </li>
                                            <li> <span class="service-charges-srno">22 a&nbsp;</span><span
                                                    class="activity">GI Lateral work up
                                                    to 2 meters for customer connectivity</span> <span
                                                    class="uom">Each</span><span class="amount-rs">1200</span> </li>
                                            <li> <span class="service-charges-srno">22 b</span><span class="activity">GI
                                                    Lateral work from 2
                                                    mtrs to 3 meters for customer connectivity</span><span
                                                    class="uom">Each</span><span class="amount-rs">1300</span> </li>
                                            <li> <span class="service-charges-srno">22 c</span><span class="activity">GI
                                                    Lateral work from 3
                                                    mtrs to 4 meters for customer connectivity</span><span
                                                    class="uom">Each</span><span class="amount-rs">1380</span> </li>
                                            <li> <span class="service-charges-srno">23</span><span
                                                    class="activity">Replacement of Riser
                                                    isolation valve</span><span class="uom">Each</span><span
                                                    class="amount-rs">290</span> </li>
                                            <p>1. CGST &amp; SGST will be applicable extra.<br>
                                                2.The cost of material is as per the MGL purchased price/rate of MGL
                                                approved vendors.<br>
                                                3. 5% extra charges will be applicable on labour charges for Service
                                                Providers operating in
                                                South Zone i.e. from SION to CSMT &amp; CHURCHGATE</p>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Main content End -->
</div>    