<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Savings ki Shubh Shuruaat
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
				<li class=""><a>CNG</a></li>
                <li class="active" aria-current="page"><a>Savings ki Shubh Shuruaat</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="light-blue-bg">
                    <div class="row">

                        <div class="customer-login-page log-meter-reading">

                            <form [formGroup]="promotionForm">
                                <div class="row">
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Name"
                                                placeholder="Name"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['Name'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Name<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="caNumber"
                                                placeholder="CA Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['caNumber'].errors }" />
                                            <div *ngIf="submitted && promotionForm.controls['caNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['caNumber'].errors.required">Required
                                                    Field
                                                </div>
                                                <div *ngIf="promotionForm.controls['caNumber'].errors.pattern">
                                                    Invalid CA Number </div>
                                                    <div *ngIf="promotionForm.controls.caNumber.hasError('maxlength') && promotionForm.controls.caNumber.touched">
                                                        Invalid CA Number </div>
                                                        <div *ngIf="promotionForm.controls.caNumber.hasError('minlength') && promotionForm.controls.caNumber.touched">
                                                            Invalid CA Number </div>
                                            </div>
                                            <label>CA Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="ContactNumber"
                                                placeholder="ContactNumber"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['ContactNumber'].errors }"
                                                id="ContactNumber" />
                                            <div *ngIf="submitted && promotionForm.controls['ContactNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['ContactNumber'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="promotionForm.controls['ContactNumber'].errors.pattern">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.ContactNumber.hasError('maxlength') && promotionForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.ContactNumber.hasError('minlength') && promotionForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                            </div>
                                            <label>Contact Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Email"
                                                placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submitted && promotionForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                              
                                                <div *ngIf="promotionForm.controls['Email'].errors.pattern">
                                                    Invalid Email ID </div>
                                            </div>
                                            <label>Email</label>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" formControlName="dob"
                                                placeholder="DOB"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['dob'].errors }" />
                                            <div *ngIf="submitted && promotionForm.controls['dob'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['dob'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>DOB<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" formControlName="vehicleregDate"
                                                placeholder="Vehicle Registration Date"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicleregDate'].errors }" />
                                            <div *ngIf="submitted && promotionForm.controls['vehicleregDate'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleregDate'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>Vehicle Registration Date<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>


                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="vehicleNo"
                                                placeholder="Vehicle Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicleNo'].errors }"
                                                id="vehicleNo"/>
                                            <div *ngIf="submitted && promotionForm.controls['vehicleNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleNo'].errors.required">
                                                    Required Field
                                                </div>
                                                 <div *ngIf="promotionForm.controls['vehicleNo'].errors.pattern">
                                                    Invalid Vehicle Number </div>
                                            </div>
                                            <label>Vehicle Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="vehicleModel"
                                                placeholder="Vehicle Model"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicleModel'].errors }" />
                                            <div *ngIf="submitted && promotionForm.controls['vehicleModel'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleModel'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>Vehicle Model</label>
                                        </div>
                                    </div> -->



                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="dlearName"
                                                placeholder="Automobile Dealer Name"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['dlearName'].errors }" />
                                            <div *ngIf="submitted && promotionForm.controls['dlearName'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['dlearName'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>Automobile Dealer Name<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                   
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="vehicleType"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['vehicleType'].errors}"
                                                (change)="getvehicleType()">
                                                <option value="" selected>Select Vehicle Type </option>
                                                <option value="SCV(< 3.5 Ton)">SCV(< 3.5 Ton)</option>
                                                <option value="LCV(3.5 -10 Ton)">LCV(3.5 -10 Ton)</option>
                                                <option value="HCV(>10 Ton)">HCV(>10 Ton)</option>

                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['vehicleType'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleType'].errors">Vehicle Type
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Vehicle Type<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div>
                                            <img src="../../../assets/images/vehicletype/hc-vehicle.png"
                                                class="vehicle-type-img" *ngIf="hcvImgFlag==true">
                                            <img src="../../../assets/images/vehicletype/sc-vehicle.png"
                                                class="vehicle-type-img" *ngIf="scvImgFlag==true">
                                            <img src="../../../assets/images/vehicletype/lc-vehicle.png"
                                                class="vehicle-type-img" *ngIf="lcvImgFlag==true">
                                        </div>

                                    </div>
                                          <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control panupper" formControlName="Pan"
                                                placeholder="PAN Card Number"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['Pan'].errors}" />

                                            <div *ngIf="submitted && promotionForm.controls['Pan'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['Pan'].errors.required">
                                                   PAN Card Number is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['Pan'].errors.pattern">
                                                    Invalid PAN Card Number </div>
                                               
                                            </div>

                                            <label>PAN  Card Number</label>

                                        </div>
                                    </div>
                                      <div class="col-sm-6 form-group">
                                        <label class="font-size11">Upload PAN Card<span
                                            style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'pancard')" class="from-control"
                                                type="file" formControlName="pan_card_img"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['pan_card_img'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['pan_card_img'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['pan_card_img'].errors">PAN Card
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        </div>
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">Vehicle Delivery Challan<span
                                            style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'challan')" class="from-control"
                                                type="file" formControlName="delivery_challan"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['delivery_challan'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['delivery_challan'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['delivery_challan'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                  
                                   
                                  
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="otherDoc"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['otherDoc'].errors}">
                                                <option value="" selected>Select Other Doc Type </option>
                                                <option value="RTO Registration Receipt">RTO Registration Receipt
                                                </option>
                                                <option value="RC Card">RC Card</option>
                                                <option value="Insurance Copy">Insurance Copy</option>
                                              

                                            </select>
                                            <div *ngIf="otherDocFlag==true" class="other-doc-message">
                                                Other Doc Type is Required
                                            </div>
                                            <label>Other Doc Type</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11" for="otherDocimg">Upload Other Doc</label>
                                        <div class="form-floating">

                                            <input (change)="filechange2($event)" class="from-control" type="file"
                                                formControlName="otherDocimg">
                                            <!-- <div *ngIf="otherDocimgFlag==true"
                                                class="invalid-feedback">
                                                <div> Address Proof
                                                    is Required</div>
                                            </div> -->
                                            <div *ngIf="otherDocimgFlag==true" class="other-doc-message">
                                                Other Doc Image is Required
                                            </div>
                                        </div>
                                       

                                    </div>

                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="addProof"
                                                placeholder="Address Proof"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['addProof'].errors }"
                                                id="addProof" />
                                            <div *ngIf="submitted && promotionForm.controls['addProof'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['addProof'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>Address Proof<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->

                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="barcodeNumber"
                                                placeholder="Bar Code Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['barcodeNumber'].errors }"
                                                id="barcodeNumber" />
                                            <div *ngIf="submitted && promotionForm.controls['barcodeNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['barcodeNumber'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="promotionForm.controls['barcodeNumber'].errors.pattern">
                                                    Invalid Bar Code Number </div>
                                                <div *ngIf="promotionForm.controls.barcodeNumber.hasError('maxlength') && promotionForm.controls.barcodeNumber.touched">
                                                    Invalid Bar Code Number </div>
                                                    <div *ngIf="promotionForm.controls.barcodeNumber.hasError('minlength') && promotionForm.controls.barcodeNumber.touched">
                                                        Invalid Bar Code Number </div>
                                            </div>
                                            <label>Bar Code Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">

                                            <input (change)="filechange1($event)" class="from-control" type="file"
                                                formControlName="addProof"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['addProof'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['addProof'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['addProof'].errors"> Address Proof
                                                    is Required</div>
                                            </div>
                                        </div>
                                        <label class="font-size11" for="addProof">Upload Address Proof</label>

                                    </div> -->





                                   
                                    <div class="col-sm-6 form-group" *ngIf="gift_card1Flag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="gift_no1"
                                                placeholder="Gift Card Number"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_no1'].errors}" />
                                            <!-- <div *ngIf="submitted && promotionForm.controls['gift_no1'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_no1'].errors"> Gift Card Number
                                                    is Required</div>
                                            </div> -->
                                            <div *ngIf="submitted && promotionForm.controls['gift_no1'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_no1'].errors.required">
                                                    Gift Card Number is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['gift_no1'].errors.pattern">
                                                    Invalid Gift Card Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no1.hasError('maxlength') && promotionForm.controls.gift_no1.touched">
                                                    Allow Only 16 Digits </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no1.hasError('minlength') && promotionForm.controls.gift_no1.touched">
                                                    Allow Only 16 Digits </div>
                                            </div>

                                            <label for="gift_no1">Gift Card Number-1</label>
                                        </div>
                                    </div>


                                    <div class="col-sm-6 form-group" *ngIf="gift_card1Flag==true">
                                        <label class="font-size11" for="Doc5_IDproof">Upload Gift Card-1</label>
                                        <div class="form-floating ">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'gift_card1')" class="from-control"
                                                type="file" formControlName="gift_card1"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_card1'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['gift_card1'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_card1'].errors">
                                                    Image is Required</div>
                                            </div>
                                        </div>
                                        
                                    </div>


                                    

                                    <div class="col-sm-6 form-group" *ngIf="gift_card2Flag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="gift_no2"
                                                placeholder="Gift Card Number"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_no2'].errors}" />

                                            <div *ngIf="submitted && promotionForm.controls['gift_no2'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_no2'].errors.required">
                                                    Gift Card Number is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['gift_no2'].errors.pattern">
                                                    Invalid Gift Card Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no2.hasError('maxlength') && promotionForm.controls.gift_no2.touched">
                                                    Allow Only 16 Digits </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no2.hasError('minlength') && promotionForm.controls.gift_no2.touched">
                                                    Allow Only 16 Digits </div>
                                            </div>

                                            <label for="gift_no2">Gift Card Number-2</label>

                                        </div>
                                    </div>

                                    <div class="col-sm-6 form-group" *ngIf="gift_card2Flag==true">
                                        <label class="font-size11" for="Doc5_IDproof">Upload Gift Card-2</label>
                                        <div class="form-floating">

                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'gift_card2')" class="from-control"
                                                type="file" formControlName="gift_card2"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_card2'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['gift_card2'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_card2'].errors"> Image
                                                    is Required</div>
                                            </div>

                                        </div>
                                       

                                    </div>


                                    
                                    <div class="col-sm-6 form-group" *ngIf="gift_card3Flag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="gift_no3"
                                                placeholder="Gift Card Number"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_no3'].errors}" />
                                            <div *ngIf="submitted && promotionForm.controls['gift_no3'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_no3'].errors.required">
                                                    Gift Card Number is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['gift_no3'].errors.pattern">
                                                    Invalid Gift Card Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no3.hasError('maxlength') && promotionForm.controls.gift_no3.touched">
                                                    Allow Only 16 Digits </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no3.hasError('minlength') && promotionForm.controls.gift_no3.touched">
                                                    Allow Only 16 Digits </div>
                                            </div>

                                            <label for="gift_no3">Gift Card Number-3</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 form-group" *ngIf="gift_card3Flag==true">
                                        <label class="font-size11" for="Doc5_IDproof">Upload Gift Card-3</label>
                                        <div class="form-floating">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'gift_card3')" class="from-control"
                                                type="file" formControlName="gift_card3"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_card3'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['gift_card3'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_card3'].errors">
                                                    Image is Required</div>
                                            </div>
                                        </div>
                                       

                                    </div>



                                    
                                    <div class="col-sm-6 form-group" *ngIf="gift_card4Flag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="gift_no4"
                                                placeholder="Gift Card Number"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_no4'].errors}" />
                                            <div *ngIf="submitted && promotionForm.controls['gift_no4'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_no4'].errors.required">
                                                    Gift Card Number is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['gift_no4'].errors.pattern">
                                                    Invalid Gift Card Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no4.hasError('maxlength') && promotionForm.controls.gift_no4.touched">
                                                    Allow Only 16 Digits </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no4.hasError('minlength') && promotionForm.controls.gift_no4.touched">
                                                    Allow Only 16 Digits </div>
                                            </div>

                                            <label>Gift Card Number-4</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 form-group" *ngIf="gift_card4Flag==true">
                                        <label class="font-size11">Upload Gift Card-4</label>
                                        <div class="form-floating">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'gift_card4')" class="from-control"
                                                type="file" formControlName="gift_card4"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_card4'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['gift_card4'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_card4'].errors">Image
                                                    is Required</div>
                                            </div>
                                        </div>
                                        

                                    </div>


                                    

                                    <div class="col-sm-6 form-group" *ngIf="gift_card5Flag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="gift_no5"
                                                placeholder="Gift Card Number"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_no5'].errors}" />
                                            <div *ngIf="submitted && promotionForm.controls['gift_no5'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_no5'].errors.required">
                                                    Gift Card Number is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['gift_no5'].errors.pattern">
                                                    Invalid Gift Card Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no5.hasError('maxlength') && promotionForm.controls.gift_no5.touched">
                                                    Allow Only 16 Digits </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no5.hasError('minlength') && promotionForm.controls.gift_no5.touched">
                                                    Allow Only 16 Digits </div>
                                            </div>

                                            <label for="gift_no5">Gift Card Number-5</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 form-group" *ngIf="gift_card5Flag==true">
                                        <label class="font-size11">Upload Gift Card-5</label>
                                        <div class="form-floating">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'gift_card5')" class="from-control"
                                                type="file" formControlName="gift_card5"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_card5'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['gift_card5'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_card5'].errors">Image is
                                                    Required</div>
                                            </div>
                                        </div>
                                       

                                    </div>
                                   
                                    <div class="col-sm-6 form-group">
                                        <div class="box100per">
                                            <b id="spTerms"></b>
                                            <div class="mb-20 mt-20 ">
                                                <div class="d-inline"><strong>Terms &amp; Conditions</strong><strong>*</strong>
                                                    : </div>
                                                <div class="d-inline-flex">
                                                    <div class="form-check">
                                                        <input
                                                            [ngClass]="{'is-invalid':submitted && promotionForm.controls['TNC'].errors}"
                                                            formControlName="TNC" id="ContentPlaceHolder1_chkTerm"
                                                            type="checkbox" name="ctl00$ContentPlaceHolder1$chkTerm"
                                                            class="form-check-input">
        
        
                                                        <label class="form-check-label"> <a
                                                                href="../../../assets/images/pdf/Savings Ki Shubh Shuruaat-T&C.pdf"
                                                                target="_blank">I accept all Terms &amp; Conditions</a></label>
                                                    </div>
                                                </div>
                                                <div *ngIf="submitted && promotionForm.controls['TNC'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="promotionForm.controls['TNC'].errors">
                                                        Required Field
                                                    </div>
                                                </div>
                                            </div>
                                             <b id="spTerms"></b>
        
                                        </div>
                                        
                                    </div> 



                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="mt-10">
                                                    <div class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Captcha'].errors }"
                                                        id="Captcha" />

                                                    <div *ngIf="submitted && promotionForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="promotionForm.controls['Captcha'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="onSubmit()" class="greenbtn2">Submit <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="text-right md-text-left">
                                            <button (click)="reset()" class="bluebtn2">Reset<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-right mt-30">
                                    <p class="mb-0"><b><i><span class="text-danger">*</span>MGL reserves the right to
                                                ask for any other document if required </i></b></p>
                                    <p class="mb-0"><b><i>Please note all fields marked <span
                                                    class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>
                                <div *ngIf="alertType == 'vehiclepromotion'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<div class="modal fade directors-sd {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                    class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                        <div class="img-div-class">
                            <img src="{{gift_card111 }}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Gift Card 1</div>
                                <div>{{gift_no11}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30" *ngIf="gift_no22!=''">
                        <div class="img-div-class">
                            <img src="{{gift_card222}}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Gift Card 2</div>
                                <div>{{gift_no22}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30" *ngIf="gift_no33!=''">
                        <div class="img-div-class">
                            <img src="{{gift_card333}}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Gift Card 3</div>
                                <div>{{gift_no33}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30" *ngIf="gift_no44!=''">
                        <div class="img-div-class">
                            <img src="{{gift_card444}}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Gift Card 4</div>
                                <div>{{gift_no44}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30" *ngIf="gift_no55!=''">
                        <div class="img-div-class">
                            <img src="{{gift_card555}}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Gift Card 5</div>
                                <div>{{gift_no55}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button  class="greenbtn2" (click)="submitPop()">Confirm</button>
                <button class="bluebtn2" (click)="closeModel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>