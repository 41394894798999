<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>How PNG Bill is Calculated</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class="active" aria-current="page"><a href="#">How PNG Bill is Calculated
                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>
                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="innerpage-right-content">
                    <div class="mt-30 mb-30">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-8 col-sm-12">
                                <h3>The Billing Pattern</h3>
                                <p>The general billing pattern is to bill for gas consumption every two months. The
                                    first bill is on ACTUAL gas consumption as per the meter reading. The second one is
                                    on ASSESSED basis. Thereafter, the cycle of billing alternately by Actual Reading
                                    and Accessed Reading is repeated.</p>

                                <h3>What do (A) and (E) represent? </h3>
                                <p>The letter (A) after the previous/ closing reading indicates that it is an
                                    <b>ASSESSED</b> Reading. Whenever Actual Reading is not available, for any reason,
                                    the bill is generated on <b>ESTIMATED</b> basis. In this case, the letter (E) is
                                    indicated after the previous/ closing reading given on the bill. For estimation,
                                    average of last 6 bills is taken. In cases where gas usage started less than 1 year
                                    ago and less than 3 actual bills were generated, then estimation is done on average
                                    gas consumption. </p>
                                <p><b>Remember:</b> Your bill is based on Actual Meter Reading if neither (A) nor (E) is
                                    mentioned after closing reading on your bill. On the reverse side of ACTUAL bill,
                                    meter photograph is provided.</p>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 text-center">
                                <img src="assets/images/specimen-bill.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="mt-30 mb-30">
                        <h3>Three Rate Slabs</h3>
                        <p>The more you consume, the higher the rate.<br>
                            Presently (As in July 2016) there are 3 Slab Rates.<br>
                            Slab I = Rs. 21.96/SCM, Slab II = Rs. 26.01/SCM, Slab III = Rs. 33.36/SCM.<br>
                            (SCM – Standard Cubic Meter) · Slab III rates vary month on month.</p>
                        <div class="slab_box">
                            <table class="table table-bordered" width="100%" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <th colspan="3">General Example of Domestic PNG Billing Through SAP</th>
                                    </tr>

                                    <tr>
                                        <td colspan="3">Presently (As of 19.07.2016): Slab I = Rs. 21.96/- SCM, Slab II
                                            = Rs. 26.01/- SCM and Slab III = Rs. 33.36/- SCM. Assumptions: For the
                                            imagery consumer in Mumbai. Closing reading on 19.07.2016 = 100; Previous
                                            reading on 19.05.2016 = 1</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">Gas consumption during the billing period of 61 days (19.05.2016
                                            to 19.07.2016)</td>
                                        <td>100 -1 = 99 SCM (Average daily consumption = 99/61 = 1.62 SCMD)</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" width="100" valign="middle">Slab - I</td>
                                        <td>Gas consumption (Up to 0.60 SCMD) during billing period of 61 days</td>
                                        <td>0.60 x 61 = 36.60 SCM (Slab I consumption)</td>
                                    </tr>
                                    <tr>
                                        <td>Billing amount (@ Rs. 21.96/- SCM)</td>
                                        <td>36.60 X 21.96 = Rs. 803.73 (Slab I amount)</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2">Slab – II </td>
                                        <td>Gas consumption (Between 0.61 SCMD and 1.50 SCMD) during billing period of
                                            61 days.</td>
                                        <td> 0.90 X 61 = 54.90 SCM (Slab II consumption)</td>
                                    </tr>
                                    <tr>
                                        <td>Billing amount (@ Rs. 26.01/- SCM)</td>
                                        <td>54.90 X 26.01 = Rs. 1427.95 (Slab II amount)</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2">Slab III</td>
                                        <td>Gas consumption (Above 1.51 SCMD) during billing period of 61 days</td>
                                        <td>0.12 x 61 = 7.32 SCM (Slab III consumption)</td>
                                    </tr>
                                    <tr>
                                        <td>Billing amount (@ Rs. 33.36/- SCM)</td>
                                        <td>7.32 X 33.36 = Rs. 244.20 (Slab III amount)</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">Hence total bill amount (Excluding taxes/ arrears/ adjustments,
                                            etc.) = Total of Slab I, II and III amounts.</td>
                                        <td>803.73 + 1427.95 +244.20 = Rs. 2475.88</td>
                                    </tr>
                                </tbody>
                            </table>

                            <p><i>Applicable rounding off is carried out for gas consumption by SAP.</i></p>
                        </div>

                        <h3>To avoid ESTIMATED Bill, you can render help as follows:</h3>
                        <div>
                            <ul class="remember">
                                <li>(a) Remember your billing date.</li>
                                <li>
                                    <div class="least">(b) At least 4 days prior to the billing date, give Actual Meter
                                        Reading (the digits under black portion on the meter) to MGL through:</div>
                                    <div class="ignore">0 8 5 3 2 <samp>X</samp><samp>X</samp><samp>X</samp><big>Ignore
                                            the figure to the right of the comma</big></div>
                                    <div class="clear"></div>
                                </li>
                                <ul class="w-100">
                                    <li>(i) a) Downloading 'MGL connect' android app &amp;<br>
                                        &nbsp;&nbsp;&nbsp;&nbsp;b) Uploading your metre photograph and reading through
                                        the app., OR</li>
                                    <li>(ii) SMS: <bp no.=""> Space &lt; 5 digits (in black) &gt; to 9223555557, OR</bp>
                                    </li>
                                    <li>(iii) E-mail to amr@mahanagargas.com, or by logging in to www.mahanagargas.com
                                        OR</li>
                                    <li>(iv) Logging in to www.mahanagargas.com - Customer Zone and entering your meter
                                        reading, OR</li>
                                    <li>(v) Calling (022) 68674500 / (022) 61564500</li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>