import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { Retrofitters20Service } from 'src/app/Service/retrofitters20.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-retrofitters20levelone',
  templateUrl: './retrofitters20levelone.component.html',
  styleUrls: ['./retrofitters20levelone.component.css']
})
export class Retrofitters20leveloneComponent {
 promotionForm!: FormGroup
  submitted = false
  pancardimg: any;
  bookingamountreceiptimg: any;
  existingrcbookimg: any;
  nocdocimg: any;
  captcha: any;
  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';
  retroffiterLoginRole:any;
   retrofittersEmail:any;

 constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private service: Retrofitters20Service,
    private UserService : UserRegistrationService,public router: Router) { }

  ngOnInit(): void {
    this.UserService.checkLogin1()

    let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential1')))
    
     if (data) {
      let retroffiterLogindata = JSON.parse(data);

      let retroffiterData = retroffiterLogindata.details
     this.retroffiterLoginRole = retroffiterData.Role;
     this.retrofittersEmail = retroffiterData.Email;
     //console.log('details data',this.retroffiterLoginRole)
    }

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

   
    this.captchaGenerate()
    this.promotionForm = this.FormBuilder.group({
            Name: ['', [Validators.required]],
            ContactNumber: ['', Validators.compose([Validators.required,Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$")])],
            Email: ['', [Validators.required,Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            vehicleNo: ['', [Validators.required]],
            Pan:['',[Validators.required, Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
            booking_receipt_date: ['', [Validators.required]],
            amount_of_scheme: ['', [Validators.required]],
            pan_card_img: ['', Validators.required],
            booking_amount_receipt: ['', Validators.required],
            existing_rc_book: ['', Validators.required],
            noc_doc:  [''],
            role: [''],
            vehicleTpye: ['', [Validators.required]],
            kit_Supplier: ['', [Validators.required]],
            Captcha: ['', [Validators.required]],
           
    });


     this.promotionForm.get('kit_Supplier')?.setValue(this.retroffiterLoginRole);

  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  




  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }


  selectCar(){
    if(this.promotionForm.get('vehicleTpye')?.value == "SCV (Upto 3.5 T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("0");

    }else if(this.promotionForm.get('vehicleTpye')?.value == "LCV (>3.5-10 T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("150000");

    }else if(this.promotionForm.get('vehicleTpye')?.value == "Bus"){
      this.promotionForm.get('amount_of_scheme')?.setValue("200000");
      
    }else if(this.promotionForm.get('vehicleTpye')?.value == "ICV ( ≥10, < 15 tons)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("300000");
    }else if(this.promotionForm.get('vehicleTpye')?.value == "HCV( ≥15)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("450000");
      
    }
    

  }

 
 
 
  filechange(element: any, check: any) {
   // console.log(check);
    if (check == 'bookingamountreceipt') {
      if(element.target.files[0].size/1024/1024>2){
          this.promotionForm.get('booking_amount_receipt')?.setValue('');
        return alert('Please select PDF less than 2 MB ')
      }else{
        this.bookingamountreceiptimg = element.target.files
      }
      
     

    } else if (check == 'existingrcbook') {
        if(element.target.files[0].size/1024/1024>2){
          this.promotionForm.get('existing_rc_book')?.setValue('');
        return alert('Please select PDF less than 2 MB ')
      }else{
        this.existingrcbookimg = element.target.files
      }
      
     
    } else if (check == 'pancard'){
       if(element.target.files[0].size/1024/1024>2){
          this.promotionForm.get('pan_card_img')?.setValue('');
        return alert('Please select image less than 2 MB ')
      }else{
       this.pancardimg = element.target.files
      }
       
      
    } else if (check == 'noccard'){
       if(element.target.files[0].size/1024/1024>2){
          this.promotionForm.get('noc_doc')?.setValue('');
        return alert('Please select image less than 2 MB ')
      }else{
       this.nocdocimg = element.target.files
      }
       
      
    }




  }



  

  

 

  clearAlert() {
    this.alertType = ''
  }

  
  submitForm() {
   // console.log(this.nocdocimg)
        this.submitted = true
    if (this.promotionForm.get('Captcha')?.value != this.captcha) {
      this.promotionForm.get('Captcha')?.setValue('')
    }
    if (this.promotionForm.invalid) {
      return;
    }

   

    const form = new FormData()
    form.append("Name", this.promotionForm.get('Name')?.value)
    form.append("ContactNumber", this.promotionForm.get('ContactNumber')?.value)
    form.append("Email", this.promotionForm.get('Email')?.value)
    form.append("vehicleNo", this.promotionForm.get('vehicleNo')?.value.replace(/\s/g, ""))
    form.append("Pan", this.promotionForm.get('Pan')?.value)
    form.append("booking_receipt_date", this.promotionForm.get('booking_receipt_date')?.value)
    form.append("amount_of_scheme", this.promotionForm.get('amount_of_scheme')?.value)
    form.append("vehicleTpye", this.promotionForm.get('vehicleTpye')?.value)
    form.append("kit_Supplier", this.promotionForm.get('kit_Supplier')?.value)
    form.append("role", this.retroffiterLoginRole )
     form.append("retrofittersEmail", this.retrofittersEmail )
       
    let bookingamountreceiptimgImg = this.bookingamountreceiptimg[0].name.split('.').pop()
    let bookingamountreceiptimgImg1  =this.bookingamountreceiptimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ bookingamountreceiptimgImg;
    form.append("booking_amount_receipt", this.bookingamountreceiptimg[0], bookingamountreceiptimgImg1)

    let existingrcbookimgImg = this.existingrcbookimg[0].name.split('.').pop()
    let existingrcbookimgImg1  =this.existingrcbookimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ existingrcbookimgImg;
    form.append("existing_rc_book", this.existingrcbookimg[0], existingrcbookimgImg1)

    let pancardimgImg = this.pancardimg[0].name.split('.').pop()
    let pancardimgImg1  =this.pancardimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ pancardimgImg;
    form.append("pan_card_img", this.pancardimg[0], pancardimgImg1)

     if(this.nocdocimg != undefined){
       let nocdocimgImg = this.nocdocimg[0].name.split('.').pop()
       let nocdocimgImg1  =this.nocdocimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ nocdocimgImg;
      form.append("noc_doc", this.nocdocimg[0], nocdocimgImg1)
     }
    

   
      

     this.service.insert_Retrofitters_Level_one(form).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.submitted = false;
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        this.promotionForm.reset();
     
        setTimeout(() => {
           this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-retrofitters-dashboard']);
          this.clearAlert()

        }, 5000);

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        //  this.promotionForm.reset();
        setTimeout(() => {
          this.clearAlert()

        }, 5000);
      }



    }, err => {
     // console.log(err)
    })

  }



  reset() {
    this.promotionForm.reset()
    this.submitted = false;
   

  }

   validDate(){
    
    console.log(this.promotionForm.get('booking_receipt_date')?.value)
    var selectDate = new Date(this.promotionForm.get('booking_receipt_date')?.value);
    var todayDate = new Date('2024-09-01');

    console.log(selectDate)
     console.log(todayDate)

    if(todayDate > selectDate){
         this.promotionForm.get('booking_receipt_date')?.setValue('')
       alert("we have not accept booking receipt date before 1 st Sep 2024")
    }else{
        console.log("okay")
    }
  }



  
}



