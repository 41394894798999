import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ApplyforoutletService } from 'src/app/Service/applyforoutlet.service';

@Component({
  selector: 'app-thank-you-outlet',
  templateUrl: './thank-you-outlet.component.html',
  styleUrls: ['./thank-you-outlet.component.css']
})
export class ThankYouOutletComponent {
  nametransferData:any

  constructor(public datePipe: DatePipe,public UserService: ApplyforoutletService){}


  ngOnInit(): void {

    // this.nametransferData=this.UserService.printNameTransfer

    

  }
}
