<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
     <h1>Blog</h1>   
     <div class="breadcrumbs">
           <ul class="">
             <li class=""><a href="#">Home</a></li>
             <li class="active" aria-current="page"><a href="#">Blog Details</a></li>
           </ul>
     </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
     <!-- Services Section End -->


<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div>
            </div>

            



<!-- <div class="col-lg-9 col-md-9 col-sm-12">
    <div class="innerpage-right-content news-section-page rs-blog blog-main-home">
        <div class="row">
            <div *ngFor="let BlogItem of blogdata" class="col-lg-4 col-md-4 col-sm-12 mb-30">
                <div class="blog-item">
                    <div class="image-wrap">
                        <a *ngIf="BlogItem.Img"><img src="{{base}}{{BlogItem.Img}}"
                                class="w-100"></a>
                    </div>
                    <div class="blog-content">
                        <ul class="blog-meta">
                            <li class="date">{{BlogItem.PublishDate | date:'MM/dd/yyyy'}}</li>
                        </ul>
                        <h3 class="blog-title"><a href="news-details.html">{{BlogItem.Title}}</a></h3>
                        <p class="desc">{{BlogItem.Description}}</p>
                        <div class="blog-btn">
                            <a class="desc">{{BlogItem.Description}}>Read More</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div> -->



<div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
    <div class="row">
                      <div class="col-lg-12 rs-inner-blog" *ngFor="let BlogItem of blogdata">
                         <div class="blog-details">
                             <div class="bs-img mb-35">
                                 <a href="#"><img class="w-100" src="{{base}}{{blogdata[0].Img}}" alt=""></a>
                             </div>
                             <div class="blog-full">
                                 <ul class="single-post-meta">
                                     <li>
                                         <span class="p-date"><i class="fa fa-calendar-check-o"></i> {{blogdata[0].PublishDate | date}}</span>
                                     </li>
                                 </ul>
                                 <h3 class="blog-title">{{BlogItem.Title}}</h3>
                                 <p [innerHTML]="blogdata[0].Description"></p>
                                 </div>

                                 </div>
                                 </div>
                                 </div>
                                 </div>
                                 </div>
                                 </div>
                                 </div>
                                