<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Videos</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Media</a></li>
                <li class=""><a >Gallery</a></li>
                <li class="active" aria-current="page"><a>Videos</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section">
                    <!-- Project Section Start -->
                    <div class="rs-project style1 red-overly-bg pb-100 md-pb-70">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let i of allVideo">
                                    <div class="rs-videos choose-video mb-30">
                                        <div class="images-video">
                                            <img src="https://img.youtube.com/vi/{{getID(i.URL)}}/hqdefault.jpg"
                                                alt="images">
                                        </div>
                                        <div class="animate-border" (click)="openPopup(i.URL)">
                                            <a class="popup-border">
                                                <i class="fa fa-play"></i>
                                            </a>
                                        </div>
                                        <p class="mt-20 mb-0 text-center">{{i.Title}}</p>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="d-flex text-center">
                                        <a href="#" class="bluebtn2 mx-auto">Load More Album <i
                                                class="demo-icon uil-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Project Section End -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Inner Page Content Section End -->

<div class="modal fade videogallery {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div (click)="this.closeModel()" class="modal-dialog modal-xl modal-video">

        <div class="modal-content">
            <button (click)="this.closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="director-modal">
                    
                    <iframe  [src]="safeSrc" width="560" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen
      allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>