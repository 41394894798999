<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Application for CNG Retail Outlets
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Dealer Zone</a></li>
                <li class="active" aria-current="page"><a href="#">Application for CNG Retail Outlets

                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        
                        <div class="customer-login-page log-meter-reading">

                            <form [formGroup]="applyoutletForm">
                            	<!-- <div class="row">
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="typeofapplicant"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['typeofapplicant'].errors}" (change)="getApplicanttype()">
                                                <option value="" selected>Select Type of applicant </option>
                                                <option value="Individual">Individual</option>
                                                <option value="Registered Firm">Registered Firm</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['typeofapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['typeofapplicant'].errors">Required Field</div>
                                            </div>
                                            <label for="typeofapplicant">Type of applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row">
                                	 <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="typeofapplicant"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['typeofapplicant'].errors}" (change)="getApplicanttype()">
                                                <option value="" selected>Select Type of applicant </option>
                                                <option value="Individual">Individual</option>
                                                <option value="Registered Firm">Registered Firm</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['typeofapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['typeofapplicant'].errors">Required Field</div>
                                            </div>
                                            <label for="typeofapplicant">Type of applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group" *ngIf="applicantIndividualFlag==true">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="numberofapplicant"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['numberofapplicant'].errors}" (change)="getApplicantNumber()">
                                                <option value="" selected>Select Number of applicant </option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['numberofapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['numberofapplicant'].errors">Required Field</div>
                                            </div>
                                            <label for="numberofapplicant">Number of applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    
                                       <div class="col-sm-6 form-group" *ngIf="firstFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="firstapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['firstapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['firstapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['firstapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="secondFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="secondapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['secondapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['secondapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['secondapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="thirdFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="thirdapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['thirdapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['thirdapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['thirdapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="fourthFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="fourthapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['fourthapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['fourthapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['fourthapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="fiveFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="fifthapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['fifthapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['fifthapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['fifthapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="sixFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="sixapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['sixapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['sixapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['sixapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                     <div class="col-sm-6 form-group" *ngIf="applicantFirmFlag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="nameoffirm"
                                                placeholder="Name of the Firm"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['nameoffirm'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['nameoffirm'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['nameoffirm'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Firm<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="applicantFirmFlag==true">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="typeoffirm"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['typeoffirm'].errors}" (change)="getfirmtype()">
                                                <option value="" selected>Select type of Firm </option>
                                                <option value="Proprietorship">Proprietorship</option>
                                                <option value="Partnership">Partnership</option>
                                                <option value="Private Limited">Private Limited</option>
                                                <option value="Others">Others</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['typeoffirm'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['typeoffirm'].errors">Required Field</div>
                                            </div>
                                            <label for="typeoffirm">Select type of Firm<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                     <div class="col-sm-6 form-group" *ngIf="othertypeFlag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="otherfirm"
                                                placeholder="Other Type of Firm"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['otherfirm'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['otherfirm'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['otherfirm'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Other Type of Firm<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Name"
                                                placeholder="Name"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Name'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="ContactNumber"
                                                placeholder="ContactNumber"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['ContactNumber'].errors }"
                                                id="ContactNumber" />
                                            <div *ngIf="submitted && applyoutletForm.controls['ContactNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['ContactNumber'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="applyoutletForm.controls['ContactNumber'].errors?.pattern">
                                                    invalid Contact Number </div>
                                            </div>
                                            <label for="ContactNumber">Contact Number<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Email"
                                                placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Email'].errors.required">Required
                                                    Field
                                                </div>
                                                 <div *ngIf="applyoutletForm.controls['Email'].errors.pattern">
                                                    Invalid Email ID </div>
                                            </div>
                                            <label for="Email">Email<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="NameofPlotOwner"
                                                placeholder="NameofPlotOwner"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['NameofPlotOwner'].errors }"
                                                id="NameofPlotOwner" />
                                            <div *ngIf="submitted && applyoutletForm.controls['NameofPlotOwner'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['NameofPlotOwner'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="NameofPlotOwner">Name of the Plot Owner<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control"
                                                formControlName="RelationshipofAppOwner"
                                                placeholder="RelationshipofAppOwner"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['RelationshipofAppOwner'].errors }"
                                                id="RelationshipofAppOwner" />
                                            <div *ngIf="submitted && applyoutletForm.controls['RelationshipofAppOwner'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['RelationshipofAppOwner'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="RelationshipofAppOwner">Relationship of Applicant Owner<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Addressofplot"
                                                placeholder="Addressofplot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Addressofplot'].errors }"
                                                id="Addressofplot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Addressofplot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Addressofplot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Addressofplot">Address of plot<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="SurveyNo"
                                                placeholder="SurveyNo"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['SurveyNo'].errors }"
                                                id="SurveyNo" />
                                            <div *ngIf="submitted && applyoutletForm.controls['SurveyNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['SurveyNo'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="SurveyNo">Survey No<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="District"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['District'].errors}">
                                                <option value="" selected>Select District </option>
                                                <option *ngFor="let DistrictItem of district "
                                                    value={{DistrictItem.District}}>{{DistrictItem.District}}</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['District'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['District'].errors">Required Field</div>
                                            </div>
                                            <label for="District">District<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="PinCode"
                                                placeholder="PinCode"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['PinCode'].errors }"
                                                id="PinCode" />
                                            <div *ngIf="submitted && applyoutletForm.controls['PinCode'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['PinCode'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="applyoutletForm.controls['PinCode'].errors?.pattern">invalid
                                                    pincode
                                                </div>
                                            </div>
                                            <label for="PinCode">PinCode<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="AreaofthePlot"
                                                placeholder="AreaofthePlot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['AreaofthePlot'].errors }"
                                                id="AreaofthePlot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['AreaofthePlot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['AreaofthePlot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="AreaofthePlot">Area of the Plot<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Frontageoftheplot"
                                                placeholder="Frontageoftheplot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Frontageoftheplot'].errors }"
                                                id="Frontageoftheplot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Frontageoftheplot'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['Frontageoftheplot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Frontageoftheplot">Frontage of the plot<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Depth"
                                                placeholder="Depth"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Depth'].errors }"
                                                id="Depth" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Depth'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Depth'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Depth">Depth<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Latitude"
                                                placeholder="Latitude"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Latitude'].errors }"
                                                id="Latitude" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Latitude'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Latitude'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Latitude">Latitude<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Longitude"
                                                placeholder="Longitude"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Longitude'].errors }"
                                                id="Longitude" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Longitude'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Longitude'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Longitude">Longitude<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="NameofRoad"
                                                placeholder="NameofRoad"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['NameofRoad'].errors }"
                                                id="NameofRoad" />
                                            <div *ngIf="submitted && applyoutletForm.controls['NameofRoad'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['NameofRoad'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="NameofRoad">Name of Road<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="Plot"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Plot'].errors}">
                                                <option value="" selected>Select Plot</option>
                                                <option *ngFor="let PlotItem of plot " value={{PlotItem.Plot}}>
                                                    {{PlotItem.Plot}}</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['Plot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Plot'].errors"> Required Field
                                                </div>
                                            </div>
                                            <label for="Plot">Plot<span style="color: crimson;">*</span></label>
                                        </div>

                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Additionalremarks"
                                                placeholder="Additionalremarks" id="Additionalremarks" />
                                            <label for="Plot">Additional remarks</label>
                                        </div>

                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="lease_tenure_years"
                                                placeholder="lease_tenure_years"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['lease_tenure_years'].errors }"
                                                id="lease_tenure_years" />
                                            <div *ngIf="submitted && applyoutletForm.controls['lease_tenure_years'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['lease_tenure_years'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="lease_tenure_years">Lease tenure years<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="orderNo"
                                                placeholder="Demand Draft / Pay Order No."
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['orderNo'].errors }"
                                                id="orderNo" />
                                            <div *ngIf="submitted && applyoutletForm.controls['orderNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['orderNo'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="orderNo">Demand Draft / Pay Order No.<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" formControlName="orderNodate"
                                                placeholder="Demand draft date"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['orderNodate'].errors }"
                                                id="orderNodate" />
                                            <div *ngIf="submitted && applyoutletForm.controls['orderNodate'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['orderNodate'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="orderNodate">Demand Draft / Pay Order date<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="bank_name"
                                                placeholder="bank_name"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['bank_name'].errors }"
                                                id="bank_name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['bank_name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['bank_name'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="bank_name">Bank's name<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <p><b><i> You can upload only .doc,.docx,.pdf file format and file size within 5
                                                MB</i></b></p>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'Undertaking')"
                                                    class="from-control" type="file"
                                                    formControlName="under_taking_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['under_taking_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['under_taking_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['under_taking_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                        <label class="font-size11 mt-1" for="under_taking_doc">Undertaking from Landowner/ Registered Lease Agreement<span
                                                style="color: crimson;">*</span></label>
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'architect')"
                                                    class="from-control" type="file"
                                                    formControlName="architect_layout_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['architect_layout_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['architect_layout_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['architect_layout_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                        <label class="font-size11 mt-1" for="architect_layout_doc">Architect Layout<span
                                                style="color: crimson;">*</span></label>
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'idproof')"
                                                    class="from-control" type="file"
                                                    formControlName="id_proof_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['id_proof_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['id_proof_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['id_proof_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                        <label class="font-size11 mt-1" for="id_proof_doc">ID proof of Applicant(s) (Aadhaar Card / PAN Card / Any Govt. ID; any one)<span
                                                style="color: crimson;">*</span></label>
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'demanddraft')"
                                                    class="from-control" type="file"
                                                    formControlName="demand_draft_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['demand_draft_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['demand_draft_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['demand_draft_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                        <label class="font-size11 mt-1" for="demand_draft_doc">Demand Draft<span
                                                style="color: crimson;">*</span></label>
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'incorporation')"
                                                    class="from-control" type="file"
                                                    formControlName="incorporation_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['incorporation_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['incorporation_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['incorporation_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                        <label class="font-size11 mt-1" for="incorporation_doc">Please attach any one supporting documents - Incorporation Certificate or GST Certificate or PAN Card or Partnership Deed etc<span
                                                style="color: crimson;">*</span></label>
                                    </div> 


                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="mt-10">
                                                    <div class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Captcha'].errors }"
                                                        id="Captcha" />

                                                    <div *ngIf="submitted && applyoutletForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="applyoutletForm.controls['Captcha'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="onSubmit()" class="greenbtn2">Submit <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                       <!--  <div class="text-right md-text-left">
                                            <button (click)="reset()" class="bluebtn2">Reset<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div> -->
                                    </div>

                                    <div *ngIf="alertType == 'submit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>

                                </div>
                                <div class="text-right mt-30">
                                    <p class="mb-0"><b><i>Please note all fields marked <span
                                                    class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>