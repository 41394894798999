<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Images</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Media</a></li>
                <li class=""><a >Gallery</a></li>
                <li class="active" aria-current="page"><a>Images</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div>
            </div>

            <!-- <div class="tab">
                <button *ngFor="let i of Albums" class="tablinks" (click)="openCity($event, i)">{{i}}</button>
                
                
              </div> -->

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section">
                    <!-- Project Section Start -->
                    <div class="rs-project style1 red-overly-bg pb-100 md-pb-70">
                        <div class="container">
                            <div class="row">

                                <div class="tab">
                                    <button *ngFor="let i of AlbumYear" autofocus [ngStyle]="{'background-color': currentYear === i ? '#51ad32' : '#ddd'}" class="tablinks" (click)="openCity($event, i)">{{i}}</button>
                                    
                                    
                                  </div>
                                <div *ngFor="let i of allAlbum" class="col-lg-4 col-md-6 mb-30">
                                    <div *ngIf="i.ThumbnailImage != ''" class="project-item">
                                        <div class="project-img">
                                            <img src="{{baseUrl}}{{i.ThumbnailImage}}" alt="images">
                                        </div>
                                        <div class="project-content">
                                            <div class="project-inner">
                                                <h3 class="title"><a href="#">{{i.Album}}</a></h3>
                                                <span class="category"><a data-bs-toggle="modal" data-bs-target="#galleryModal" (click)="closed = false" (click)="getPhotoByAlbum(i)">View Details</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="range !< Albums.length" class="col-lg-12 col-md-12">
                                    <div class="d-flex text-center">
                                        <a href="javascript:void(0)" (click)="increaseRange()" class="bluebtn2 mx-auto">Load More Album <i
                                                class="demo-icon uil-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Project Section End -->
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>


<div class="modal fade" id="galleryModal" tabindex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="galleryModalLabel">{{selectedTitle}}</h5>
          <button (click)="closed = true" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

            
            <div *ngIf="PhotoList.length > 0" class="slider-img">
                <img src="{{baseUrl}}{{PhotoList[currentImgIndex].ThumbImageName}}" alt="Slider">
            </div>

            <button class="greenbtn2 mr-10" (click)="PrevImage()">Prev</button>
            <button class="greenbtn2" (click)="NextImage()">Next</button>
                
            
        </div>
    
        
      </div>
    </div>
  </div>