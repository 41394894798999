<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/cng-rate-inner-banner.jpg">
    <div class="banner-heading">
        <h1>New CNG Vehicle</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav</a></li>
                <li class="active" aria-current="page"><a>New CNG Vehicle</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips> 
                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
          <h5>   Mahanagar Gas Limited launches “MGL CNG Mahotsav” </h5>       
                <div class="blue-tick-list">
                    <ul>
                        <li>Incentive of up to INR 5 lakhs for new or retrofitted CNG Commercial Vehicles</li>
                       
                        <p>Mahanagar Gas Limited (MGL), one of the largest City Gas Distribution (CGD) companies in India, launched a new scheme ‘MGL CNG Mahotsav’ for purchase of new Compressed Natural Gas (CNG) vehicles as well as for retrofitment of existing petrol or diesel vehicles to CNG in Mumbai, Thane & Raigad Districts </p>
                        <p>The scheme, which can be availed on purchase of cars as well as Commercial CNG vehicles, is launched to further encourage use of CNG, a clean & green energy. Mahanagar Gas Limited has partnered with OEM’s for promoting new CNG vehicles and with leading CNG kit distributors to promote quality retro-fitment</p>
                        <div class="mb-30">
                            <img src="assets/images/MGL CNG Mahotsav.jpg" class="w-70">
                        </div>
                        <p>The lucrative scheme offers new Commercial vehicle buyers, incentives of ₹ 2 to 5 lakhs on purchase of new vehicles from following OEMs:</p>
                          <div class="table-responsive">
                                <table width="100%" border="0" cellspacing="0" cellpadding="5" class="table table-bordered">
                              <tbody>
                                <tr>
                                  <th><strong>OEM</strong></th>
                                  <th><strong>Category </strong></th>
                                  <th><strong>Models of vehicles</strong></th>
                                  <th><strong>Gross Vehicle Weight (GVW) in Tons</strong></th>
                                  <th><strong>Fuel Card (₹/ Vehicle)</strong></th>
                                </tr>
                                <tr>
                                  <td rowspan="3" style="vertical-align:middle;">Ashok Leyland</td>
                                  <td>ICV</td>
                                  <td>Ecomet 1015 20 FT </td>
                                  <td> ≥10, &lt; 15 tons</td>
                                  <td>3,50,000</td>
                                </tr>
                                <tr>
                                  <td>ICV</td>
                                  <td>Ecomet 1415 20 FT</td>
                                  <td>≥10, &lt; 15 tons</td>
                                  <td>3,50,000</td>
                                </tr>
                                <tr>
                                  <td>HCV</td>
                                  <td>Ecomet 1615 22 FT</td>
                                  <td>≥15 tons</td>
                                  <td>5,00,000</td>
                                </tr>
                                 <tr>
                                  <td rowspan="8" style="vertical-align:middle;">TATA Motors</td>
                                   <tr>
                                  <td rowspan="2">HCV</td>
                                  <td>1812G</td>
                                   <td rowspan="2" align="left" valign="middle" style="vertical-align: middle;">≥15 Ton</td>
                                  <td rowspan="2" align="left" valign="middle" style="vertical-align: middle;">5,00,000</td>
                                  
                                  
                                </tr>
                                <tr>
                                  
                                  <td>1612G</td>
                                 
                                </tr>
                                 <tr>
                                  <td>ICV</td>
                                  <td>1109G</td>
                                  <td>≥10, &lt;15 Ton</td>
                                  <td>3,50,000</td>
                                </tr>
                                <tr>
                                  <td rowspan="3" style="vertical-align: middle;">LCV</td>
                                  <td>407G</td>
                                  <td rowspan="3" align="left" valign="middle" style="vertical-align: middle;">&gt;3.5, &lt; 10 Ton</td>
                                  <td rowspan="3" align="left" valign="middle" style="vertical-align: middle;">2,00,000</td>
                                </tr>
                                  <tr>
                                  <td>709G LPT</td>
                                </tr>
                                  <tr>
                                  <td>609G SFC</td>
                                </tr>
                             
                                 
                                 
                                  
                                  
                                  <tr>
                                  <td  style="vertical-align: middle;">Bus</td>
                                  <td>1.   34 S SKl NAC/AC LP 410/36 CNG, <br>2. 51 S Skl NAC/AC LP 910/52 CNG, <br>3.   LP913CNG/52 AC 24V 3.8 SGI TC 125 HP,<br> 4. 24 S Staff NAC/AC LP 410/36   CNG, <br> 5. 40 S Staff   AC/NAC </td>
                                  <td>&nbsp;</td>
                                  <td style="vertical-align: middle;">3,00,000</td>
                                </tr>
                                  <tr>
                                  <td rowspan="12" style="vertical-align:middle;">Eicher</td>
                                  <td rowspan="4" style="vertical-align: middle;">ICV</td>
                                  <td>Pro 2049 CNG</td>
                                  <td rowspan="4" style="vertical-align: middle;"><span lang="EN-US">&gt;3.5, &lt; 10 Ton</span></td>
                                  <td rowspan="4" style="vertical-align: middle;">2,00,000</td>
                                </tr>
                                <tr>
                                  <td>Pro 2059 CNG</td>
                                </tr>
                                <tr>
                                  <td>Pro 2059XP CNG</td>
                                </tr>
                                <tr>
                                  <td>Pro 2075 CNG</td>
                                </tr>
                                <tr>
                                  <td rowspan="3" style="vertical-align: middle;">ICV</td>
                                  <td>Pro 2059XP CNG</td>
                                  <td rowspan="3" style="vertical-align: middle;">≥10, &lt; 15 tons</td>
                                  <td rowspan="3" style="vertical-align: middle;">3,50,000</td>
                                </tr>
                                <tr>
                                  <td>Pro 2110XP CNG</td>
                                </tr>
                                 <tr>
                                  <td>Pro 2109 CNG</td>
                                </tr>
                                <tr>
                                  <td rowspan="2" style="vertical-align: middle;">HCV</td>
                                  <td>Pro 2114XP CNG</td>
                                  <td rowspan="2" style="vertical-align: middle;">≥15 tons</td>
                                  <td rowspan="2" style="vertical-align: middle;">5,00,000</td>
                                </tr>
                                <tr>
                                  <td>Pro 3018 CNG</td>
                                </tr>
                                   <tr>
                                  <td rowspan="3" style="vertical-align: middle;">Bus</td>
                                  <td>2075</td>
                                  <td rowspan="3" style="vertical-align: middle;"></td>
                                  <td rowspan="3" style="vertical-align: middle;">3,00,000</td>
                                </tr>
                                <tr>
                                  <td>2090</td>
                                </tr>
                                 <tr>
                                  <td>Pro 3010</td>
                                </tr>
                               
                              
                              </tbody>
                            </table>
                            </div>
                       
    

                        <p>The fuel cards can be used to fill CNG in vehicles in the +310 outlets. Commercial vehicles can also utilise the fuel cards for CNG filing at few BEST bus depots like Ghatkopar and Goregaon by booking their slots through “MGL Tez” app.</p>
                        <p>For more information contact our customer care helpline nos. 022 6867 4500 / 6156 4500 or write to us at <a href="mailto:support@mahanagargas.com">support@mahanagargas.com.</a></p>
                    </ul>
                </div>

              
            
            </div>

          
            <!-- <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
          <h5>   Mahanagar Gas Limited launches “MGL CNG Mahotsav” </h5>       
                <div class="blue-tick-list">
                    <ul>
                        <li>Benefits worth INR 19,999/- for new or retrofitted CNG cars</li>
                        <li>Incentive of up to INR 5 lakhs for new or retrofitted CNG Commercial Vehicles</li>
                        <p>Mahanagar Gas Limited (MGL), one of the largest City Gas Distribution (CGD) companies in India, launched a new scheme ‘MGL CNG Mahotsav’ for purchase of new Compressed Natural Gas (CNG) vehicles as well as for retrofitment of existing petrol or diesel vehicles to CNG in Mumbai, Thane & Raigad Districts </p>
                        <p>The scheme, which can be availed on purchase of cars as well as Commercial CNG vehicles, is launched to further encourage use of CNG, a clean & green energy. Mahanagar Gas Limited has partnered with OEM’s for promoting new CNG vehicles and with leading CNG kit distributors to promote quality retro-fitment</p>
                        <div class="mb-30">
                            <img src="assets/images/MGLHalf.jpeg" class="w-70">
                        </div>
                        <p>The lucrative scheme offers new CNG car owners of select models CNG Fuel cards worth ₹19,999/- and for new Commercial vehicle buyers, incentives of ₹ 2 to 5 lakhs on purchase of new vehicles from following OEMs:</p>
                          <div class="table-responsive">
                                <table width="100%" border="0" cellspacing="0" cellpadding="5" class="table table-bordered">
                              <tbody>
                                <tr>
                                  <th><strong>OEM</strong></th>
                                  <th><strong>Category </strong></th>
                                  <th><strong>Models of vehicles</strong></th>
                                  <th><strong>Gross Vehicle Weight (GVW) in Tons</strong></th>
                                  <th><strong>Fuel Card (₹/ Vehicle)</strong></th>
                                </tr>
                                <tr>
                                  <td style="vertical-align: middle;">Maruti Suzuki India Limited</td>
                                  <td style="vertical-align: middle;">CAR</td>
                                  <td>Alto K10 CNG, <br>S-Presso CNG,<br>Celerio CNG, <br>Wagon R CNG (Non H3 Cars), <br>Swift CNG,<br> Baleno CNG and Brezza CNG</td>
                                  <td style="vertical-align: middle;">NA</td>
                                  <td style="vertical-align: middle;">19,999</td>
                                </tr>
                                <tr>
                                  <td>Maruti Suzuki India Limited</td>
                                  <td>SCV</td>
                                  <td>SUPER CARRY CNG</td>
                                  <td>&lt; 3.5 Tonnes</td>
                                  <td>19,999</td>
                                </tr>
                                <tr>
                                  <td rowspan="4" style="vertical-align:middle;">Ashok Leyland</td>
                                  <td>ICV</td>
                                  <td>Ecomet 1015 20 FT </td>
                                  <td> ≥10, &lt; 15 tons</td>
                                  <td>3,50,000</td>
                                </tr>
                                <tr>
                                  <td>ICV</td>
                                  <td>Ecomet 1415 20 FT</td>
                                  <td>≥10, &lt; 15 tons</td>
                                  <td>3,50,000</td>
                                </tr>
                                <tr>
                                  <td>SCV</td>
                                  <td>DOST CNG</td>
                                  <td>&lt; 3.5 Tonnes</td>
                                  <td>19,999</td>
                                </tr>
                                <tr>
                                  <td>HCV</td>
                                  <td>Ecomet 1615 22 FT</td>
                                  <td>≥15 tons</td>
                                  <td>5,00,000</td>
                                </tr>
                                <tr>
                                  <td>Hyundai Motors</td>
                                  <td>CAR</td>
                                  <td>Grand i10 NIOS, Aura</td>
                                  <td>NA</td>
                                  <td>19,999</td>
                                </tr>
                                <tr>
                                  <td rowspan="8" style="vertical-align:middle;">TATA Motors</td>
                                  <td>CAR</td>
                                  <td>Tiago, Tigor and Altroz</td>
                                  <td>NA</td>
                                  <td>19,999</td>
                                </tr>
                                <tr>
                                  <td>SCV</td>
                                  <td>Ace CNG, Ace CNG+ and Intra V20</td>
                                  <td>&lt; 3.5 Tonnes</td>
                                  <td>19,999</td>
                                </tr>
                                  <tr>
                                  <td>HCV</td>
                                  <td>1612g</td>
                                  <td>≥15 Ton</td>
                                  <td>5,00,000</td>
                                </tr>
                                  <tr>
                                  <td>ICV</td>
                                  <td>1109g</td>
                                  <td>≥10, &lt;15 Ton</td>
                                  <td>3,50,000</td>
                                </tr>
                                  <tr>
                                  <td rowspan="3" style="vertical-align: middle;">LCV</td>
                                  <td>407g</td>
                                  <td rowspan="3" align="left" valign="middle" style="vertical-align: middle;">&gt;3.5, &lt; 10 Ton</td>
                                  <td rowspan="3" align="left" valign="middle" style="vertical-align: middle;">2,00,000</td>
                                </tr>
                                  <tr>
                                  <td>709g LPT</td>
                                </tr>
                                  <tr>
                                  <td>609g SFC</td>
                                </tr>
                                  
                                  <tr>
                                  <td style="vertical-align: middle;">Bus</td>
                                  <td>1.   34 S SKl NAC/AC LP 410/36 CNG, <br>2. 51 S Skl NAC/AC LP 910/52 CNG, <br>3.   LP913CNG/52 AC 24V 3.8 SGI TC 125 HP,<br> 4. 24 S Staff NAC/AC LP 410/36   CNG, <br> 5. 40 S Staff   AC/NAC </td>
                                  <td>&nbsp;</td>
                                  <td style="vertical-align: middle;">3,00,000</td>
                                </tr>
                                <tr>
                                  <td>Mahindra &amp; Mahindra Ltd</td>
                                  <td>SCV</td>
                                  <td>Pick UP CNG and SUPRO CNG</td>
                                  <td>&lt; 3.5 Tonnes</td>
                                  <td>19,999</td>
                                </tr>
                                <tr>
                                  <td rowspan="8" style="vertical-align:middle;">Eicher</td>
                                  <td rowspan="4" style="vertical-align: middle;">ICV</td>
                                  <td>Pro 2049 CNG</td>
                                  <td rowspan="4" style="vertical-align: middle;"><span lang="EN-US">&gt;3.5, &lt; 10 Ton</span></td>
                                  <td rowspan="4" style="vertical-align: middle;">2,00,000</td>
                                </tr>
                                <tr>
                                  <td>Pro 2059 CNG</td>
                                </tr>
                                <tr>
                                  <td>Pro 2059XP CNG</td>
                                </tr>
                                <tr>
                                  <td>Pro 2075 CNG</td>
                                </tr>
                                <tr>
                                  <td rowspan="2" style="vertical-align: middle;">ICV</td>
                                  <td>Pro 2059XP CNG</td>
                                  <td rowspan="2" style="vertical-align: middle;">≥10, &lt; 15 tons</td>
                                  <td rowspan="2" style="vertical-align: middle;">3,50,000</td>
                                </tr>
                                <tr>
                                  <td>Pro 2110XP CNG</td>
                                </tr>
                                <tr>
                                  <td rowspan="2" style="vertical-align: middle;">HCV</td>
                                  <td>Pro 2114XP CNG</td>
                                  <td rowspan="2" style="vertical-align: middle;">≥15 tons</td>
                                  <td rowspan="2" style="vertical-align: middle;">5,00,000</td>
                                </tr>
                                <tr>
                                  <td>Pro 3018 CNG</td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                       
    

                        <p>The fuel cards can be used to fill CNG in vehicles in the +310 outlets. Commercial vehicles can also utilise the fuel cards for CNG filing at few BEST bus depots like Ghatkopar and Goregaon by booking their slots through “MGL Tez” app.</p>
                        <p>For more information contact our customer care helpline nos. 022 6867 4500 / 6156 4500 or write to us at <a href="mailto:support@mahanagargas.com">support@mahanagargas.com.</a></p>
                    </ul>
                </div>

              
            
            </div> -->

        </div>
    </div>
</div>