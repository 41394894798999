import { AfterViewInit, Component, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FaqServiceService } from './Service/FAQ/faq-service.service';
import { OnInit, HostListener, Inject } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { DisableRightClickService } from './DisableRightClickService';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)]),
      ]
    )]
})
export class AppComponent implements OnInit, AfterViewInit {
 // title = 'MahanagarGas_Website';
  location: Location;
  headerFooter:any;
  rightMenu:any;

  constructor(@Inject(DOCUMENT) private document: Document, public FaqService: FaqServiceService, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object, private rightClickDisable: DisableRightClickService,
    private router: Router,
    private meta: Meta,private title: Title) {

     this.meta.addTags([
{name: 'description', content:"description', content: 'Enjoy safe, efficient piped natural gas supply for your home with Mahanagar Gas. Register now for secure PNG gas connection and professional CNG services. Convenience assured."},
{name: 'keywords', content: "png gas connection, cng service, png registration, Mahanagar Gas"},
{name: 'description', content:"Versatile PNG uses from Mahanagar Gas - households, hospitals, flight kitchens, hotels, restaurants, places of worship. Safe, efficient piped natural gas for cooking."},
{name: 'keywords', content: "piped Natural Gas, natural gas prices, PNG provider"},
{name: 'description', content:"Discover the benefits of using CNG from Mahanagar Gas. It's economical, easy on the pocket, and eco-friendly. Switch to CNG for cost-effective, environmentally friendly solutions."},
{name: 'keywords', content: "Compressed natural Gas, CNG Provider"},
{name: 'description', content:"Mahanagar Gas is a leading natural gas distribution company offering reliable CNG and PNG services. Trust us for convenient and sustainable natural gas solutions."},
{name: 'keywords', content: "Cng Png  Distribution Company,  Natural Gas Distribution Company, leading Natural Gas Distribution"},
{name: 'description', content:"Explore the prestigious awards received by Mahanagar Gas Limited (MGL) for outstanding contributions in natural gas distribution. Recognize their excellence."},
{name: 'keywords', content: "Png Cng Service, CNG station near me"},
{name: 'description', content:"Explore the prestigious awards received by Mahanagar Gas Limited (MGL) for their outstanding contributions in the field of natural gas distribution."},
{name: 'keywords', content: "Natural Gas Distribution Company, Domestic industrial Gas"},
{name: 'description', content:"Explore Mahanagar Gas' natural gas services for industrial and commercial clients. Get tips for conserving gas and learn about our esteemed clientele"},
{name: 'keywords', content: "Natural Gas, Cng & Png services,"},
{name: 'description', content:"Explore Mahanagar Gas' Natural Gas, CNG, and PNG services. Learn about their commercial PNG application process and access clean, efficient fuel solutions."},
{name: 'keywords', content: "Piped Natural Gas,  PNG application, PNG Services Provider"},
{name: 'description', content:"Discover reliable and eco-friendly Natural Gas, CNG, and PNG services from Mahanagar Gas. Visit our customer login page for seamless residential access."},
{name: 'keywords', content: "Residential png gas connection, Png Domestic Application"},
{name: 'description', content:"Get reliable PNG gas connection and CNG service with Mahanagar Gas. Visit our website for PNG registration and explore our vision for a greener, better future."},
{name: 'keywords', content: "png gas connection, cng service, png registration, Mahanagar Gas"},
{name: 'description', content:"Explore MGL's Annual Report for insights into our corporate performance, achievements, and future prospects as a leading player in the natural gas industry."},
{name: 'keywords', content: "Mahanagar Gas report"},
{name: 'description', content:"Learn how to transfer your residential PNG connection hassle-free with Mahanagar Gas. Visit our customer zone for easy name transfers and more PNG services."},
{name: 'keywords', content: "MGL, Residential PNG ,Png Domestic Application"},
{name: 'description', content:"Check your Residential PNG name transfer status at the Mahanagar Gas customer zone. Access the link for quick updates and hassle-free PNG connection transactions."},
{name: 'keywords', content: "Application Form Png, MGL, Residential PNG,"},
{name: 'description', content:"Check your Residential PNG name transfer status at Mahanagar Gas customer zone. Access the link for quick updates and hassle-free transactions."},
{name: 'keywords', content: "MGL, Residential PNG,  Application status"},
{name: 'description', content:"Keep your home safe with our trusted Piped Natural Gas checklist. Ensure residential PNG safety with expert guidance from your reliable provider."},
{name: 'keywords', content: "png gas, piped natural gas, Residential PNG Safety"},
{name: 'description', content:"Switch to reliable, eco-friendly, and cost-effective energy solutions. Register for a Domestic Connection Request at Mahanagar Gas today!"},
{name: 'keywords', content: "png connection, natural gas service provider"},
{name: 'description', content:"Effortlessly apply online for piped natural gas and conveniently track your application status."},
{name: 'keywords', content: "Residential PNG, Domestic Connection Request , mgl connection status"},
{name: 'description', content:"Discover Residential PNG Solutions with Rate Cards at Mahanagar Gas. Experience the convenience of clean, efficient energy for your home."},
{name: 'keywords', content: "Piped Natural Gas prices , Residential PNG rate"},
{name: 'description', content:"Unlock the Ultimate Tech Guide for Residential PNG Installation! Switch to Cleaner, Efficient Energy with Piped Natural Gas (PNG) from Mahanagar Gas."},
{name: 'keywords', content: "PNG, Guidelines for PNG Installation , technical Guidelines"},
// {name: 'description', content:"Discover the convenience of Residential PNG with Mahanagar Gas. Access our registration form and terms & conditions."},
// {name: 'keywords', content: "mgl registration form, Mahanagar gas term & condition"},
{name: 'description', content:"Get Residential PNG Connections and Enjoy the Benefits of Piped Natural Gas with Mahanagar Gas. Explore Our Network Today!"},
{name: 'keywords', content: "MGL PNG Network , gas network of residential png , png network near me"},
{name: 'description', content:"Explore Residential PNG Connections. Authorized Personnel, Login Now"},
{name: 'keywords', content: "Piped Natural Gas,  Png client login"},
{name: 'description', content:"Stay Informed and Compliant with Mahanagar Gas' Comprehensive Policies and Procedures for Residential PNG (Piped Natural Gas). Explore Our Guidelines Now!"},
{name: 'keywords', content: "Important Policies of Mahanagar Gas, Billing Policy of mgl,PNG Safety Policy"},
{name: 'description', content:"Experience Hassle-Free After-Sales Services and Competitive PNG Service Charges for a Convenient and Reliable Piped Natural Gas Solution."},
{name: 'keywords', content: "Residential PNG Service Charges, Piped Natural Gas, labour charges"},
{name: 'description', content:"Trust Us for Expert After-Sales Services for Your Piped Natural Gas Needs."},
{name: 'keywords', content: "Piped Natural Gas (PNG) Service Provider , area distribution of PNG"},
{name: 'description', content:"Experience the Convenience and Safety of PNG (Piped Natural Gas) for Your Home with Mahanagar Gas. Enjoy Hassle-Free Energy with 24/7 Supply."},
{name: 'keywords', content: "Safety measures for gas usage, how to use png,"},
{name: 'description', content:"Explore Mahanagar Gas sitemap for easy navigation. Find information on Residential PNG, Domestic Connection Request, and Application status effortlessly."},
{name: 'keywords', content: "Residential PNG, Domestic Connection Request,  Application status"},
{name: 'description', content:"MGL terms and conditions, including policies related to the network,  privacy policy. Learn about how we protect your information and ensure a secure service."},
{name: 'keywords', content: "Mahanagar Gas Ltd terms and conditions, MGL PNG network"},
{name: 'description', content:"Empowering underprivileged youth with skills and opportunities for a brighter future through CSRL's work readiness program"},
{name: 'keywords', content: "Mgl Blog Unnati"},
{name: 'description', content:"Partnership between MGL and B-LNG accelerates LNG station expansion, fueling cleaner, sustainable transport solutions."},
{name: 'keywords', content: "Mahanagar Gas, Baidyanath LNG"},
{name: 'description', content:"From Remote Learning to Mask Production. Discover how MGL Disha project and social media are driving education and safety initiatives in the face of the pandemic"},
{name: 'keywords', content: "MGL Disha project"},
{name: 'description', content:"MGL Arogya's vital role in the fight against COVID-19, providing ventilators and healthcare support"},
{name: 'keywords', content: "MGL Arogya"},
{name: 'description', content:"MGL Aarogya: A Lifeline for Health & Humanity, Transforming Lives through Compassionate Healthcare Initiatives"},
{name: 'keywords', content: "MGL Arogya"},
{name: 'description', content:"Register with Mahanagar Gas, your trusted PNG provider, using our convenient user registration form. Join our network today!"},
{name: 'keywords', content: "Png Connection, Png network, Png Provider, Mgl user online registration"},
{name: 'description', content:"Explore MGL's CNG & PNG services and learn about the Chairman of MGL on our Corporate Leadership. Discover sustainable energy solutions"},
{name: 'keywords', content: "Cng & Png services , Chairman of mgl"},
{name: 'description', content:"Stay updated with Mahanagar Gas, the leading Natural Gas Distribution Company, and learn about CNG, PNG Services through our newsletters"},
{name: 'keywords', content: "Natural Gas Distribution Company, CNG, PNG Service Newsletter"},
{name: 'description', content:"MGL's 2022 Awards & Achievements in Natural Gas Distribution and Domestic Industrial Gas."},
{name: 'keywords', content: "Natural Gas Distribution Company, Domestic industrial Gas"},
{name: 'description', content:"Calculate your CNG savings with Mahanagar Gas, the leading CNG gas supplier. Use our CNG Savings Calculator for cost-effective solutions."},
{name: 'keywords', content: "cng gas supplier, CNG services Leading CNG Company"},
{name: 'description', content:"MGL's 2023 Awards & Achievements in Natural Gas Distribution and Domestic Industrial Gas."},
{name: 'keywords', content: "Natural Gas Distribution Company, Domestic industrial Gas"},
{name: 'description', content:"Optimize Your CNG Vehicle's Performance with Expert Car Care Services by Mahanagar Gas"},
{name: 'keywords', content: "CNG Car Care Tips, Services by  Mahanagar Gas"},
{name: 'description', content:"Contact Mahanagar Gas for Residential PNG Gas Connection - Your Trusted Customer Care Partner"},
{name: 'keywords', content: "Residential png gas connection, Customer Care, Mahanagar Gas"},
{name: 'description', content:"Explore MGL's Corporate CSR Policy in Leading CNG & PNG Services. Commitment to a Sustainable Future"},
{name: 'keywords', content: "Cng & Png services , Chairman of mgl"},
{name: 'description', content:"MGL takes a step to reduce costs with lowered CNG and Domestic PNG prices. Stay informed on our CNG & PNG network updates"},
{name: 'keywords', content: "Price of png, price of cng"},
{name: 'description', content:"MGL Arogya's : Free Eye Check-up Camp for Auto Rickshaw Drivers. Learn more about this initiative."},
{name: 'keywords', content: "MGL Arogya"},
{name: 'description', content:"Explore exciting career opportunities at Mahanagar Gas Limited. Join us and shape your future with MGL. Check our current openings today"},
{name: 'keywords', content: "career in mgl, jobs in mgl"},
{name: 'description', content:"Discover Mahanagar Gas, your trusted PNG provider. Learn about asset integrity at Mahanagar Gas for safe and reliable natural gas distribution."},
{name: 'keywords', content: "Png Connection, Png network, Png Provider, Mgl user online registration"},
{name: 'description', content:"Mahanagar Gas, CNG and PNG services. Convenient IC Bill payment online. Your trusted PNG provider."},
{name: 'keywords', content: "Cng & Png services, Png Provider, IC Bill Online"},
{name: 'description', content:"MGL's Saksham Cycle Rally promotes a greener lifestyle, health, and fuel conservation. Join the movement for a sustainable future."},
{name: 'keywords', content: "Residential png gas connection, Png Cng Service"},
{name: 'description', content:"MGL Adjusts CNG and Domestic PNG Rates Due to Rising Gas Costs. MGL continues to offer significant savings and convenience for its valued customers."},
{name: 'keywords', content: "png gas connection, CNG and Domestic PNG customers"},
{name: 'description', content:"25 Years of MGL: Celebrating a Legacy of Clean Energy. Explore MGL's journey and the release of a Special Postage Stamp, Special Cover, and Commemorative Volume"},
{name: 'keywords', content: "Environment-Friendly Natural Gas Distributor In Mumbai |"},
{name: 'description', content:"Discover Corporate Governance at MGL, led by our Chairman, ensuring top-tier CNG & PNG services in Mumbai. Learn more on our page"},
{name: 'keywords', content: "Cng & Png services , Chairman of mgl"},
{name: 'description', content:"Important Gas Price Update: MGL Adjusts CNG and Domestic PNG Prices in Mumbai. Discover the impact and continued benefits of our services"},
{name: 'keywords', content: "CNG and Domestic PNG in Mumbai , Price of CNG"},
{name: 'description', content:"Conveniently pay your gas bills online with MGL's secure QR payment platform"},
{name: 'keywords', content: "Bills Payment Online, Pay Gas Bills Online"},
{name: 'description', content:"Change Password for Customer Login at Mahanagar Gas. Secure and update your login credentials easily on our dedicated page."},
{name: 'keywords', content: "Change Password, Change Password of Customer Login"},
{name: 'description', content:"MGL Tez: Your Fueling Solution for CNG Commercial Vehicles. Discover how our app streamlines CNG refueling at bus depots."},
{name: 'keywords', content: "Cng Fuel Supply to B.E.S.T, CNG facility in BEST bus depots, MGL TEZ"},
{name: 'description', content:"How MGL's CSR initiatives uplift lives through assistive aids, education, and holistic development, reaching thousands across Mumbai and Raigad"},
{name: 'keywords', content: "MGL Saksham"},
{name: 'description', content:"Discover MGL's commitment to education through initiatives supporting out-of-school children, early childhood education, and Anganwadi workers"},
{name: 'keywords', content: "MGL Vidya"},
{name: 'description', content:"FAQS : Get answers to common questions and information about PNG CNG services with Mahanagar Gas."},
{name: 'keywords', content: "PNG CNG Services"},
{name: 'description', content:"MGL Investor Policies: Explore Our Commitment to CNG and PNG Supply. Visit our page for valuable insights"},
{name: 'keywords', content: "CNG,PNG Supplier, Mgl Investor Policies"},
{name: 'description', content:" MGL Sahayogi: Our commitment to protecting MGL's assets and ensuring the safety of our PNG gas supply. Learn more at Mahanagar Gas Limited."},
{name: 'keywords', content: "Leading PNG Gas Supplier  MGL Sahayogi"},
{name: 'description', content:"MGL rewards 'MGL CNG Chalate Raho' scheme participants with Rs. 5 lakhs. A memorable day with Managing Director, Mr. Sanjib Datta"},
{name: 'keywords', content: "MGL CNG Chalate Raho, leading Cng Gas Supplier"},
{name: 'description', content:"Leading CNG supplier in Mumbai, Navi Mumbai & Thane at Mahanagar Gas. Explore CNG gas, kits, and more for your needs"},
{name: 'keywords', content: "Leading CNG Supplier, CNG Gas IN Mumbai, Navi Mumbai & Thane"},
{name: 'description', content:"Important Gas Price Update: MGL Adjusts CNG and Domestic PNG Prices in Mumbai. Discover the impact and continued benefits of our services"},
{name: 'keywords', content: "CNG and Domestic PNG in Mumbai , Price of CNG"},
{name: 'description', content:"MGL Adjusts CNG and Domestic PNG Rates Due to Rising Gas Costs. MGL continues to offer significant savings and convenience for its valued customers."},
{name: 'keywords', content: "png gas connection, CNG and Domestic PNG customers"},
{name: 'description', content:"MGL initiates PNG supply at Raj Bhavan, extending the reach of cleaner energy to a prestigious location in South Mumbai, enhancing convenience and sustainability."},
{name: 'keywords', content: "PNG supplier | PNG supply at Raj Bhavan"},
{name: 'description', content:"Bridging Education Gaps: MGL's Commitment to Mainstreaming Out of School Children. Explore our blog for insights on education initiatives by MGL"},
{name: 'keywords', content: "Mainstreaming of Out of School Children, MGL blog"},
{name: 'description', content:"Empowering Women Through Skill Development: MGL Hunar's Impactful Initiative in Raigad District, Maharashtra"},
{name: 'keywords', content: "MGL Hunar, Blog"},
{name: 'description', content:"MGL Adjusts CNG and Domestic PNG Rates Due to Rising Gas Costs. MGL continues to offer significant savings and convenience for its valued customers."},
{name: 'keywords', content: "png gas connection, CNG and Domestic PNG customers"},
{name: 'description', content:"Important Gas Price Update: MGL Adjusts CNG and Domestic PNG Prices in Mumbai. Discover the impact and continued benefits of our services"},
{name: 'keywords', content: "CNG and Domestic PNG in Mumbai , Price of CNG"},
{name: 'description', content:"Unlock Savings with MGL CNG Mahotsav: Get INR 19,999 Benefits for New CNG Cars and Up to 5 Lakhs Incentive for CNG Commercial Vehicles."},
{name: 'keywords', content: "MGL CNG Mahotsav, cng gas Offer by mgl"},
{name: 'description', content:"Find the Nearest MGL CNG Station with Our CNG Locator. Locate convenient CNG gas stations for eco-friendly refueling"},
{name: 'keywords', content: "Nearest MGL CNG Station | Cng gas Station"},
{name: 'description', content:"Unlock Savings with MGL Hat-trick Yojana: Get up to Rs 40,000 grant on CNG Commercial Vehicle adoption. Join the green revolution now"},
{name: 'keywords', content: "CNG PNG Gas Leading Company, MGL Hat-trick Yojana"},
{name: 'description', content:"MGL Cardiology Ward at Nair Hospital A Step Towards Quality Healthcare for All. MGL's CSR initiative MGL Aarogya strengthens the hospital's cardiac care infrastructure"},
{name: 'keywords', content: "MGL Cardiology Ward, News"},
{name: 'description', content:"MGL and BMC Join Forces for a Greener Mumbai: Compressed Biogas Plant Set to Transform Waste into Green Energy, Aligning with Sustainable Development Goals."},
{name: 'keywords', content: "Compressed Biogas Plants in Mumbai,  MGL News"},
{name: 'description', content:"Seamless and Convenient: Get Your Domestic PNG Connection for Home with MGL. Enjoy safe and reliable natural gas supply"},
{name: 'keywords', content: "PNG Connection For Home, Domestic PNG Connection"},
{name: 'description', content:"Explore Mahanagar Gas' impactful CSR initiatives in their blog. Stay updated on MGL's community efforts and corporate social responsibility initiatives"},
{name: 'keywords', content: "Mgl Blog Unnati, Mahanagar Gas, CSR Initiative"},
{name: 'description', content:"Mahanagar Gas Limited Project Vikas initiative, fostering holistic development across six villages, emphasizing sanitation, infrastructure, and livelihood."},
{name: 'keywords', content: "Project Vikas initiative, Mahanagar Gas"},
{name: 'description', content:"MGL partners with Baidyanath LNG, fostering LNG infrastructure across Maharashtra, aiming to accelerate cleaner fuel adoption in long-haul transport"},
{name: 'keywords', content: "Mahanagar Gas, Baidyanath LNG"},
{name: 'description', content:"Latest milestone as they inaugurate the Savroli City Gate Station, fortifying gas supply to benefit CNG and PNG consumers in Khopoli and neighboring areas"},
{name: 'keywords', content: "Largest City Gas Distribution companies in India,  City Gate Station,"},
{name: 'description', content:" MGL's Investor Guide. Your go-to resource for insights into our role as a leading Natural Gas Distribution Company"},
{name: 'keywords', content: "Natural Gas Distribution Company"},
{name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'keywords', content: "Price of png, price of cng"},
{name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'keywords', content: "Price of png, price of cng"},
{name: 'description', content:"MGL takes a step to reduce costs with lowered CNG and Domestic PNG prices. Stay informed on our CNG & PNG network updates"},
{name: 'keywords', content: "Price of png, price of cng"},
{name: 'description', content:"MGL introduces a pioneering solution to reduce CNG refuelling wait times with the first Mobile Refuelling Unit in India"},
{name: 'keywords', content: "India's leading Natural Gas Distribution Companies"},
{name: 'description', content:"MGL's Expression of Interest for Supply of Compressed Biogas. Join us in advancing sustainable energy solutions. Learn more and participate today!"},
{name: 'keywords', content: "Supply of Compressed Biogas"},
{name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'keywords', content: "Price of png, price of cng"},
{name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'keywords', content: "Price of png, price of cng"},
{name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'keywords', content: "Price of png, price of cng"},
{name: 'description', content:"Mahanagar Gas Limited's vision and direction under the leadership of Managing Director, Mr. Ashu Shinghal. Explore our commitment to efficient energy solutions."},
{name: 'keywords', content: "Managing Director of Mahanagar Gas Limited, Mr. Ashu Shinghal"},
{name: 'description', content:"Stay updated on Mahanagar Gas Limited's latest news: reductions in CNG prices, changes in PNG rates, and current prices of CNG and PNG fuels. Get the newest updates here!"},
{name: 'keywords', content: "reduction in CNG price ,Price of png, price of cng"},
{name: 'description', content:"Explore MGL's video gallery, the leading PNG and CNG gas distribution company. Discover our services and commitment to clean energy solutions."},
{name: 'keywords', content: "Leading Png, Cng Gas Distribution Company, Gas Distribution Company"},
{name: 'description', content:"Reclaim your dividends with Mahanagar Gas. Explore the Unclaimed Dividend page to retrieve your earnings effortlessly. Secure your financial assets today."},
{name: 'keywords', content: "Mahanagar Gas, Dividends Page"},
{name: 'description', content:"Explore Mahanagar Gas' impactful CSR initiatives in their blog. Stay updated on MGL's community efforts and corporate social responsibility initiatives"},
{name: 'keywords', content: "Mgl Blog, Mahanagar Gas, CSR Initiative"},
{name: 'description', content:"About the Government of India's reduction in natural gas prices, impacting CNG and PNG rates. Explore the latest updates on CNG and PNG price adjustments by MGL"},
{name: 'keywords', content: "Natural Gas Price Reduction, reduction in CNG price ,Price of png, price of cng"},
{name: 'description', content:"Stay informed about Mahanagar Gas Limited's gas cost increase and its impact on natural gas, CNG, and PNG prices. Learn about reductions in CNG rates."},
{name: 'keywords', content: "CNG price ,Price of png, price of cng, Natural Gas Price"},
{name: 'description', content:"Experience the Convenience of Residential PNG with Mahanagar Gas. Access Our Registration Form and Terms & Conditions Today!"},
{name: 'keywords', content: "mgl registration form ,mahanagar gas term & condition"},


]);


     }

       public setTitle( newTitle: string) {
  this.title.setTitle( newTitle );
 }

  ngOnInit(): void {
    this.FaqService.title = "All Data";
    this.rightClickDisable.disableRightClick();
    if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }

     this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
         let mysrt1 = event.url
        let finalsrt1 = mysrt1.split("/")
        this.headerFooter = (event.url !== '/cng/cng-offers-schemes/mobile-app/retrofitment'  && event.url !=='/cng/cng-offers-schemes/mobile-app/new-cng-vehicle-promotion' && event.url !=='/app-pay-success' && event.url !=='/app-pay-error'
          && event.url !=='/create-wallet' && event.url !=='/view-wallet'
          && event.url !=='/view-payu-transaction'
          && event.url !=='/apppayment-success/'+finalsrt1[2]  && event.url !=='/apppayment-error/'+finalsrt1[2]+"/"+finalsrt1[3])
      }
    });

     this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
      //  console.log('event',event)

        let mysrt = event.url
        let finalsrt = mysrt.split("/")
       // console.log(finalsrt)
        this.rightMenu = (event.url !=='/app-pay-success' && event.url !=='/app-pay-error' && event.url !=='/apppayment-success/'+finalsrt[2]  && event.url !=='/apppayment-error/'+finalsrt[2]+"/"+finalsrt[3])
      }
    });


    this.setmySEO();

}

setmySEO(){
     this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
       // console.log(event.url)
      if(event.url =='/'){
        // this.setTitle("Mahanagar Gas: Reliable Gas Services Empowering Growth");
         this.setTitle("Mahanagar Gas: Innovating Natural Gas Solutions Across India");

       }else if(event.url =='/residential-png/customer-care/customer-care-overview'){
        this.setTitle("Mahanagar Gas: Comprehensive Piped Natural Gas (PNG) Customer Service");

       }else if(event.url =='/cng/overview/overview-enviro-benifits'){
        this.setTitle("Understanding Compressed Natural Gas (CNG) and Its Benefits");

       }else if(event.url =='/MGL-corporate/about-MGL'){
        this.setTitle("Mahanagar Gas: Leading Distributor of CNG and PNG Natural Gas");

       }else if(event.url =='/MGL-corporate/Contact-Us/registered-and-corporate-office-address'){
        this.setTitle("Mahanagar Gas: Expert PNG and CNG Services - Get in Touch with MGL!");

       }else if(event.url =='/MGL-corporate/awards'){
        this.setTitle("Mahanagar Gas Limited's Achievements and Recognitions");

       }else if(event.url =='/industrial-and-commercial/conservation-tips-for-industrial-usage/clien-tele'){
        this.setTitle("Comprehensive Natural Gas, CNG & PNG Services | Mahanagar Gas | Client Portfolio");

       }else if(event.url =='/commercial-png-application'){
        this.setTitle("Applications of Piped Natural Gas (PNG) | Mahanagar Gas");

       }else if(event.url =='/residential-png/customer-zone/customer-login'){
        this.setTitle("Mahanagar Gas: Customer Account Access via Secure Login");

       }else if(event.url =='/MGL-corporate/vision'){
        this.setTitle("PNG and CNG Gas Services & Registration | Mahanagar Gas");

       }else if(event.url =='/MGL-corporate/investors/annual-report'){
        this.setTitle("MGL Annual Report | Investors | Mahanagar Gas Ltd");

       }else if(event.url =='/residential-png/customer-zone/name-transfer'){
        this.setTitle("MGL | Residential PNG - Apply for Name Transfer");

       }else if(event.url =='/residential-png/customer-zone/name-transfer/apply-for-name'){
        this.setTitle("MGL | Residential PNG - Apply for Name Transfer | Application Form");

       }else if(event.url =='/residential-png/customer-zone/name-transfer/name-transfer-status'){
        this.setTitle("MGL | Residential PNG - Apply for Name Transfer | Application Status");

       }else if(event.url =='/residential-png/customer-zone/consumer-safty-checklist'){
        this.setTitle("Residential PNG Safety | Piped Natural Gas Checklist | MGL");

       }else if(event.url =='/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request'){
        this.setTitle("Residential PNG | Domestic Connection Request Application Form | MGL");

       }else if(event.url =='/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check'){
        this.setTitle("Residential PNG | Domestic Connection Request Status | MGL");

       }else if(event.url =='/residential-png/domestic-connection-request/png-rate-card'){
        this.setTitle("Residential PNG Rate Card | Piped Natural Gas Prices | Mahanagar Gas");

       }else if(event.url =='/residential-png/domestic-connection-request/tech-guide-for-png-installation'){
        this.setTitle("PNG Installation Guidelines | Mahanagar Gas");

       }else if(event.url =='/residential-png/domestic-connection-request/png-network'){
        this.setTitle("MGL | PNG Network and Residential PNG");

       }else if(event.url =='/residential-png/view-authorized-personnel'){
        this.setTitle("View Authorized Person for PNG | Mahanagar Gas");

       }else if(event.url =='/residential-png/policies-and-procedures/important-policy'){
        this.setTitle("Key Policies | Mahanagar Gas");

       }else if(event.url =='/residential-png/policies-and-procedures/aftersales-services/png-service-charges'){
        this.setTitle("Residential PNG Service Charges | Piped Natural Gas | Mahanagar Gas");

       }else if(event.url =='/residential-png/policies-and-procedures/aftersales-services/service-provider'){
        this.setTitle("Residential PNG Services | Mahanagar Gas | Piped Natural Gas");

       }else if(event.url =='/residential-png/if-you-smell-gas'){
        this.setTitle("Safely Use Piped Natural Gas | Mahanagar Gas Ltd.");

       }else if(event.url =='/sitemap'){
        this.setTitle("Sitemap: Browse Mahanagar Gas Website Structure");

       }else if(event.url =='/privacy-policy'){
        this.setTitle("Terms and conditions | Privacy Policy | Mahanagar Gas");

       }else if(event.url =='/blog/645e117faa47103642606283'){
        this.setTitle("Mahanagar Gas | Blogs | Unnati");

       }else if(event.url =='/blog/646d7ea243e2e031643f51db'){
        this.setTitle("Mahanagar Gas | Blogs | MoU Between MGL & Baidyanath LNG (B-LNG)");

       }else if(event.url =='/blog/6427e565e215531fbd0915f8'){
        this.setTitle("Mahanagar Gas | Blogs | Training for Mask & Sanitizer Making");

       }else if(event.url =='/blog/6427e518e215531fbd0915f7'){
        this.setTitle("MGL Arogya's: Healthcare Impact in Fighting the COVID-19");

       }else if(event.url =='/blog/645e107eaa47103642606248'){
        this.setTitle("MGL Aarogya: A Lifeline for Health & Humanity");

       }else if(event.url =='/user-registration'){
        this.setTitle("Mahanagar Gas | PNG Provider | User Registration online");

       }else if(event.url =='/MGL-corporate/leadership'){
        this.setTitle("Mahanagar Gas | Corporate Leadership | Leading PNG & CNG Service");

       }else if(event.url =='/MGL-corporate/media/newsletters'){
        this.setTitle("Natural Gas Distribution Company| MGL Newsletters");

       }else if(event.url =='/MGL-corporate/award/2022'){
        this.setTitle("Mahanagar Gas |  Awards 2022, Achievements and Recognitions");

       }else if(event.url =='/cng/customer-zone/saving-calculator'){
        this.setTitle("CNG Savings Calculator | Mahanagar Gas | CNG Gas Supplier");

       }else if(event.url =='/MGL-corporate/award/2023'){
        this.setTitle("Achievements and Recognitions | Mahanagar Gas| Awards 2023");

       }else if(event.url =='/cng/customer-zone/car-care'){
        this.setTitle("Comprehensive CNG Car Care Tips - Mahanagar Gas");

       }else if(event.url =='/residential-png/customer-care/dropbox'){
        this.setTitle("Customer Care | Mahanagar Gas | Residential PNG Connection");

       }else if(event.url =='/MGL-corporate/csr/csr-policy'){
        this.setTitle("Mahanagar Gas | Corporate CSR Policy | PNG & CNG Service");

       }else if(event.url =='/cng/dealer-zone/mobile-unit'){
        this.setTitle("MGL | Request for CNG Composite Dispensing Unit | CNG & PNG");

       }else if(event.url =='/MGL-corporate/media/news/647f110193ddf62e4901fd1f'){
        this.setTitle("MGL | Reduces CNG and Domestic PNG Price| News");

       }else if(event.url =='/MGL-corporate/media/news/641804a4f270bfcd0b874bb3'){
        this.setTitle("MGL Arogya : Free Eye check-up Camp for Auto rickshaw Drivers");

       }else if(event.url =='/MGL-corporate/career/current-opp'){
        this.setTitle("Mahanagar Gas | Career Opportunities");

       }else if(event.url =='/MGL-corporate/asset-integrity'){
        this.setTitle("Mahanagar Gas | Natural Gas Distribution Company | Asset-Integrity");

       }else if(event.url =='/industrial-and-commercial/ic-bill'){
        this.setTitle("MGL | CNG & PNG Services | IC Bill Online");

       }else if(event.url =='/MGL-corporate/media/news/647ef751bd5b92dcbc9a6bee'){
        this.setTitle("Mahanagar Gas | Conducts Cycle Rally in Thane and Navi Mumbai");

       }else if(event.url =='/MGL-corporate/media/news/647efc9a93ddf62e4901f70e'){
        this.setTitle("Mahanagar Gas | MGL Revises PNG and CNG price");

       }else if(event.url =='/MGL-corporate/media/news/647ef8b9bd5b92dcbc9a6ce2'){
        this.setTitle("Mahanagar Gas | MGL Celebrates its 25 years Journey");

       }else if(event.url =='/MGL-corporate/investors/corporate-governance'){
        this.setTitle("Mahanagar Gas | Corporate Governance | PNG & CNG Service");

       }else if(event.url =='/MGL-corporate/media/news/647ef6296733e3241f1c47fa'){
        this.setTitle("Mahanagar Gas | Price of CNG | Press Release");

       }else if(event.url =='/qr-pay'){
        this.setTitle("MGL | Bills Payment Online | Pay Gas Bills Online");

       }else if(event.url =='/forgot-password'){
        this.setTitle("Mahanagar Gas | Forgot Password | Change Password | Customer Login");

       }else if(event.url =='/blog/646d7e552f44e3e6b23a73c5'){
        this.setTitle("MGL TEZ | CNG Fuel Supply To B.E.S.T | CNG Facility");

       }else if(event.url =='/blog/645e0daceed5dca75bd4a094'){
        this.setTitle("Mahanagar Gas | MGL Saksham | Blog");

       }else if(event.url =='/blog/645e0d34352d3d11603845b6'){
        this.setTitle("Mahanagar Gas | MGL Vidya | Blog");

       }else if(event.url =='/faq-list'){
        this.setTitle("PNG CNG Services | FAQs List | Mahanagar Gas");

       }else if(event.url =='/MGL-corporate/investors/policies'){
        this.setTitle("Mahanagar Gas Limited | CNG,PNG Supplier |Inverter Policies");

       }else if(event.url =='/MGL-corporate/MGL-sahayogi'){
        this.setTitle("Mahanagar Gas Limited | MGL Sahayogi | Leading PNG Gas Supplier");

       }else if(event.url =='/MGL-corporate/media/news/647ef83d0e6dd0f9995922b5'){
        this.setTitle("Mahanagar Gas | MGL CNG Chalate Raho | News");

       }else if(event.url =='/cng/customer-zone/cng-kit'){
        this.setTitle("RTO Approved CNG Kit Distributors | Mahanagar Gas");

       }else if(event.url =='/MGL-corporate/media/news/647ef5db0e6dd0f9995921de'){
        this.setTitle("CNG and Domestic PNG in Mumbai | Price of CNG | Press Release");

       }else if(event.url =='/MGL-corporate/media/news/647efbcebc944f5ee92b898b'){
        this.setTitle("CNG and Domestic PNG Gas Line | MGL revises PNG and CNG price | MGL");

       }else if(event.url =='/MGL-corporate/media/news/648d580f9bd67db3519d242c'){
        this.setTitle("Mahanagar Gas Limited | PNG supply at Raj Bhavan");

       }else if(event.url =='/blog/6427e48ce215531fbd0915f5'){
        this.setTitle("Mahanagar Gas |  Mainstreaming of Out of School Children | Blog");

       }else if(event.url =='/blog/645f0056e1300f7505b39331'){
        this.setTitle("Mahanagar Gas | MGL Hunar | Blog");

       }else if(event.url =='/MGL-corporate/media/news/647f0f1a69e5bb3e83ab5a39'){
        this.setTitle("Mahanagar Gas | MGL Revises PNG and CNG Price");

       }else if(event.url =='/MGL-corporate/media/news/647efd82236384271725d31e'){
        this.setTitle("CNG and Domestic PNG in Mumbai | Press Release");

       }else if(event.url =='/cng/cng-offers-schemes/retrofitment'){
        this.setTitle("Mahanagar Gas Limited | MGL CNG Mahotsav | Retrofitment");

       }else if(event.url =='/cng/customer-zone/cng-locator'){
        this.setTitle("Mahanagar Gas Limited | MGLl CNG Station | CNG Locator");

       }else if(event.url =='/MGL-corporate/media/news/647efb6593ddf62e4901f6b3'){
        this.setTitle("Mahanagar Gas| MGL Hat-Trick Yojana");

       }else if(event.url =='/MGL-corporate/media/news/647f0f976733e3241f1c5029'){
        this.setTitle("Mahanagar Gas Limited | MGL Cardiology Ward | News");

       }else if(event.url =='/MGL-corporate/media/news/648d5a2eb7929ab6b40ef528'){
        this.setTitle("Compressed Biogas Plants in Mumbai | Mahanagar Gas Limited | News");

       }else if(event.url =='/residential-png/customer-zone/domestic-connection'){
        this.setTitle("PNG Connection For Home | Domestic PNG Connection | MGL");

       }else if(event.url =='/BlogLists'){
        this.setTitle("Mahanagar Gas | MGL Blog List | CSR Initiative");

       }else if(event.url =='/MGL-corporate/media/news/647efe706733e3241f1c4ab0'){
        this.setTitle("Mgl Blogs |  Vikas initiative");

       }else if(event.url =='/MGL-corporate/media/news/646d9d5ddb075d826be0f50e'){
        this.setTitle("Mahanagar Gas Limited (MGL) Collaborates with Baidyanath LNG to Develop LNG Station Network, Advancing Clean Fuel Adoption");

       }else if(event.url =='/MGL-corporate/media/news/641972bd9fe373041a80044b'){
        this.setTitle("MGL Expands Gas Distribution Network with Savroli City Gate Station");

       }else if(event.url =='/MGL-corporate/investors/investor-guide'){
        this.setTitle("MGL | Natural Gas Distribution Company | Investor Guide");

       }else if(event.url =='/MGL-corporate/media/news/647f10100e6dd0f9995929b1'){
        this.setTitle("MGL | CNG and Domestic PNG Price| CNG & PNG network | Apr 6, 2022");

       }else if(event.url =='/MGL-corporate/media/news/647f1162bd5b92dcbc9a743a'){
        this.setTitle("MGL | CNG and Domestic PNG Price| CNG & PNG network | Jan 31, 2023");

       }else if(event.url =='/MGL-corporate/media/news/647f1050bc944f5ee92b8fa6'){
        this.setTitle("MGL | Reduces CNG and Domestic PNG Price| CNG & PNG network");

       }else if(event.url =='/MGL-corporate/media/news/647efdfc93ddf62e4901f761'){
        this.setTitle("India's First Mobile CNG Refuelling Unit | Mahanagar Gas Limited");

       }else if(event.url =='/MGL-corporate/expression-of-interest'){
        this.setTitle("MGL | Supply of Compressed Biogas | Expression of Interest");

       }else if(event.url =='/MGL-corporate/media/news/647f0be20e6dd0f999592873'){
        this.setTitle("MGL | CNG and Domestic PNG Price| CNG & PNG network | Oct 4, 2022");

       }else if(event.url =='/MGL-corporate/media/news/647f0cc40e6dd0f9995928ac'){
        this.setTitle("MGL | CNG and Domestic PNG Price| CNG & PNG network | Aug 17, 2022");

       }else if(event.url =='/MGL-corporate/media/news/647f0d8e0e6dd0f9995928e5'){
        this.setTitle("MGL | CNG and Domestic PNG Price| CNG & PNG network | Jul 13, 2022");

       }else if(event.url =='/MGL-corporate/media/news/64180ba9f270bfcd0b874d40'){
        this.setTitle("Mahanagar Gas Limited | Managing Director | Mr. Ashu Shinghal");

       }else if(event.url =='/MGL-corporate/media/news/6519a69a033f13d26dadd071'){
        this.setTitle("Mahanagar Gas Limited latest updates about Cng price & Png price,");

       }else if(event.url =='/MGL-corporate/media/gallery/video-gallary'){
        this.setTitle("Leading Png, Cng Gas Distribution Company  | MGL | Video Gallery");

       }else if(event.url =='/MGL-corporate/investors/dividend/unclaimed-dividend'){
        this.setTitle("Mahanagar Gas | Unclaimed Dividend Detail Page");

       }else if(event.url =='/MGL-corporate/csr/csr-initiative'){
        this.setTitle("Mahanagar Gas | MGL Blog List | CSR Initiative");

       }else if(event.url =='/MGL-corporate/media/news/647ef966483322b4e4afd040'){
        this.setTitle("Natural Gas Price Reduction by the Government of India | MGL");

       }else if(event.url =='/MGL-corporate/media/news/647ef68a483322b4e4afcf07'){
        this.setTitle("Mahanagar Gas Limited | Gas Cost Rise: Updates & Impact");

       }else if(event.url =='/regitration-form-terms-and-condition_0d27e650ee.pdf'){
        this.setTitle("Terms & Conditions for Registration Form | Mahanagar Gas");

       }else{
         this.setTitle("Mahanagar Gas: Innovating Natural Gas Solutions Across India");
       }
      }
    });
    
}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      let loader = this.renderer.selectRootElement('#loader');
      if (loader.style.display != "none") loader.style.display = "none"; //hide loader
     // console.log("test view init")
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    if (window.pageYOffset > 120) {
      let element = this.document.getElementById('navbar');
      element!.classList.add('sticky');
    } else {
      let element = this.document.getElementById('navbar');
      element!.classList.remove('sticky');
    }
  }
}
