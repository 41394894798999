<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Gas Geyser Rate</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a >Gas Geyser</a></li>
                <li class="active" aria-current="page"><a>Gas Geyser Rate
                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <!-- <app-recent-post></app-recent-post>
 <app-tips></app-tips> -->
                 <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

                <!-- <div class="customer-slider mt-30 mb-30">
                    <owl-carousel-o [options]="networkOptions">
                        <ng-template carouselSlide>
                            <div style="width: 300px;" class="blog-item">
                                <div class="blog-content">
                                    <div class="number">1</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Open all Doors and Windows</p>

                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img1.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div style="width: 300px;" class="blog-item">
                                <div class="blog-content">
                                    <div class="number">2</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Turn off your gas supply by using Meter Control Valve (MCV)</p>

                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img2.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="blog-item">
                                <div class="blog-content">
                                    <div class="number">3</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Do not switch on or off any electrical switch</p>
                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img3.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="blog-item">
                                <div class="blog-content">
                                    <div class="number">4</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Extinguish all naked flames</p>

                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img4.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <div class="blog-item">
                            <div class="blog-content">
                                <div class="number">5</div>
                                <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                <p class="desc">Call emergency service no 18002669944 (Toll free) / (022)-68759400 /
                                    (022)-24012400 after coming out from the danger area</p>

                            </div>
                            <div class="image-wrap">
                                <a href="#"><img src="assets/images/safty-img5.jpg" alt="Blog"></a>
                            </div>
                        </div>

                    </owl-carousel-o>

                </div> -->


                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section">
                    <p>Service charges applicable for installation or removal of gas geyser along with the list of
                        approved service providers</p>
                    <p > <span>SERVICE CHARGES FOR INSTALLATION OR REMOVAL OF GAS GEYSER</span>
                    </p>
                    <table class="table dark-table-bordered" id="tablelist" text-align="center">
                        <tbody>
                            <tr>
                                <th>Sr No</th>
                                <th>SOR Description</th>
                                 <th>SOR No</th>
                                <th>UOM</th>
                                <th>Rate</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Addition / Alteration of Copper Tubing work (for Meter shifting/ Gas Geyser work)
                                </td>
                                <td>3</td>
                                <td>Meter</td>
                                <td>85</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Copper tube work up to 5 meters length (for Gas meter shifting / Installation and
                                    /or Removal of gas geyser). For more than 05 meters length, SOR 3 will also be
                                    applicable.</td>
                                      <td>5</td>
                                <td>Each</td>
                                <td>575</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Visit charge (applicable on third visit/ technical support request from customer/
                                    for Gas Geyser related work)</td>
                                     <td>13</td>
                                <td>Each</td>
                                <td>175</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="border-single"></div>
                    <ul class="applicants mt-30 mb-30">
                        <li>1. Installation and connection of waterpipeline to the geyser is in the scope of customer.
                        </li>
                        <li>2. CGST &amp; SGST will be applicable extra.</li>
                        <li>3. The cost of material is as per the MGL purchased price/rate of MGL approved vendors.</li>
                        <li>4. 5% extra charges will be applicable on labour charges for Service Providers operating in
                            South Zone i.e. from SION to CSMT &amp; CHURCHGATE</li>
                    </ul>
                    <div class="accordian_industries">
                        <div class="border-single"></div>
                        <h6 class="mt-20"><span>MGL APPROVED SERVICE PROVIDER FOR GAS GEYSER INSTALLATION</span>
                        </h6>
                        <div class="clear"></div>
                        <div class="accordianindustriesCont dealers_contace_table"
                            style="width: 100%; margin-right: 0px;">
                            <div style="overflow-x: auto;">
                                <table class="table table-bordered" id="tablelist">
                                    <tbody>
                                        <tr style="text-transform: uppercase;">
                                            <th colspan="2">Max Natural gas.Pvt.Ltd</th>
                                            <th colspan="2">Fixofit-Well</th>
                                            <th colspan="2">V.V.Prasad</th>
                                            <th colspan="2">Sahedul Sheikh</th>
                                            <th colspan="2">Aarti Enterprises</th>
                                            <th colspan="2">Rishi Infra</th>
                                            <th colspan="2">Samruddhi Express</th>
                                            <th colspan="2">Shri Engineers</th>
                                            <th colspan="2">Rathod Brothers</th>
                                        </tr>
                                        <tr>
                                            <th>DRS No.</th>
                                            <th>AREA</th>
                                            <th>DRS No.</th>
                                            <th>AREA</th>
                                            <th>DRS No.</th>
                                            <th>AREA</th>
                                            <th>DRS No.</th>
                                            <th>AREA</th>
                                            <th>DRS No.</th>
                                            <th>AREA</th>
                                            <th>DRS No.</th>
                                            <th>AREA</th>
                                            <th>DRS No.</th>
                                            <th>AREA</th>
                                            <th>DRS No.</th>
                                            <th>AREA</th>
                                            <th>DRS No.</th>
                                            <th>AREA</th>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Chembur,Chunabhatti</td>
                                            <td>14</td>
                                            <td>Juhu / Vile Parle (W)</td>
                                            <td>105 , 106, 107 ,108, 109</td>
                                            <td>Thane</td>
                                            <td>5</td>
                                            <td>Powai, Chandivali,Sakinaka</td>
                                            <td>34</td>
                                            <td>Parel</td>
                                            <td>16A</td>
                                            <td>Malad (E)</td>
                                            <td>6</td>
                                            <td>Andheri (E)</td>
                                            <td>301A</td>
                                            <td>Uran</td>
                                            <td>100A,101,101B,102A,102B102C,103A,104</td>
                                            <td>Mira Road, Bhayander</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Chembur, Deonar,&nbsp;</td>
                                            <td>12</td>
                                            <td>Andheri (W), Jogeshwari (W)</td>
                                            <td>142&amp;143</td>
                                            <td>Badlapur</td>
                                            <td>8</td>
                                            <td>Andheri (E ),Jogeshwari (E )</td>
                                            <td>29</td>
                                            <td>Sion, Wadala,Matunga ( E)</td>
                                            <td>17</td>
                                            <td>Kandivali (E)</td>
                                            <td>11</td>
                                            <td>Vile Parle (E)</td>
                                            <td>302A</td>
                                            <td>Ulwe</td>
                                            <td>24A</td>
                                            <td>Dahisar (W)</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Ghatkopar (E), Tilak Nagar, Pestom Sagar</td>
                                            <td>13</td>
                                            <td>Andheri (W)</td>
                                            <td>147A,148A&amp;148B,151A&nbsp;</td>
                                            <td>Dombivali</td>
                                            <td>15A</td>
                                            <td>Goregaon (E)</td>
                                            <td>&nbsp;36A</td>
                                            <td>Patiksha nagar</td>
                                            <td>15B</td>
                                            <td>Goregaon (W)</td>
                                            <td>9</td>
                                            <td>Bandra (E) &amp; Khar (E)</td>
                                            <td>310A</td>
                                            <td>Fortune city</td>
                                            <td>24B</td>
                                            <td>Borivali &amp; Dahisar (W)</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Kurla (W)</td>
                                            <td>27</td>
                                            <td>Santacruz (W)</td>
                                            <td>135, 136, 137</td>
                                            <td>Ambarnath</td>
                                            <td></td>
                                            <td></td>
                                            <td>&nbsp;35A</td>
                                            <td>Wadala</td>
                                            <td>16B,37A</td>
                                            <td>Malad (W)</td>
                                            <td>26</td>
                                            <td>Santacruz (E)</td>
                                            <td>401A</td>
                                            <td>Pen</td>
                                            <td>23A,23B</td>
                                            <td>Borivali (W)</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Ghatkopar (W)</td>
                                            <td>18A,18B</td>
                                            <td>Bandra (W) &amp;Khar (W)</td>
                                            <td>146A,140A,141A</td>
                                            <td>Kalyan</td>
                                            <td></td>
                                            <td></td>
                                            <td>25A,25B</td>
                                            <td>Matunga, Dadar / Mahim (W)</td>
                                            <td>16C</td>
                                            <td>Kandivali (W)</td>
                                            <td>28</td>
                                            <td>Bandra Kurla Complex</td>
                                            <td>308A</td>
                                            <td>India Bulls Panvel</td>
                                            <td>104</td>
                                            <td>Bhayander</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Vikhroli (E) &amp; (W)</td>
                                            <td></td>
                                            <td></td>
                                            <td>139A</td>
                                            <td>Shahad</td>
                                            <td></td>
                                            <td></td>
                                            <td>30</td>
                                            <td>Worli, Prabhadevi</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>22B</td>
                                            <td>Dahisar (E)</td>
                                        </tr>
                                        <tr>
                                            <td>19</td>
                                            <td>Mulund (W)</td>
                                            <td></td>
                                            <td></td>
                                            <td>114</td>
                                            <td>Juinagar</td>
                                            <td></td>
                                            <td></td>
                                            <td>31</td>
                                            <td>Tardeo</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>22A</td>
                                            <td>Borivali (E)</td>
                                        </tr>
                                        <tr>
                                            <td>19A</td>
                                            <td>Mulund (E)</td>
                                            <td></td>
                                            <td></td>
                                            <td>121A,121B</td>
                                            <td>CBD Belapur</td>
                                            <td></td>
                                            <td></td>
                                            <td>32</td>
                                            <td>Mahalaxmi</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>20</td>
                                            <td>Bhandup (W), Kanjur Marg (W)</td>
                                            <td></td>
                                            <td></td>
                                            <td>120A,120B</td>
                                            <td>Nerul</td>
                                            <td></td>
                                            <td></td>
                                            <td>33</td>
                                            <td>Marine Drive, Colaba</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>21</td>
                                            <td>Bhandup (E), Kanjur Marg (E)</td>
                                            <td></td>
                                            <td></td>
                                            <td>115A</td>
                                            <td>Seawoods</td>
                                            <td></td>
                                            <td></td>
                                            <td>40</td>
                                            <td>Navi nagar</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>42</td>
                                            <td>Kurla (W)</td>
                                            <td></td>
                                            <td></td>
                                            <td>131A</td>
                                            <td>Kharghar</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>132A</td>
                                            <td>Taloja</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>123A,123B</td>
                                            <td>Kharghar</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>118,118B</td>
                                            <td>Vashi</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>119</td>
                                            <td>Sanpada</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>129A</td>
                                            <td>Kalamboli</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>117,117B</td>
                                            <td>Ghansoli</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>116,116B</td>
                                            <td>Airoli</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>122</td>
                                            <td>Rabale</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>124</td>
                                            <td>Mahape</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>125</td>
                                            <td>Turbhe&nbsp;</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>126A</td>
                                            <td>Taloja</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>127A,128A</td>
                                            <td>New and Old Panvel</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>130A</td>
                                            <td>Kamothe</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</div>