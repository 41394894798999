<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
     <div class="banner-heading">
        <h1>Retrofitters Bill Processing Status</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav</a></li>
                <li class="active" aria-current="page"><a>Retrofitters Bill Processing Status</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->

<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">

            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        <div id="ContentPlaceHolder1_div_Customer_Address" class="mb-20">
                                  <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Reason & Status
                                    </div>
                                    <div class="row">
                                        
                                       
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Status: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.level_two_status}}</span>
                                            </h6>
                                        </div>

                                        <div class="col-lg-8 mb-20 name-trasfer-address" *ngIf="previouspageData1.level_two_status=='Rejected'">
                                            <p>Reject Reason: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.level_two_reason}}</span>
                                            </h6>
                                        </div>
                                       
                                       
                                    </div>
                                    
                                    
                                    
                                </div>
                                
                                  <div  class="form-group" *ngIf="previouspageData1.level_two_status=='Rejected'"><input type="submit" name="submit" value="Edit Application" class="greenbtn3 mt-20" (click)="gotoEdit()"></div>

                         <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Customer Information
                                    </div>
                                    <div class="row">
                                       
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Reference No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.refranceNumber}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Name: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Name}}</span>
                                            </h6>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Contact Number: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.ContactNumber}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Email: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Email}}</span>
                                            </h6>
                                        </div>
                                      
                                          <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Pan Number: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Pan}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Invoice No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.invoice_number}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Invoice Date: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.invoice_date}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Demand Note Number: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.demand_note_number}}</span>
                                            </h6>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Amount of Scheme: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.amount_of_scheme}}</span>
                                            </h6>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Booking Receipt Date: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.booking_receipt_date}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>CNG conversion Date: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.cng_conversion_date}}</span>
                                            </h6>
                                        </div>
                                          <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Vehicle Type: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.vehicleTpye}}</span>
                                            </h6>
                                        </div>
                                          <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Vehicle No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.vehicleNo}}</span>
                                            </h6>
                                        </div>
                                         
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Name of Kit Supplier: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.kit_Supplier}}</span>
                                            </h6>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>ECM Sr. No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.ECMSrNo}}</span>
                                            </h6>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Pressure regulator Sr. No.: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Pressure_regulator_SrNo}}</span>
                                            </h6>
                                        </div>
                                          
                                  </div>
                                </div>

                                <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Documents upload
                                    </div>
                                    <div class="row">
                                       
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Invoice Copy: </p>
                                            <a href="https://mahanagargas.com:3000/retrofitters_img/{{previouspageData1.invoice_copy}}" target="_blank" *ngIf="previouspageData1.invoice_copy!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>CNG kit retrofitment certificate: </p>
                                            <a href="https://mahanagargas.com:3000/retrofitters_img/{{previouspageData1.cng_certificate}}" target="_blank" *ngIf="previouspageData1.cng_certificate!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>RTO E Fee Receipt: </p>
                                            <a href="https://mahanagargas.com:3000/retrofitters_img/{{previouspageData1.rc_book}}" target="_blank" *ngIf="previouspageData1.rc_book!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Customer PAN Card: </p>
                                            <a href="https://mahanagargas.com:3000/retrofitters_img/{{previouspageData1.pan_card_img}}" target="_blank" *ngIf="previouspageData1.pan_card_img!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Installation Certificate: </p>
                                            <a href="https://mahanagargas.com:3000/retrofitters_img/{{previouspageData1.installation_certificate}}" target="_blank" *ngIf="previouspageData1.installation_certificate!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>

                                      
                                   </div>
                                    
                                </div>

                             

                        </div>
                    </div>
                </div>
            </div>



        </div> <!-- row end -->
    </div>
</div>


