import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-institution-error',
  templateUrl: './payment-institution-error.component.html',
  styleUrls: ['./payment-institution-error.component.css']
})
export class PaymentInstitutionErrorComponent {

}
