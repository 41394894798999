<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Domestic Connection Request Form</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG </a></li>
                <li class=""><a>Domestic Connection Request</a></li>
                <!--  <li class=""><a>Domestic PNG Registration</a></li> -->
                <li class="active" aria-current="page">Apply for PNG Connection</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>

<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-30">
                        <!--  <div class="white-box mt-30 mb-30 dom-con-req">
                                  <i class="demo-icon uil-notes"></i>    
                                 <p>If you wish to avail a new Domestic PNG connection from a Gasified building, please <a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Form']">Click here</a> </p>
                                </div>-->
                        <div class=" customer-care-overview-icon">

                            <div id="rs-services" class="rs-services style4">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 md-mb-30" ><!-- data-bs-toggle="modal" data-bs-target="#alert-dom"-->
                                        <a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Form']">
                                        <div class="services-item text-center">
                                            <div class="services-icon">
                                                <img class="dance_hover"
                                                    src="assets/images/domestic-connection-form-icons/Network-is-Available.png"
                                                    alt="Customer Care">
                                            </div>
                                            <div class="services-content">
                                                <!-- <h3 class="title">Customer Care</h3> -->
                                                <p class="margin-0"><strong>Apply for Domestic PNG
                                                        connection where Gas Supply is available</strong></p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>


                                    <div class="col-lg-6 col-md-6 md-mb-30">
                                        <a
                                            [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Non-gasified']">
                                            <div class="services-item text-center">
                                                <div class="services-icon">
                                                    <img class="dance_hover"
                                                        src="assets/images/domestic-connection-form-icons/No-Network-is-Available.png"
                                                        alt="Distribution Company">
                                                </div>
                                                <div class="services-content">
                                                    <!--  <h3 class="title">Distribution Company</h3> -->
                                                    <p class="margin-0"><strong>New PNG Request where Gas
                                                            Supply is not available</strong></p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                </div>

                            </div>

                            <div class="white-box mt-30 mb-30">
                                <p>If you have already submitted your Application, click the link below to View the
                                    status of application / re-print your Application Form / Pay Registration charges
                                    online</p>
                                <a class="bluebtn2"
                                    [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check']">View
                                    Status/Print Application Form <i class="demo-icon uil-print"></i></a>
                            </div>
                            <div class="white-box mt-30 mb-30 dom-con-req">
                                <i class="demo-icon uil-notes"></i>
                                <p>Tenant can avail PNG connection by submitting the NOC of the owner as per the
                                    prescribed format. To download the NoC format, please <a
                                        href="assets/images/pdf/_noc_c94eddee8e_new.pdf" target="_blank">Click here</a>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>

<div class="modal fade" id="alert-dom" tabindex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="alert-domModalLabel">Alert</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i
                        class="demo-icon uil-multiply"></i></button>
            </div>
            <div class="modal-body">
                <p class="mb-15"><b><i>Dear Customer,</i></b></p>
                <p class="mb-15"><b><i>The website is under maintenance for 2 hours.</i></b></p>
                <p class="mb-15"><b><i>You may kindly contact us on customer care no - (022)-68674500 / (022)-61564500,
                            so that our representative can visit you as per your convenience.</i></b></p>
                <p class="mb-15"><b><i>Regret the inconvenience caused.</i></b></p>
                <p class="mb-15"><b><i>Regards,</i></b></p>
                <p class="mb-15"><b><i>MGL Team</i></b></p>
            </div>
        </div>
    </div>
</div>