<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Edit Name Transfer Request
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential Png</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Name Transfer</a></li>
                <li class="active" aria-current="page">Edit Name Transfer Request
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Description"></a>

                                </div>
                            </div>
                            <a href="#" class="greenbtn2 d-block mt-20">View All <i
                                    class="demo-icon uil-arrow-right"></i></a>
                        </div>
                    </div>
                </div>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div>
            </div>




            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">


                <div class="customer-login-page log-meter-reading">

                    <form [formGroup]="CustomerInfoForm">
                        <div class="light-blue-bg mt-10">
                            <!-- <p><b><i>* Kindly Fill All Fields</i></b></p> -->

                            <div class="row">
                                <!-- <p><b><i>New Customer Information </i></b></p> -->



                                <div class="col-lg-2 col-md-2 col-sm-12 mb-3">
                                    <div class="form-floating">
                                        <select
                                            [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['NameInitial'].errors}"
                                            class="form-select" required formControlName="NameInitial">
                                            <option value="">Initial</option>
                                            <option *ngFor="let i of NameInitial" value="{{i}}">{{i}}</option>


                                        </select>
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['NameInitial'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['NameInitial'].errors.required">
                                                Required Field</div>

                                        </div>
                                        <label for="Captcha">Initial<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>




                                <div class="col-lg-4 col-md-4 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="Fname"
                                            placeholder="Fname"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['Fname'].errors }"
                                            id="Fname" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['Fname'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['Fname'].errors.required">Required
                                                Field</div>

                                        </div>
                                        <label for="Captcha">First Name<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="Mname"
                                            placeholder="Mname"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['Mname'].errors }"
                                            id="Mname" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['Mname'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['Mname'].errors.required">Required
                                                Field</div>

                                        </div>
                                        <label for="Mname">Middle Name</label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">



                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="lname"
                                            placeholder="lname"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['lname'].errors }"
                                            id="lname" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['lname'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['lname'].errors.required">Required
                                                Field</div>

                                        </div>
                                        <label for="lname">Last Name<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="FHusbandName"
                                            placeholder="FHusbandName"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['FHusbandName'].errors }"
                                            id="FHusbandName" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['FHusbandName'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['FHusbandName'].errors.required">
                                                Required Field</div>

                                        </div>
                                        <label for="FHusbandName">Father/Husband's Name</label>
                                    </div>
                                </div>


                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input type="date" class="form-control" formControlName="DOB" placeholder="DOB"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['DOB'].errors }"
                                            id="DOB" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['DOB'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['DOB'].errors.required">Required Field
                                            </div>

                                        </div>
                                        <label for="DOB">Date Of Birth</label>
                                    </div>
                                </div>


                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <select #Occ formControlName="Occupation"
                                            [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['Occupation'].errors}"
                                            class="form-select" required>
                                            <option value="" selected>Select Gender</option>
                                            <option *ngFor="let i of Occupation" value="{{i}}">{{i}}</option>


                                        </select>
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['Occupation'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['Occupation'].errors.required">
                                                Required Field</div>

                                        </div>
                                        <label for="Occupation">Gender</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="light-blue-bg mt-10">
                            <div class="row">
                                <p><b><i>Contact Information</i></b></p>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">



                                    <div class="form-floating">
                                        <input onKeyPress="if(this.value.length==10) return false;" type="text"
                                            class="form-control panupper" formControlName="Pan" placeholder="Pan"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['Pan'].errors }"
                                            id="Pan" />

                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['Pan'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['Pan'].errors.required">Required Field
                                            </div>
                                            <div *ngIf="CustomerInfoForm.controls['Pan'].errors?.['pattern']">PAN No
                                                Invalid</div>
                                        </div>
                                        <label for="Pan">Pan No<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input onKeyPress="if(this.value.length==10) return false;" type="number"
                                            class="form-control" formControlName="MobileNo" placeholder="MobileNo"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['MobileNo'].errors }"
                                            id="MobileNo" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['MobileNo'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['MobileNo'].errors.required">Required
                                                Field</div>

                                        </div>
                                        <label for="MobileNo">Mobile No<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input onKeyPress="if(this.value.length==10) return false;" type="number"
                                            class="form-control" formControlName="TelePhone_Res"
                                            placeholder="TelePhone_Res"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['TelePhone_Res'].errors }"
                                            id="TelePhone_Res" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['TelePhone_Res'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['TelePhone_Res'].errors.required">
                                                Required Field</div>

                                        </div>
                                        <label for="TelePhone_Res">Telephone No(Resident)</label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input onKeyPress="if(this.value.length==10) return false;" type="number"
                                            class="form-control" formControlName="TelePhone_Off"
                                            placeholder="TelePhone_Off"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['TelePhone_Off'].errors }"
                                            id="TelePhone_Off" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['TelePhone_Off'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['TelePhone_Off'].errors.required">
                                                Required Field</div>

                                        </div>
                                        <label for="TelePhone_Off">Telephone No(Office)</label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control" formControlName="Email"
                                            placeholder="Email"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['Email'].errors }"
                                            id="Email" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['Email'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['Email'].errors.required">Required
                                                Field</div>

                                        </div>
                                        <label for="Email">Email<span style="color: crimson;">*</span></label>
                                    </div>


                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">

                                    <div class="form-floating">
                                        <input onKeyPress="if(this.value.length==10) return false;" type="number"
                                            class="form-control" formControlName="AltMobileNo" placeholder="AltMobileNo"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['AltMobileNo'].errors }"
                                            id="AltMobileNo" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['AltMobileNo'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['AltMobileNo'].errors.required">
                                                Required
                                                Field</div>

                                        </div>
                                        <label for="AltMobileNo">Alternate Mobile No</label>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="light-blue-bg mt-10">
                            <div class="row">
                                <p><b><i>Details of Meter installed at premise</i></b></p>
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input type="number" class="form-control" formControlName="MeterNo"
                                            placeholder="MeterNo"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['MeterNo'].errors }"
                                            id="MeterNo" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['MeterNo'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['MeterNo'].errors.required">Required
                                                Field</div>

                                        </div>
                                        <label for="MeterNo">Meter No</label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input type="number" class="form-control" formControlName="MeterReading"
                                            placeholder="MeterReading"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['MeterReading'].errors }"
                                            id="MeterReading" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['MeterReading'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['MeterReading'].errors.required">
                                                Required Field</div>

                                        </div>
                                        <label for="MeterReading">Meter Reading</label>
                                    </div>
                                </div>


                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label class="font-size11">Meter Photo Upload</label>
                                    <div class="mb-20 upload-input">

                                        <input accept=".jpg, .jpeg, .png" (change)="fileCheckForMeter($event)"
                                            formControlName="MeterPhoto"
                                            [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['MeterPhoto'].errors}"
                                            class="from-control" type="file" id="name" name="name"
                                            placeholder="Enter Meter Photo Upload">
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['MeterPhoto'].errors"
                                            class="invalid-feedback">
                                            <div style="white-space: nowrap;"
                                                *ngIf="CustomerInfoForm.controls['MeterPhoto'].errors">Required Field
                                            </div>
                                        </div>
                                        <div>
                                            <a href="https://mahanagargas.com:3000/{{previouspageData1.MeterPhoto}}"
                                                target="_blank" *ngIf="previouspageData1.MeterPhoto!=''">
                                                <h6> <span class="badge bg-secondary">View Old Pohoto</span></h6>
                                            </a>
                                        </div>

                                    </div>


                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <select #status (change)="HaveConnection(status.value)"
                                            formControlName="LpgGasConn"
                                            [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['LpgGasConn'].errors}"
                                            class="form-select">
                                            <option value="" selected>Select Option</option>
                                            <option *ngFor="let i of HaveConn" value="{{i}}">{{i}}</option>


                                        </select>
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['LpgGasConn'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['LpgGasConn'].errors.required">
                                                Required Field</div>

                                        </div>
                                        <label for="LpgGasConn">Do You Have LPG Gas Connection in your Name<span
                                                style="color: crimson;">*</span></label>
                                    </div>


                                </div>

                                <div *ngIf="LpgConn" class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="GasSuppli"
                                            placeholder="GasSuppli"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['GasSuppli'].errors }"
                                            id="GasSuppli" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['GasSuppli'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['GasSuppli'].errors.required">Required
                                                Field</div>

                                        </div>
                                        <label for="GasSuppli">Gas Supplier<span
                                                style="color: crimson;">*</span></label>
                                    </div>
                                </div>


                                <div *ngIf="LpgConn" class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="GasDistri"
                                            placeholder="GasDistri"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['GasDistri'].errors }"
                                            id="GasDistri" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['GasDistri'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['GasDistri'].errors.required">Required
                                                Field</div>

                                        </div>
                                        <label for="GasDistri">Gas Distributor<span
                                                style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                                <div *ngIf="LpgConn" class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input type="number" class="form-control" formControlName="LpgConnNo"
                                            placeholder="LpgConnNo"
                                            [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['LpgConnNo'].errors }"
                                            id="LpgConnNo" />
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['LpgConnNo'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['LpgConnNo'].errors.required">Required
                                                Field</div>

                                        </div>
                                        <label for="LpgConnNo">LPG Connection Number<span
                                                style="color: crimson;">*</span></label>
                                    </div>
                                </div>


                                <div *ngIf="LpgConn" class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label class="font-size11">Lpg Connection Surrender Slip</label>
                                    <div class="mb-20 upload-input">

                                        <input accept=".pdf" (change)="fileCheckForSlip($event,'LpgSurrSlip')"
                                            formControlName="LpgSurrSlip"
                                            [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['LpgSurrSlip'].errors}"
                                            class="from-control" type="file" id="name" name="name"
                                            placeholder="Enter Lpg Connection Surrender Slip">
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['LpgSurrSlip'].errors"
                                            class="invalid-feedback">
                                            <div style="white-space: nowrap;"
                                                *ngIf="CustomerInfoForm.controls['LpgSurrSlip'].errors">
                                                Required Field
                                            </div>
                                        </div>
                                        <div>
                                            <a href="https://mahanagargas.com:3000/{{previouspageData1.LpgSurrSlip}}"
                                                target="_blank" *ngIf="previouspageData1.LpgSurrSlip!=''">
                                                <h6> <span class="badge bg-secondary">View Old DOC</span></h6>
                                            </a>
                                        </div>

                                    </div>
                                </div>


                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <select #Reason (change)="ReasonForTransfer(Reason.value)"
                                            formControlName="ResForTrans"
                                            [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['ResForTrans'].errors}"
                                            class="form-select" required>
                                            <option value="" selected>Select Option</option>
                                            <option *ngFor="let i of AllReason , index as j" [value]="i.ReasonText">
                                                {{i.ReasonText}}</option>


                                        </select>
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['ResForTrans'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['ResForTrans'].errors.required">
                                                Required Field</div>

                                        </div>
                                        <label for="LpgConnNo">Reason for transfer of PNG Connection<span
                                                style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                                <div class="box100per fl_wrap">
                                    <div class="mb-20"><strong class="badge badge-danger">Documents required *</strong>
                                    </div>
                                    <samp><b id="spDocuments"></b></samp>

                                    <div>
                                        <strong>Note: </strong>
                                        <p>
                                            Agreement pages referring to pervious and current owner name along
                                            with address to be uploaded.</p>
                                    </div>
                                    <div>

                                        <p>
                                            <strong>Note:</strong> Attach previous owner's PAN card with NOC,
                                            while uploading NOC.
                                        </p>
                                    </div>

                                    <div class="row">
                                        <div *ngIf="ShowDoc1" class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                            <div class="form-floating">
                                                <select formControlName="DocName1"
                                                    [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['DocName1'].errors}"
                                                    class="form-select" required>
                                                    <option value="" selected>Select Option
                                                    </option>
                                                    <option *ngFor="let i of Data1 , index as j" [value]="i">
                                                        {{i}}</option>


                                                </select>
                                                <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['DocName1'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="CustomerInfoForm.controls['DocName1'].errors.required">
                                                        Required Field</div>

                                                </div>
                                                <label for="LpgConnNo">{{Doc1Text}}<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div *ngIf="ShowDoc1" class="col-lg-6 col-md-6 col-sm-12 mb-3">

                                            <div class="upload-input death-cert">

                                                <input accept=".pdf" (change)="fileCheckForSlip($event,'DocName1File')"
                                                    formControlName="DocName1File"
                                                    [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['DocName1File'].errors}"
                                                    class="from-control" type="file" id="name" name="name"
                                                    placeholder="Enter Lpg Connection Surrender Slip">
                                                <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['DocName1File'].errors"
                                                    class="invalid-feedback">
                                                    <div style="white-space: nowrap;"
                                                        *ngIf="CustomerInfoForm.controls['DocName1File'].errors">
                                                        Required Field
                                                    </div>
                                                </div>

                                                <div>
                                                    <a href="https://mahanagargas.com:3000/{{previouspageData1.DocName1File}}"
                                                        target="_blank" *ngIf="previouspageData1.DocName1File!=''">
                                                        <h6> <span class="badge bg-secondary">View Old DOC</span></h6>
                                                    </a>
                                                </div>
                                            </div>


                                            <p class="font-size11">Only .pdf file is allowed and size within 2 MB</p>
                                        </div>
                                        <div class="pb-20">
                                            <div class="border-single"></div>
                                        </div>

                                        <div *ngIf="ShowDoc2" class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                            <div class="form-floating">
                                                <select #doc2 formControlName="DocName2"
                                                    [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['DocName2'].errors}"
                                                    class="form-select" required>
                                                    <option value="" selected>Select Option
                                                    </option>
                                                    <option *ngFor="let i of Data2 , index as j" [value]="i">
                                                        {{i}}</option>


                                                </select>
                                                <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['DocName2'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="CustomerInfoForm.controls['DocName2'].errors.required">
                                                        Required Field</div>

                                                </div>
                                                <label for="LpgConnNo">{{Doc2Text}}<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div *ngIf="ShowDoc2" class="col-lg-6 col-md-6 col-sm-12 mb-3">

                                            <div class="upload-input">

                                                <input accept=".pdf" (change)="fileCheckForSlip($event,'DocName2File')"
                                                    formControlName="DocName2File"
                                                    [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['DocName2File'].errors}"
                                                    class="from-control" type="file" id="name" name="name"
                                                    placeholder="Enter Lpg Connection Surrender Slip">
                                                <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['DocName2File'].errors"
                                                    class="invalid-feedback">
                                                    <div style="white-space: nowrap;"
                                                        *ngIf="CustomerInfoForm.controls['DocName2File'].errors">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <div>
                                                    <a href="https://mahanagargas.com:3000/{{previouspageData1.DocName2File}}"
                                                        target="_blank" *ngIf="previouspageData1.DocName2File!=''">
                                                        <h6> <span class="badge bg-secondary">View Old DOC</span></h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <p class="font-size11">Only .pdf file is allowed and size within 2 MB</p>


                                        </div>
                                        <div class="pb-20">
                                            <div class="border-single"></div>
                                        </div>
                                        <div *ngIf="ShowDoc3" class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                            <div class="form-floating">
                                                <select #doc3 formControlName="DocName3"
                                                    [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['DocName3'].errors}"
                                                    class="form-select" required>
                                                    <option value="" selected>Select Option
                                                    </option>
                                                    <option *ngFor="let i of Data3 , index as j" [value]="i">
                                                        {{i}}</option>


                                                </select>
                                                <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['DocName3'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="CustomerInfoForm.controls['DocName3'].errors.required">
                                                        Required Field</div>
                                                    <!-- <div *ngIf="ValidationForm.controls['Fname'].errors.pattern">MobileNo should be in 12 digits.</div> -->
                                                </div>
                                                <label for="LpgConnNo">{{Doc3Text}}<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div *ngIf="ShowDoc3" class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                            <!-- <label>{{Doc3Text}}</label> -->

                                            <div class="upload-input">
                                                <!-- <i class="demo-icon uil-clipboard-notes"></i> -->
                                                <input accept=".pdf" (change)="fileCheckForSlip($event,'DocName3File')"
                                                    formControlName="DocName3File"
                                                    [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['DocName3File'].errors}"
                                                    class="from-control" type="file" id="name" name="name"
                                                    placeholder="Enter Lpg Connection Surrender Slip">
                                                <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['DocName3File'].errors"
                                                    class="invalid-feedback">
                                                    <div style="white-space: nowrap;"
                                                        *ngIf="CustomerInfoForm.controls['DocName3File'].errors">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <div>
                                                    <a href="https://mahanagargas.com:3000/{{previouspageData1.DocName3File}}"
                                                        target="_blank" *ngIf="previouspageData1.DocName3File!=''">
                                                        <h6> <span class="badge bg-secondary">View Old DOC</span></h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <p class="font-size11">Only .pdf file is allowed and size within 2 MB</p>


                                        </div>
                                    </div>
                                    <div></div>

                                </div>


                                <div class="box100per">
                                    <div class="d-inline"><strong class="d-inline"
                                            style="color:#000;">Declaration</strong><strong>*</strong>:
                                        <div class="form-check d-inline-flex">
                                            <input
                                                [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['Declaration'].errors}"
                                                formControlName="Declaration" id="ContentPlaceHolder1_chkDeclaration"
                                                type="checkbox" name="ctl00$ContentPlaceHolder1$chkDeclaration"
                                                class="form-check-input">

                                            <label class="form-check-label" style="margin-top:2px"> <a
                                                    href="../../../assets/images/pdf/_Name_transfer_&_registration_form_T&C_6de4837bfa.pdf?rand=1781308971"
                                                    target="_blank">I accept</a></label>
                                        </div>
                                    </div>

                                    <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['Declaration'].errors"
                                        class="invalid-feedback">
                                        <div *ngIf="CustomerInfoForm.controls['Declaration'].errors">
                                            Required Field
                                        </div>
                                    </div>

                                    <b id="spDeclaration"></b>

                                    <div></div>

                                    <ul class="apply-list-sect">
                                        <li>a. I/We declare that whatever stated herein above is true and correct and no
                                            information has been concealed. If any information provided found to be
                                            incorrect Mahanagar Gas Limited shall be entitled to disconnect PNG supply
                                            without any notice.</li>


                                        <li> b. I/We confirm that I/We have read and understood the terms & conditions
                                            on the reverse hereof.</li>

                                        <li> c. I/We accept & agree to the said terms & conditions, as amended from time
                                            to time.</li>

                                        <li> d. I/We agree to settle the unpaid connection related charges gas
                                            consumption bills of the previous gas consumer.</li>

                                        <li> e. MGL Officer shall have right to reject the application without assigning
                                            any reasons thereof. </li>
                                    </ul>

                                    <div class="mb-20 mt-20 ">
                                        <div class="d-inline"><strong>Terms &amp; Conditions</strong><strong>*</strong>
                                            : </div>
                                        <div class="d-inline-flex">
                                            <div class="form-check">
                                                <input
                                                    [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['TNC'].errors}"
                                                    formControlName="TNC" id="ContentPlaceHolder1_chkTerm"
                                                    type="checkbox" name="ctl00$ContentPlaceHolder1$chkTerm"
                                                    class="form-check-input">


                                                <label class="form-check-label"> <a
                                                        href="../../../assets/images/pdf/_Name_transfer_&_registration_form_T&C_6de4837bfa.pdf?rand=1781308971"
                                                        target="_blank">I accept all Terms &amp; Conditions</a></label>
                                            </div>
                                        </div>
                                        <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['TNC'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['TNC'].errors">
                                                Required Field
                                            </div>
                                        </div>
                                    </div>
                                    <b id="spTerms"></b>


                                    <div class="clear"></div>
                                    <span class="d-inline"><strong>NO OBJECTION CERTIFICATE (NOC) FROM THE PREVIOUS GAS
                                            CONSUMER IS NOT AVAILABLE</strong>: </span>
                                    <div class="d-inline-flex">
                                        <div class="form-check">
                                            <input
                                                [ngClass]="{'is-invalid':CustomerformSubmited && CustomerInfoForm.controls['Noc'].errors}"
                                                formControlName="Noc" id="ContentPlaceHolder1_chkNoObjection"
                                                type="checkbox" name="ctl00$ContentPlaceHolder1$chkNoObjection"
                                                class="form-check-input">
                                            <label class="form-check-label"> <a href="javascript:void(0)">I
                                                    accept</a></label>
                                        </div>
                                    </div>
                                    <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['Noc'].errors"
                                        class="invalid-feedback">
                                        <div style="white-space: nowrap;"
                                            *ngIf="CustomerInfoForm.controls['Noc'].errors">
                                            Required Field
                                        </div>
                                    </div>
                                    <br>
                                    <b id="spNoObjection"></b>

                                    <div></div>

                                    <p class="mt-10">
                                        I, the applicant named above do hereby declare that I shall defend,
                                        indemnify and hold harmless MGL from all claims and losses that MGL may
                                        sustain or incur as a result, whether directly or indirectly in not
                                        providing NOC from the previous owner
                                    </p>

                                </div>


                                <div id="ContentPlaceHolder1_div_Customer_Address" class="mb-20">
                                    <div class="name-transfer-box">
                                        <div class="user-add-heading">
                                            Name Changes is being processed for following address
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <span>BP No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{previouspageData1.BPno}}</span></b>

                                            </div>

                                            <div class="col-lg-6">
                                                <span>CA No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingCANo">{{previouspageData1.CAno}}</span></b>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="name-transfer-box mt-30">

                                        <div class="user-add-heading">
                                            Address
                                        </div>
                                        <div class="row">

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Flat/Room No: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtFlat">{{this.CustomerInfoForm.get('flatNo')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Floor: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtFloor">{{this.CustomerInfoForm.get('floor')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Wing: </p>
                                                <h6> <span
                                                        id="ContentPlaceHolder1_txtWing">{{this.CustomerInfoForm.get('wing')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Plot No: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtPlot">{{this.CustomerInfoForm.get('plot')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Building Name: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtBuildingName">{{this.CustomerInfoForm.get('BuildingName')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Colony Name: </p>
                                                <h6> <span
                                                        id="ContentPlaceHolder1_txtColony">{{this.CustomerInfoForm.get('Colony')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Road Name: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtRoadName">{{this.CustomerInfoForm.get('RoadName')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Location: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtLocation">{{this.CustomerInfoForm.get('Location')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Land Mark: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtLandMark">{{this.CustomerInfoForm.get('Landkmark')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>City: </p>
                                                <h6> <span
                                                        id="ContentPlaceHolder1_txtCity">{{this.CustomerInfoForm.get('cityDist')?.value}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Pincode: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtPin">{{this.CustomerInfoForm.get('Pincode')?.value}}</span>
                                                </h6>

                                            </div>

                                        </div>
                                    </div>

                                </div>




                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-12">

                                                <div class="mt-20">

                                                    <div class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-7 col-sm-12">


                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': CustomerformSubmited && CustomerInfoForm.controls['Captcha'].errors }"
                                                        id="Captcha" />
                                                    <div *ngIf="CustomerformSubmited && CustomerInfoForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="CustomerInfoForm.controls['Captcha'].errors.required">
                                                            Required Field</div>

                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button [disabled]="finalSubmit" (click)="submitCustomerForm()"
                                            class="{{finalSubmit ? 'greybtn' : 'greenbtn2'}}">Submit<i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>

                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                        <button
                                            (click)="this.router.navigate(['/residential-png/customer-zone/name-transfer'])"
                                            class="greybtn">Cancel<i class="demo-icon uil-times-circle"></i></button>
                                    </div>
                                </div>


                                <div *ngIf="alertType == '3rdSubmit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>






            </div>
        </div>
    </div>
</div>