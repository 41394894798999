<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/cng-rate-inner-banner.jpg">
    <div class="banner-heading">
        <h1>OEMs New Vehicles Level One</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav 2.0</a></li>
                <li class="active" aria-current="page"><a>OEMs New Vehicles Level One</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips> 
                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>

          
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <!-- <div class="mb-30">
                    <img src="assets/images/MGLHalf.jpeg" class="w-70">
                </div> -->
                  <div class="light-blue-bg">
                        <!-- <h5>New CNG Vehicle (Maruti Suzuki)</h5> -->
                       <div class="row">
                          <div class="customer-login-page log-meter-reading">

                            <form [formGroup]="promotionForm">
                                 <div class="row">
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Name"
                                                placeholder="Customer Name"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['Name'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Customer Name<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                          <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="ContactNumber"
                                                placeholder="Customer Contact Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['ContactNumber'].errors }"
                                                id="ContactNumber" onKeyPress="if(this.value.length==10) return false;" />
                                            <div *ngIf="submitted && promotionForm.controls['ContactNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['ContactNumber'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="promotionForm.controls['ContactNumber'].errors.pattern">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.ContactNumber.hasError('maxlength') && promotionForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.ContactNumber.hasError('minlength') && promotionForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                            </div>
                                            <label>Customer Contact Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                      <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" formControlName="dob"
                                                placeholder="DOB"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['dob'].errors }" (change)="checkDob()" />
                                            <div *ngIf="submitted && promotionForm.controls['dob'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['dob'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>DOB<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                       <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control panupper" formControlName="panNo"
                                                placeholder="Customer PAN Card Number"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['panNo'].errors}" onKeyPress="if(this.value.length==10) return false;" />

                                            <div *ngIf="submitted && promotionForm.controls['panNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['panNo'].errors.required">
                                                   PAN Card Number is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['panNo'].errors.pattern">
                                                    Invalid PAN Card Number </div>
                                               
                                            </div>
                                              <label>Customer PAN Card Number<span style="color: crimson;">*</span></label>

                                        </div>
                                    </div>

                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <textarea type="text" class="form-control" formControlName="address"
                                                placeholder="Full Address"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['address'].errors }"></textarea>
                                            <div *ngIf="submitted && promotionForm.controls['address'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['address'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>Full Address<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                        <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Pincode"
                                                placeholder="Pincode"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Pincode'].errors }"
                                                id="Pincode" />
                                            <div *ngIf="submitted && promotionForm.controls['Pincode'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['Pincode'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="promotionForm.controls['Pincode'].errors.pattern">
                                                    Invalid Pincode </div>
                                                <div
                                                    *ngIf="promotionForm.controls.Pincode.hasError('maxlength') && promotionForm.controls.Pincode.touched">
                                                    Invalid Pincode </div>
                                                <div
                                                    *ngIf="promotionForm.controls.Pincode.hasError('minlength') && promotionForm.controls.Pincode.touched">
                                                    Invalid Pincode </div>
                                            </div>
                                            <label>Pincode<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                              <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Email"
                                                placeholder="Customer Email ID"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submitted && promotionForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                               
                                                <div *ngIf="promotionForm.controls['Email'].errors.pattern">
                                                    Invalid Email </div>
                                              
                                            </div>
                                            <label>Customer Email ID</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" (change)="validDate()" class="form-control" formControlName="vehicleregDate"
                                                placeholder="Vehicle Booking Date"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicleregDate'].errors }" />
                                            <div *ngIf="submitted && promotionForm.controls['vehicleregDate'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleregDate'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>Vehicle Booking Date<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                
                                      
                                     
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="rto_no"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['rto_no'].errors}">
                                                <option value="" selected>Select Proposed RTO No. </option>
                                                <option value="MH 01">MH 01</option>
                                                <option value="MH 02">MH 02</option>
                                                <option value="MH 03">MH 03</option>
                                                <option value="MH 04">MH 04</option>
                                                <option value="MH 05">MH 05</option>
                                                <option value="MH 06">MH 06</option>
                                                <option value="MH 43">MH 43</option>
                                                <option value="MH 46">MH 46</option>
                                                <option value="MH 47">MH 47</option>

                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['rto_no'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['rto_no'].errors">Proposed RTO No.
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Proposed RTO No.<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="oem"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['oem'].errors}" (change)="vmodelData()">
                                                <option value="" selected>Select OEM</option>
                                                 <option value="TATA Motors">TATA Motors</option>
                                                  <option value="Eicher (VECV)">Eicher (VECV)</option>
                                                <option value="Ashok Leyland">Ashok Leyland</option>
                                              </select>
                                            <div *ngIf="submitted && promotionForm.controls['oem'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['oem'].errors">OEM
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>OEM<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="vehicle_category"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['vehicle_category'].errors}" (change)="vmodelcatData()">
                                                <option value="" selected>Select Vehicle Category</option>
                                                  <option *ngFor="let item of vehiclecategoryArr" value="{{item.id}}">{{item.name}}</option>
                                               <!--  <option value="Car">Car</option> -->
                                               <!--  <option value="Bus">Bus</option> -->
                                               <!--  <option value="SCV (GVW upto 3.5 T)">SCV (GVW upto 3.5 T)</option> -->
                                                <!-- <option value="LCV (>3.5 T, <10 T)">LCV (>3.5 T, <10 T)</option>
                                                <option value="ICV ( ≥10T, <15T)">ICV ( ≥10T, <15T)</option>
                                                <option value="HCV (≥15 T)">HCV (≥15 T)</option> -->
                                             </select>
                                            <div *ngIf="submitted && promotionForm.controls['vehicle_category'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicle_category'].errors">Vehicle Category
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Vehicle Category<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="vehicleModel"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['vehicleModel'].errors}">
                                                <option value="" selected>Select Vehicle Model</option>
                                                <option *ngFor="let item of VehicleModelArr" value="{{item.id}}">{{item.name}}</option>
                                               
                                                

                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['vehicleModel'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleModel'].errors">Vehicle Model
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Vehicle Model<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="dealerName"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['dealerName'].errors}">
                                                <option value="" selected>Select Dealer Name</option>
                                                <option *ngFor="let item of dealerNameArr" value="{{item.id}}">{{item.name}}</option>
                                               
                                                

                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['dealerName'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['dealerName'].errors">Dealer Name
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Dealer Name<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" readonly class="form-control" formControlName="vehicleAmount"
                                                placeholder="Amount"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicleAmount'].errors }"
                                                id="vehicleAmount" />
                                            <div *ngIf="submitted && promotionForm.controls['vehicleAmount'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleAmount'].errors.required">
                                                    Required Field
                                                </div>
                                              
                                             
                                               
                                            </div>
                                            <label>Amount<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="vehicle_chassis_no"
                                                placeholder="Vehicle Chassis No"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicle_chassis_no'].errors }" onKeyPress="if(this.value.length==17) return false;"/>
                                            <div *ngIf="submitted && promotionForm.controls['vehicle_chassis_no'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicle_chassis_no'].errors.required">
                                                    Required Field
                                                </div>
                                                 <div
                                                    *ngIf="promotionForm.controls.vehicle_chassis_no.hasError('maxlength') && promotionForm.controls.vehicle_chassis_no.touched">
                                                    Invalid Chassis No </div>
                                                <div
                                                    *ngIf="promotionForm.controls.vehicle_chassis_no.hasError('minlength') && promotionForm.controls.vehicle_chassis_no.touched">
                                                    Invalid Chassis No </div>
                                            </div>
                                            <label>Vehicle Chassis No<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     
                                    
                                    
                                         <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="gift_no1"
                                                placeholder="MGL Fuel Card No"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_no1'].errors}" onKeyPress="if(this.value.length==16) return false;" />
                                            <div *ngIf="submitted && promotionForm.controls['gift_no1'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_no1'].errors.required">
                                                    MGL Fuel Card No is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['gift_no1'].errors.pattern">
                                                    Invalid MGL Fuel Card No </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no1.hasError('maxlength') && promotionForm.controls.gift_no1.touched">
                                                    Allow Only 16 Digits </div>
                                                <div
                                                    *ngIf="promotionForm.controls.gift_no1.hasError('minlength') && promotionForm.controls.gift_no1.touched">
                                                    Allow Only 16 Digits </div>
                                            </div>

                                            <label>MGL Fuel Card No<span
                                            style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <label class="font-size11" for="Doc5_IDproof">Upload MGL Fuel Card<span style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'gift_card1')" class="from-control"
                                                type="file" formControlName="gift_card1"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['gift_card1'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['gift_card1'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['gift_card1'].errors">
                                                    Image is Required</div>
                                            </div>
                                        </div>
                                         <div>
                                            <a href="https://www.mahanagargas.com:3000/vehicle_promotion_2_0_oem/{{previouspageData.gift_card1}}"
                                                target="_blank" *ngIf="previouspageData.gift_card1!=''">
                                                <h6> <span class="badge bg-secondary">View Old Doc</span></h6>
                                            </a>
                                        </div>

                                        
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <label class="font-size11">Booking Receipt<span
                                            style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input accept=".pdf" (change)="filechange($event,'booking receipt')" class="from-control"
                                                type="file" formControlName="booking_receipt"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['booking_receipt'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['booking_receipt'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['booking_receipt'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>
                                         <div>
                                            <a href="https://www.mahanagargas.com:3000/vehicle_promotion_2_0_oem/{{previouspageData.booking_receipt}}"
                                                target="_blank" *ngIf="previouspageData.booking_receipt!=''">
                                                <h6> <span class="badge bg-secondary">View Old Doc</span></h6>
                                            </a>
                                        </div>
                                     
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <label class="font-size11">Upload Customer PAN Card<span
                                            style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'pancard')" class="from-control"
                                                type="file" formControlName="pan_card_img"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['pan_card_img'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['pan_card_img'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['pan_card_img'].errors">PAN Card
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>
                                        <div>
                                            <a href="https://www.mahanagargas.com:3000/vehicle_promotion_2_0_oem/{{previouspageData.pan_card_img}}"
                                                target="_blank" *ngIf="previouspageData.pan_card_img!=''">
                                                <h6> <span class="badge bg-secondary">View Old Doc</span></h6>
                                            </a>
                                        </div>
                                     
                                    </div>
                                      <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="mt-10">
                                                    <div class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Captcha'].errors }"
                                                        id="Captcha" />

                                                    <div *ngIf="submitted && promotionForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="promotionForm.controls['Captcha'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="onSubmit()" class="greenbtn2">Submit <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="text-right md-text-left">
                                            <button (click)="reset()" class="bluebtn2">Reset<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div>
                                    </div>

                                </div>
                                 <div class="text-right mt-30">
                                    <p class="mb-0"><b><i><span class="text-danger">*</span>MGL reserves the right to
                                                ask for any other document if required </i></b></p>
                                    <p class="mb-0"><b><i>Please note all fields marked <span
                                                    class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>
                                <div *ngIf="alertType == 'vehiclepromotion'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                            </form>
                        </div>
                       </div>
                  </div>
            
            </div>
        </div>
    </div>
</div>


<div class="modal fade directors-sd {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                    class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                        <div class="img-div-class">
                            <img *ngIf="gift_card1 !=undefined" src="{{gift_card111 }}"
                                class="img-class">
                                  <img *ngIf="gift_card1 ==undefined" src="https://www.mahanagargas.com:3000/vehicle_promotion_2_0_oem/{{giftCardliveimg }}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Fuel Card</div>
                                <div>{{gift_no11}}</div>
                            </div>
                        </div>
                    </div>
                  <!--   <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                        <div class="img-div-class">
                            <img src="{{booking_receipt111 }}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Booking Receipt</div>
                            </div>
                        </div>
                    </div> -->
                      <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                        <div class="img-div-class">
                            <img *ngIf="pancardimg !=undefined" src="{{pancardimg111 }}"
                                class="img-class">
                                <img *ngIf="pancardimg ==undefined" src="https://www.mahanagargas.com:3000/vehicle_promotion_2_0_oem/{{panCardliveimg }}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>PAN Card</div>
                               <!--  <div>{{gift_no11}}</div> -->
                            </div>
                        </div>
                    </div>
              </div>
            </div>
            <div class="modal-footer">
                <button  class="greenbtn2" (click)="submitPop()">Confirm</button>
                <button class="bluebtn2" (click)="closeModel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>