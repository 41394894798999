<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/awards-banner.jpg">
  <div class="banner-heading">
    <h1>CNG Locator</h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a>CNG</a></li>
        <li class=""><a>Customer Zone</a></li>
        <li class="active" aria-current="page"><a href="#">CNG Locator</a></li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
        <app-recent-post></app-recent-post>

        <app-tips></app-tips>

        <div class="mt-30">

          <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
            mahanagargas</a>


        </div>
      </div>

      <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
        <div class="innerpage-right-content awards-section">
          <div class="light-blue-bg mt-20">
          <h6>Select location from the dropdown below to view your nearest CNG Filling Station</h6>
          <div class="">
            <form [formGroup]="searchForm" (submit)="getcng1()">

              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="select-area mb-2">
                    <select name="" formControlName="Location" (change)="Change($event)">
                      <option value="">Please Select City</option>
                      <option *ngFor="let AreaItem of locationdata " value={{AreaItem}}>{{AreaItem}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="select-area">
                    <select name="" formControlName="AreaName">
                      <option value="">Please Select Area</option>
                      <option *ngFor="let AreaItem of areadata " value={{AreaItem.AreaName}}>{{AreaItem.AreaName}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <input type="submit" name="submit" [className]="'greenbtn3 mt-20'" value="Search" />
                </div>
              </div>
              <div *ngIf="alertType == 'cnlocater'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
            </form>
          </div>
          </div>
         <!--  <div class="share-location-section mb-30 mt-30" *ngIf="name">
            <div class="location-details">
              <h6>Share Location</h6>
              <ul>
                <li><i class="demo-icon uil-location-point"></i> {{name}}</li>
                <li><i class="demo-icon uil-phone-volume"></i> {{contact}}</li>
              </ul>
            </div>
            <a href="#" class="greenbtn2 d-block">Get Direction <i class="demo-icon uil-location-arrow"></i></a>
          </div> -->
          <div class="mt-30 mb-30">
            <h6 id="cng-filling-stations-map">CNG Filling Station Map</h6>
            <div class="cng-filling-stations-map">
              <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15092.155832162045!2d72.8137168!3d18.9738868!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce705cf727a5%3A0x33fa62d6d0dc37c7!2sMGL%20CNG%20Station!5e0!3m2!1sen!2sin!4v1676268703136!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>     -->
              <!-- <iframe [src]="safeSrc" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
              </iframe> -->
            <!--   <google-map height="450px" width="100%" [zoom]="zoom" [center]="center" [options]="options">
                <map-marker
                  *ngFor="let marker of markers"
                  [position]="marker.position"
                 
                  [title]="marker.title"
                  [options]="marker.options"
                >
                </map-marker>
              </google-map> -->
               
                <div  id="map" class="map"></div>

            </div>
          </div>
        </div>
        <div class="light-blue-bg station-list" *ngIf="outletdata1.length!=0">
          <div class="table-responsive cng-locators">
            <table class="">
              <thead>
                <tr>
                  <th width="60px">Sr.no</th>
                  <th>Name Of The Outlet</th>
                  <th>Contact Number</th>
                  <!-- <th>Operational Start Time</th>
            <th>Operational End Time</th> -->
                  <th>City</th>
                  <th>Area</th>
                  <!-- <th>Map</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let OutletItem of outletdata1 let i = index;">
                  <td>{{i+1}}</td>
                  <td>{{OutletItem.OutletName}}</td>
                  <td>{{OutletItem.ContactNumber}}</td>
                  <!-- <td>{{OutletItem.OperationalStartTime}}</td>
                 <td>{{OutletItem.OperationalEndTime }}</td> -->
                  <td>{{OutletItem.Location}}</td>
                  <td>{{OutletItem.AreaName}}</td>
                    <!-- <td><a href='https://www.google.com/maps?saddr=My+Location&daddr="+{{OutletItem.Latitude}}+","+{{OutletItem.Latitude}}+"' target='_blank'><i
                        class="demo-icon uil-location-point"></i></a></td> -->
                  <td><a (click)="showMap(OutletItem)" class="dance_hover"><i
                        class="demo-icon uil-location-point"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>