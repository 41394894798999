<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Refund Policy

        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a >Policies & Procedures</a></li>
                <li class="active" aria-current="page"><a>Refund Policy
                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="innerpage-right-content awards-section">
                    <div class="con">
                        <h6 class="ecs_text">How to Get Refund?</h6>
                        <p>Any customer, who has started using Domestic Piped Natural Gas (PNG) on or after 1st April
                            2008, is eligible for refund of interest free Security Deposit under the following
                            conditions: On customer’s request: </p>
                        <h6>Security Deposit shall be refunded in full, irrespective of the status of connection,
                            subject to receipt of the following:</h6>
                            <div class="blue-tick-list">
                        <ul >
                            <li>Refund request letter from the customer.</li>
                            <li>PNG equipments from the premises (flat / kitchen) of the customer, if applicable.</li>
                            <li>Payment towards disconnection charges as per prevailing rate and all other dues.</li>
                        </ul>
                    </div>
                        <h6 class="ecs_text">Other reasons: </h6>
                        <h6>Security Deposit shall be refunded in full under the following conditions:</h6>
                        <div class="blue-tick-list">
                        <ul >
                            <li>In case premise of the customer is found technically not feasible for supplying PNG.
                            </li>
                            <li>In case of non-availability of necessary statutory permissions.</li>
                            <li>Any other reasons as and when specified by Statutory Authorities in its notifications.
                            </li>
                        </ul>
                    </div>
                        <p>For more information contact our Customer Care Executive on&nbsp;<strong>68674500 /
                                61564500</strong>&nbsp;from 8:00 hrs. to 22:00 hrs. daily. Kindly quote your Business
                            Partner (BP) Number as mentioned on your gas bill.</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->





</div>