<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Retrofitters Bill Processing
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>CNG</a></li>
                <li class="active" aria-current="page"><a>Retrofitters Bill Processing</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">

                        <div class="customer-login-page log-meter-reading">

                            <form [formGroup]="promotionForm">
                                <div class="row">
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="Name"
                                                placeholder="Customer Name"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['Name'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Customer Name<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="ContactNumber"
                                                placeholder="Customer Contact Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['ContactNumber'].errors }"
                                                id="ContactNumber" />
                                            <div *ngIf="submitted && promotionForm.controls['ContactNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['ContactNumber'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="promotionForm.controls['ContactNumber'].errors.pattern">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.ContactNumber.hasError('maxlength') && promotionForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.ContactNumber.hasError('minlength') && promotionForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                            </div>
                                            <label>Customer Contact Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                   <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="Email"
                                                placeholder="Customer Email"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submitted && promotionForm.controls['Email'].errors"
                                                class="invalid-feedback">

                                                <div *ngIf="promotionForm.controls['Email'].errors.pattern">
                                                    Invalid Email ID </div>
                                            </div>
                                             <label>Customer Email ID<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <!--   <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" formControlName="dob"
                                                placeholder="Customer DOB"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['dob'].errors }" (change)="checkDob()" />
                                            <div *ngIf="submitted && promotionForm.controls['dob'].errors"(change)="checkDob()"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['dob'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>Customer DOB<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->

                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control panupper" formControlName="Pan"
                                                placeholder="Customer PAN Card Number"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['Pan'].errors}" />

                                            <div *ngIf="submitted && promotionForm.controls['Pan'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['Pan'].errors.required">
                                                    PAN Card Number is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['Pan'].errors.pattern">
                                                    Invalid PAN Card Number </div>

                                            </div>

                                            <label>Customer PAN Card Number<span
                                                    style="color: crimson;">*</span></label>

                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input  type="text" class="form-control" formControlName="invoice_number"
                                                placeholder="Invoice No"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['invoice_number'].errors }"
                                                id="invoice_number" />
                                            <div *ngIf="submitted && promotionForm.controls['invoice_number'].errors"
                                                class="invalid-feedback">

                                                <div *ngIf="promotionForm.controls['invoice_number'].errors">Invoice No is Required</div>
                                            </div>
                                             <label>Invoice No<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" formControlName="invoice_date"
                                                placeholder="Invoice Date"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['invoice_date'].errors }"
                                                id="invoice_date" />
                                            <div *ngIf="submitted && promotionForm.controls['invoice_date'].errors"
                                                class="invalid-feedback">
                                                  <div *ngIf="promotionForm.controls['invoice_date'].errors">Invoice Date is Required</div>
                                            </div>
                                             <label>Invoice Date<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input  type="text" class="form-control" formControlName="demand_note_number"
                                                placeholder="Demand Note Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['demand_note_number'].errors }"
                                                id="demand_note_number" />
                                            <div *ngIf="submitted && promotionForm.controls['demand_note_number'].errors"
                                                class="invalid-feedback">

                                               <div *ngIf="promotionForm.controls['demand_note_number'].errors">Demand Note Number is Required</div>
                                            </div>
                                             <label>Demand Note Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="amount_of_scheme"
                                                placeholder="Amount of Scheme"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['amount_of_scheme'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['amount_of_scheme'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['amount_of_scheme'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Amount of Scheme<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                      <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                         <input readonly type="date" class="form-control" formControlName="booking_receipt_date"
                                                placeholder="Booking Receipt Date"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['booking_receipt_date'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['booking_receipt_date'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['booking_receipt_date'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Booking Receipt Date<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                         <input  type="date" class="form-control" formControlName="cng_conversion_date"
                                                placeholder="CNG conversion Date"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cng_conversion_date'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['cng_conversion_date'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['cng_conversion_date'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>CNG conversion Date<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="vehicleTpye"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['vehicleTpye'].errors}" (change)="checkVtype()">
                                                <option value="" selected>Select Type of vehicle </option>
                                                <option value="Car">Car</option>
                                                <option value="SCV (Upto 3.5 T)">SCV (Upto 3.5 T)</option>
                                                <option value="LCV (>3.5-10 T)">LCV (>3.5-10 T)</option>
                                                <option value="Buses (9- 12 meters)">Buses (9- 12 meters)</option>
                                              <!--   <option value="ICV/HCV (> 10 T)">ICV/HCV (> 10 T)</option> -->
                                                 <option value="ICV ( ≥10, < 15 tons)">ICV ( ≥10, < 15 tons)</option>
                                                  <option value="HCV( ≥15)">HCV( ≥15)</option>
                                                <!--  <option value="Car">Car</option>
                                                <option value="LCV">LCV</option>
                                                <option value="Pvt Buses">Pvt Buses</option>
                                                <option value="ICV/HCV">ICV/HCV</option> -->

                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['vehicleTpye'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleTpye'].errors">Type of
                                                    vehicle
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Type of vehicle<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="vehicleNo"
                                                placeholder="Vehicle Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicleNo'].errors }"
                                                id="vehicleNo" />
                                            <div *ngIf="submitted && promotionForm.controls['vehicleNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleNo'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="promotionForm.controls['vehicleNo'].errors.pattern">
                                                    Invalid Vehicle Number </div>
                                            </div>
                                            <label>Vehicle Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="kit_Supplier"
                                                placeholder="Vehicle Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['kit_Supplier'].errors }"
                                                id="kit_Supplier" />
                                            <div *ngIf="submitted && promotionForm.controls['kit_Supplier'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['kit_Supplier'].errors.required">
                                                    Required Field
                                                </div>
                                               
                                            </div> 
                                            <label>Name of Kit Supplier<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="kit_Supplier"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['kit_Supplier'].errors}">
                                                <option value="" selected>Select Name of Kit Supplier </option>
                                                <option value="Green Globe Fuel Solutions Pvt Ltd">Green Globe Fuel
                                                    Solutions Pvt Ltd</option>
                                                <option value="Eco Fuel Systems (I) Pvt Ltd">Eco Fuel Systems (I) Pvt
                                                    Ltd</option>
                                                <option value="Amol Prala Clean Energy Pvt. Ltd">Amol Prala Clean Energy
                                                    Pvt. Ltd</option>
                                                <option value="Shigan Quantum Technologies Limited">Shigan Telematics
                                                    Private Limited</option>
                                               
                                                <option value="Motozen Fuel Systems Pvt. Ltd.">Motozen Fuel Systems Pvt.
                                                    Ltd.</option>

                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['kit_Supplier'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['kit_Supplier'].errors">Name of Kit
                                                    Supplier
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Name of Kit Supplier<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="ECMSrNo"
                                                placeholder="ECM Sr.No"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['ECMSrNo'].errors }"
                                                id="ECMSrNo" />
                                            <div *ngIf="submitted && promotionForm.controls['ECMSrNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['ECMSrNo'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>ECM Sr.No<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control"
                                                formControlName="Pressure_regulator_SrNo"
                                                placeholder="Pressure regulator Sr.No"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Pressure_regulator_SrNo'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['Pressure_regulator_SrNo'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="promotionForm.controls['Pressure_regulator_SrNo'].errors.required">
                                                    Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Pressure regulator Sr.No<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="NoofCNGCylinders"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['NoofCNGCylinders'].errors}"
                                                (change)="selectedCylinders()">
                                                <option value="" selected>Select No. of CNG Cylinders </option>
                                                <option value="1 Nos">1 Nos</option>
                                                <option value="2 Nos">2 Nos</option>
                                                <option value="3 Nos">3 Nos</option>
                                                <option value="4 Nos">4 Nos</option>
                                                <option value="5 Nos">5 Nos</option>
                                                <option value="6 Nos">6 Nos</option>
                                                <option value="7 Nos">7 Nos</option>
                                                <option value="8 Nos">8 Nos</option>
                                                <option value="9 Nos">9 Nos</option>
                                                <option value="10 Nos">10 Nos</option>


                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['NoofCNGCylinders'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['NoofCNGCylinders'].errors"> No. of
                                                    CNG Cylinders
                                                    is
                                                    Required</div>
                                            </div>
                                            <label> No. of CNG Cylinders
                                                <!-- <span style="color: crimson;">*</span> -->
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag1==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make1"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make1'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make1'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make1'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno1"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno1'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno1'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno1'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing1"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing1'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing1'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing1'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag2==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make2"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make2'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make2'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make2'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno2"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno2'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno2'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno2'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing2"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing2'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing2'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing2'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag3==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make3"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make3'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make3'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make3'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno3"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno3'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno3'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno3'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing3"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing3'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing3'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing3'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag4==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make4"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make4'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make4'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make4'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno4"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno4'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno4'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno4'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing4"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing4'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing4'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing4'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag5==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make5"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make5'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make5'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make5'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno5"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno5'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno5'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno5'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing5"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing5'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing5'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing5'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag6==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make6"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make6'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make6'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make6'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno6"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno6'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno6'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno6'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing6"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing6'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing6'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing6'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag7==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make7"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make7'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make7'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make7'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno7"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno7'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno7'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno7'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing7"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing7'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing7'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing7'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag8==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make8"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make8'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make8'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make8'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno8"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno8'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno8'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno8'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing8"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing8'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing8'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing8'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="NoofCNGCylindersFlag9==true">
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_make9"
                                                    placeholder="Cylinder Make"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_make9'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_make9'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_make9'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Make<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="cylinder_srno9"
                                                    placeholder="Cylinder Sr. No"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['cylinder_srno9'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['cylinder_srno9'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['cylinder_srno9'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Cylinder Sr. No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="year_of_manufacturing9"
                                                    placeholder="Year of Manufacturing"
                                                    [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['year_of_manufacturing9'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && promotionForm.controls['year_of_manufacturing9'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="promotionForm.controls['year_of_manufacturing9'].errors.required">
                                                        Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label>Year of Manufacturing<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>

                                   <!--  <div class="col-sm-6 form-group">
                                        <label class="font-size11">Upload Customer PAN Card<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'pancard')"
                                                class="from-control" type="file" formControlName="pan_card_img"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['pan_card_img'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['pan_card_img'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['pan_card_img'].errors">PAN Card
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>

                                    </div> -->
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">Invoice Copy<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'Invoice')" class="from-control"
                                                type="file" formControlName="invoice_copy"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['invoice_copy'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['invoice_copy'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['invoice_copy'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                             <div>
                                            <a href="https://www.mahanagargas.com:3000/retrofitters_img/{{previouspageData.invoice_copy}}"
                                                target="_blank" *ngIf="previouspageData.invoice_copy!=''">
                                                <h6> <span class="badge bg-secondary">View Old Doc</span></h6>
                                            </a>
                                        </div>
                                        </div>

                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">CNG kit retrofitment certificate<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'Kitimg')" class="from-control"
                                                type="file" formControlName="cng_certificate"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['cng_certificate'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['cng_certificate'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['cng_certificate'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                             <div>
                                            <a href="https://www.mahanagargas.com:3000/retrofitters_img/{{previouspageData.cng_certificate}}"
                                                target="_blank" *ngIf="previouspageData.cng_certificate!=''">
                                                <h6> <span class="badge bg-secondary">View Old Doc</span></h6>
                                            </a>
                                        </div>
                                        </div>

                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">RTO E Fee Receipt<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'rcbook')" class="from-control"
                                                type="file" formControlName="rc_book"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['rc_book'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['rc_book'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['rc_book'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                            <div>
                                            <a href="https://www.mahanagargas.com:3000/retrofitters_img/{{previouspageData.rc_book}}"
                                                target="_blank" *ngIf="previouspageData.rc_book!=''">
                                                <h6> <span class="badge bg-secondary">View Old Doc</span></h6>
                                            </a>
                                        </div>
                                        </div>

                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">Installation Certificate<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'installationcertificate')"
                                                class="from-control" type="file"
                                                formControlName="installation_certificate"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['installation_certificate'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['installation_certificate'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['installation_certificate'].errors">
                                                    Document
                                                    is
                                                    Required</div>
                                            </div>
                                             <div>
                                            <a href="https://www.mahanagargas.com:3000/retrofitters_img/{{previouspageData.installation_certificate}}"
                                                target="_blank" *ngIf="previouspageData.installation_certificate!=''">
                                                <h6> <span class="badge bg-secondary">View Old Doc</span></h6>
                                            </a>
                                        </div>
                                        </div>

                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">Fitment Receipt<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'fitmentreceipt')"
                                                class="from-control" type="file"
                                                formControlName="fitment_receipt"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['fitment_receipt'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['fitment_receipt'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['fitment_receipt'].errors">
                                                    Document
                                                    is
                                                    Required</div>
                                            </div>
                                            <div>
                                            <a href="https://www.mahanagargas.com:3000/retrofitters_img/{{previouspageData.fitment_receipt}}"
                                                target="_blank" *ngIf="previouspageData.fitment_receipt!=''">
                                                <h6> <span class="badge bg-secondary">View Old Doc</span></h6>
                                            </a>
                                        </div>
                                        </div>

                                    </div>
                                     <div class="col-sm-6 form-group" *ngIf="commercialrcbookimgFlag==true">
                                        <label class="font-size11">Commercial RC Book<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'commercialrcbook')" class="from-control"
                                                type="file" formControlName="commercial_rc_book"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['commercial_rc_book'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['commercial_rc_book'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['commercial_rc_book'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                            <div>
                                            <a href="https://www.mahanagargas.com:3000/retrofitters_img/{{previouspageData.commercial_rc_book}}"
                                                target="_blank" *ngIf="previouspageData.commercial_rc_book!=''">
                                                <h6> <span class="badge bg-secondary">View Old Doc</span></h6>
                                            </a>
                                        </div>
                                        </div>

                                    </div>
                                    <!-- <div class="col-sm-6 form-group">
                                        <label class="font-size11">Latest Vehicle Insurance copy<span
                                            style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'insurance')" class="from-control"
                                                type="file" formControlName="l_vehicle_insurance_copy"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['l_vehicle_insurance_copy'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['l_vehicle_insurance_copy'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['l_vehicle_insurance_copy'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>
                                     
                                    </div> -->





                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="box100per">
                                            <b id="spTerms"></b>
                                            <div class="mb-20 mt-20 ">
                                                <div class="d-inline"><strong>Terms &amp; Conditions</strong><strong>*</strong>
                                                    : </div>
                                                <div class="d-inline-flex">
                                                    <div class="form-check">
                                                        <input
                                                            [ngClass]="{'is-invalid':submitted && promotionForm.controls['TNC'].errors}"
                                                            formControlName="TNC" id="ContentPlaceHolder1_chkTerm"
                                                            type="checkbox" name="ctl00$ContentPlaceHolder1$chkTerm"
                                                            class="form-check-input">
        
        
                                                        <label class="form-check-label"> <a
                                                                href="../../../assets/images/pdf/Savings Ki Shubh Shuruaat-T&C.pdf"
                                                                target="_blank">I accept all Terms &amp; Conditions</a></label>
                                                    </div>
                                                </div>
                                                <div *ngIf="submitted && promotionForm.controls['TNC'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="promotionForm.controls['TNC'].errors">
                                                        Required Field
                                                    </div>
                                                </div>
                                            </div>
                                             <b id="spTerms"></b>
        
                                        </div>
                                        
                                    </div>  -->
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="mt-10">
                                                    <div class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Captcha'].errors }"
                                                        id="Captcha" />

                                                    <div *ngIf="submitted && promotionForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="promotionForm.controls['Captcha'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="submitForm()" class="greenbtn2"><span *ngIf="loading"
                                                                        class="spinner-border spinner-border-sm mr-1"></span>Submit <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                       <!--  <div class="text-right md-text-left">
                                            <button (click)="reset()" class="bluebtn2">Reset<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div> -->
                                    </div>

                                </div>
                                <div class="text-right mt-30">
                                    <p class="mb-0"><b><i><span class="text-danger">*</span>MGL reserves the right to
                                                ask for any other document if required </i></b></p>
                                    <p class="mb-0"><b><i>Please note all fields marked <span
                                                    class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>
                                <div *ngIf="alertType == 'vehiclepromotion'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<!-- <div class="modal fade directors-sd {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                    class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30">
                        <div class="img-div-class">
                            <img src="{{gift_card111 }}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Fuel Card 1</div>
                                <div>{{gift_no11}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30" *ngIf="gift_no22!=''">
                        <div class="img-div-class">
                            <img src="{{gift_card222}}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Fuel Card 2</div>
                                <div>{{gift_no22}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30" *ngIf="gift_no33!=''">
                        <div class="img-div-class">
                            <img src="{{gift_card333}}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Fuel Card 3</div>
                                <div>{{gift_no33}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30" *ngIf="gift_no44!=''">
                        <div class="img-div-class">
                            <img src="{{gift_card444}}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Fuel Card 4</div>
                                <div>{{gift_no44}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-30" *ngIf="gift_no55!=''">
                        <div class="img-div-class">
                            <img src="{{gift_card555}}"
                                class="img-class">
                            <div class="lg-sub-html">
                                <div>Fuel Card 5</div>
                                <div>{{gift_no55}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button  class="greenbtn2" (click)="submitPop()">Confirm</button>
                <button class="bluebtn2" (click)="closeModel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div> -->