<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
      <h1>Archives Tender</h1>
      <div class="breadcrumbs">
        <ul class="">
          <li class=""><a [routerLink]="['/']">Home</a></li>
                  <li class=""><a >MGL Corporate</a></li>
                  <li class=""><a >Contracts & Procurements</a></li>
                  <li class=""><a >Tenders</a></li>
                  <li class="active" aria-current="page"><a >Archives Tenders</a></li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Services Section Start -->
  <app-service-section></app-service-section>
  <!-- Services Section End -->
  
  <!-- Inner Page Content Section Start -->
  <div class="innerpage-content-section">
    <div class="container custom">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
          <app-recent-post></app-recent-post>
  
          <app-tips></app-tips>
  
          <div class="mt-30">
            <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
              mahanagargas</a>
          </div>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
          <div class="innerpage-right-content">
            <div class="row y-middle justify-content-center">
              <div class="col-lg-3 col-md-3 col-sm-12">
                <label><b>Active Tenders</b> </label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="select-area">
                  <select>
                    <option selected="selected" value="">-Select Category-</option>
                    <option value="">Active</option>
                    <option value="">CNG Mobile Refuelling Unit</option>
                    <option value="">Contracts</option>
                    <option value="">Domestic Open Tender ( Two bid system)</option>
                    <option value="">Open Domestic Tender</option>
                    <option value="">Open Tender</option>
                    <option value="">Procurement</option>
                    <option value="">Services</option>
                    <option value="">Services / Contracts</option>
                    <option value="">Supply of Material</option>
                    <option value="">Two bid system</option>
                  </select>
                </div>
              </div>
            </div>
  
  
            <div class="light-blue-bg mt-30 customer-care-overview-icon">
              <div class="white-box mt-30 mb-30" *ngFor="let i of tenderDate">
                <div class="tender-setion">
                  <div class="tender-head">
                    <div class="tendername">
                      <p>Tender Name</p>
                      <h6>{{i.TenderName}}</h6>
                    </div>
                  </div>
  
                  <div class="tender-body">
                    <div class="category-sect">
                      <p>Category</p>
                      <h6>{{i.CategoryName}}</h6>
                    </div>
                    <div class="category-sect">
                      <p>Tender Reference No</p>
                      <h6>{{i.ReferenceNo}}</h6>
                    </div>
                    <div class="category-sect">
                      <p>Name of Department Floating Tender</p>
                      <h6>{{i.DeptFloatingTender}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main content End -->
  </div>  
  