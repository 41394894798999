<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>FAQS</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a  [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a  >Dealer Zone</a></li>
                <li class="active" aria-current="page"><a>FAQS</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                
                <app-tips></app-tips>

                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
               

                <div class="light-blue-bg mt-20">
                    <div class="rs-faq billing-faq">
                        <div class="faq-content">
                            <div id="accordion" class="accordion">
                                <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse1" data-toggle="collapse"
                                            aria-expanded="true">Can I become a CNG Dealer of Mahanagar Gas Limited (MGL)?</a>
                                    </div>
                                    <div id="collapse1" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>Any individual or firm (having at least one Indian National as stakeholder) who either owns a parcel of land (attached to main road) or who can take land on long term lease and willing to offer land to MGL on lease/ sublease basis for setting up CNG station is eligible.</span>
                                        </div>
                                    </div>
                                </div>
                                 <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse2" data-toggle="collapse"
                                            aria-expanded="true">What is the area of land required to setup an MGL CNG Station?</a>
                                    </div>
                                    <div id="collapse2" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Stretch</th>
                                                                <th>Minimum Area (Acceptable)</th>
                                                                <th>Desirable Area (Larger plot areas will be given preference)</th>
                                                               

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Within Municipal Limits (BMC, NMMC, PMC, TMC, KDMC, Bhiwandi, Mira Bhayander, etc.)</td>
                                                                <td>400 Sq. M (with frontage of 20 m)</td>
                                                                <td>> 700 Sq. M (with frontage of >25m)</td>
                                                               
                                                            </tr>
                                                            <tr>
                                                                <td>National/ State Highway (Outside Municipal Limits)</td>
                                                                <td>1,225 Sq. M (with frontage of 35m)</td>
                                                               <td>> 1600 Sq. M (with frontage of > 40m)</td>
                                                            </tr>
                                                           
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse3" data-toggle="collapse"
                                            aria-expanded="true">Where does MGL require plots?</a>
                                    </div>
                                    <div id="collapse3" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>MGL requires plots at following markets.<br>
                                            	1. On major roads of Mumbai and Thane Districts <br>
                                            2. Raigad District - As per list of required stretches published on MGL website. (CNG -&gt; Dealer Zone -&gt; <a  [routerLink]="['/cng/dealer-zone/apply-for-cng-oulet']">Apply for New CNG Retail Outlets)</a></span>
                                        </div>
                                    </div>
                                </div>
                                 <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse4" data-toggle="collapse"
                                            aria-expanded="true">Am I allowed to have dealership of multiple CNG Stations?</a>
                                    </div>
                                    <div id="collapse4" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>There is no restriction on the number of CNG Stations. Any individual or firm can put up.</span>
                                        </div>
                                    </div>
                                </div>
                                 <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse5" data-toggle="collapse"
                                            aria-expanded="true">What is the lease period for which land needs to be leased/ sub-leased to MGL?</a>
                                    </div>
                                    <div id="collapse5" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>The tenure of lease agreement is between 17 – 29 years. Preference is given to applicants offering land on longer lease period.</span>
                                        </div>
                                    </div>
                                </div>
                                <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse11" data-toggle="collapse"
                                            aria-expanded="true">What is the process for applying for an MGL CNG dealership?</a>
                                    </div>
                                    <div id="collapse11" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>Once land is identified, submit your Expression of Interest (EOI) online on MGL website (Click to apply) with necessary documents. This will be followed by a site inspection and verification process.Subsequently, if the site is found preliminary feasible, submit the application form along with architect layout, land ownership documents, personal details including net worth certificate,application fees etc. to initiate further selection process.</span>
                                        </div>
                                    </div>
                                </div>
                                  <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse6" data-toggle="collapse"
                                            aria-expanded="true">What is the selection process?</a>
                                    </div>
                                    <div id="collapse6" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>The application is shortlisted based upon the Site selection criteria and Dealer selection criteria as per MGL CNG Retail Outlet Policy. Letter of Intent (LOI) shall be issued to successful applicants having an initial validity of six (6) months.</span>
                                        </div>
                                    </div>
                                </div>
                                <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse7" data-toggle="collapse"
                                            aria-expanded="true">What kind of investment is required for setting up CNG station?</a>
                                    </div>
                                    <div id="collapse7" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>Depending upon factors like location, plot area etc., Approximate investment for setting up CNG station is INR 1.5 – INR 2.0 Cr. (This excludes cost towards plot of land and working capital).</span>
                                        </div>
                                    </div>
                                </div>
                                  <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse8" data-toggle="collapse"
                                            aria-expanded="true">What is the application fee?</a>
                                    </div>
                                    <div id="collapse8" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>A one-time non-refundable application fee of INR 5 Lakhs + 18% GST is applicable. The same is payable via Demand Draft (DD) in favour of Mahanagar Gas Limited along with application form.</span>
                                        </div>
                                    </div>
                                </div>
                                 <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse9" data-toggle="collapse"
                                            aria-expanded="true">Broadly what are the responsibilities of Applicant and MGL?</a>
                                    </div>
                                    <div id="collapse9" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>- The applicant has to obtain pre and post construction approvals/ licenses necessary to setup and operate the CNG Station.<br>- The applicant has to carry out all civil and electrical works.<br>- MGL is responsible for <br>(i) Installation of gas equipment <br>(ii) To provide gas supply<br> (iii) Training to manpower<br> (iv) MGL shall provide all necessary support and guidance during the process.</span>
                                        </div>
                                    </div>
                                </div>
                                 <div  class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse10" data-toggle="collapse"
                                            aria-expanded="true">Where can I get more details about setting up a CNG Station with MGL?</a>
                                    </div>
                                    <div id="collapse10" class="collapse" data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span>- For more information, please call<br>For Mumbai &amp; Thane Districts: +91 86579 44508<br>For Raigad District: +91 86579 43172</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->