<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/cng-rate-inner-banner.jpg">
    <div class="banner-heading">
        <h1>OEMs New Vehicles</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav</a></li>
                <li class="active" aria-current="page"><a>OEMs New Vehicles</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips> 
                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>

          
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <!-- <div class="mb-30">
                    <img src="assets/images/MGLHalf.jpeg" class="w-70">
                </div> -->
                  <div class="light-blue-bg">
                       <div class="row">
                          <div class="customer-login-page log-meter-reading">

                            <form [formGroup]="promotionForm">
                                 <div class="row">
                                    
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" formControlName="vehicledeliveryDate"
                                                placeholder="Vehicle Delivery Date"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicledeliveryDate'].errors }" />
                                            <div *ngIf="submitted && promotionForm.controls['vehicledeliveryDate'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicledeliveryDate'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label>Vehicle Delivery Date<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                       <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="vehicleNo"
                                                placeholder="Vehicle Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicleNo'].errors }"
                                                id="vehicleNo"/>
                                            <div *ngIf="submitted && promotionForm.controls['vehicleNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleNo'].errors.required">
                                                    Required Field
                                                </div>
                                                 <div *ngIf="promotionForm.controls['vehicleNo'].errors.pattern">
                                                    Invalid Vehicle Number </div>
                                            </div>
                                            <label>Vehicle Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                   
                                     <div class="col-sm-6 form-group">
                                        <label class="font-size11">Delivery note /Insurance copy<span
                                            style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input accept=".pdf" (change)="filechange($event,'delivery')" class="from-control"
                                                type="file" formControlName="delivery_note"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['delivery_note'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['delivery_note'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['delivery_note'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                      <div class="col-sm-6 form-group">
                                        <label class="font-size11">Undertaking copy<span
                                            style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input accept=".pdf" (change)="filechange($event,'undertaking')" class="from-control"
                                                type="file" formControlName="undertaking_copy"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['undertaking_copy'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['undertaking_copy'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['undertaking_copy'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                   
                                      <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="mt-10">
                                                    <div class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Captcha'].errors }"
                                                        id="Captcha" />

                                                    <div *ngIf="submitted && promotionForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="promotionForm.controls['Captcha'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="onSubmit()" class="greenbtn2">Submit <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="text-right md-text-left">
                                            <button (click)="reset()" class="bluebtn2">Reset<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div>
                                    </div>

                                </div>
                                 <div class="text-right mt-30">
                                    <p class="mb-0"><b><i><span class="text-danger">*</span>MGL reserves the right to
                                                ask for any other document if required </i></b></p>
                                    <p class="mb-0"><b><i>Please note all fields marked <span
                                                    class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>
                                <div *ngIf="alertType == 'vehiclepromotion'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                            </form>
                        </div>
                       </div>
                  </div>
            
            </div>
        </div>
    </div>
</div>


