import { Injectable } from '@angular/core';
  import { map, Observable} from 'rxjs';
  import { ApplyOutletResp } from '../models/applyforoutket';
  import { environment } from 'src/environments/environment';
  import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApplyforoutletService {
  baseUrl: any = "";
  ApplicationNo : any;
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  getAll():Observable<ApplyOutletResp[]> {
    return this.http.get<ApplyOutletResp[]>(this.baseUrl+'applyforoutlet/get_applyoutlet');
  }
  getdistrict():Observable<ApplyOutletResp[]> {
    return this.http.get<ApplyOutletResp []>(this.baseUrl+'district/districts');
  }
  getplot():Observable<ApplyOutletResp[]> {
    return this.http.get<ApplyOutletResp[]>(this.baseUrl+'plot/get_plots'); 
  }
   insertapplyforoutlet(data:any) {
    return  this.http.post<ApplyOutletResp>(`${this.baseUrl}applyforoutlet/insert_applyoulet`,data)
  }

   get_outlet_details(data:any) {
    return  this.http.post<ApplyOutletResp>(`${this.baseUrl}applyforoutlet/get_outlet_details`,data)
  }

  insert_applyoulet_level_two(data:any) {
    return  this.http.post<ApplyOutletResp>(`${this.baseUrl}applyforoutlet/insert_applyoulet_level_two`,data)
  }
   
    }
  

