import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Router} from "@angular/router"
import { environment } from 'src/environments/environment';
import { FaqServiceService } from './FAQ/faq-service.service';
import { AreaResp } from '../models/area';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {
  printNameTransfer:any
  ApplicationNo : any

  loginStatus:boolean =false
  baseUrl:string=environment.baseUrl
  loggedIn: boolean = false
  otpStatus : boolean =false

   loggedIn1: boolean = false
   loginStatus1:boolean =false
  
  constructor(private http : HttpClient,private FaqService: FaqServiceService, private router: Router) { 
    console.log('asdlf servie',this.printNameTransfer)
  }

   getbillDesk(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/getbillDesk',data)
        }

  getStockData(){
    return this.http.get(this.baseUrl+'getStockData')
  }

  checkLogin(){
    let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    if(!data){
      this.router.navigate(['/residential-png/customer-zone/customer-login'])
    }else{
      this.loginStatus=true
    }
  }

  checkLogin1(){
    let data1 = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential1')))
    if(!data1){
      this.router.navigate(['/cng/cng-offers-schemes/retrofitters-login'])
    }else{
      this.loginStatus1=true
    }
  }


  registerUser(data:any){
    return this.http.post(this.baseUrl+'reg/insert_reg',data)
  }

  login(data:any){

    return this.http.post(this.baseUrl+'reg/userLogin',data)
  }

   loginwithOTP(data:any){

    return this.http.post(this.baseUrl+'reg/loginwithOTP',data)
  }

  retrofitterslogin(data:any){

    return this.http.post(this.baseUrl+'reg/retrofitterslogin',data)
  }

  checkOtp(data:any){
    return this.http.post(this.baseUrl+'reg/checkOtp',data)
  }

  checkLoginOtp(data:any){
    return this.http.post(this.baseUrl+'reg/checkLoginOtp',data)
  }

  updateOtpToDb(data:any){
    return this.http.post(this.baseUrl+'reg/resendOtp',data)
  }

  sendOtp(otp:any,Number:any){
    let Data = `Dear MGL Customer, ${otp} is OTP for your contact verification.&tempId=1007080798813643220`
    let url = `http://webpostservice.com/sendsms_v2.0/sendsms.php?apikey=QVNDTU1HTDp6UzV5b01tYQ==&type=TEXT&sender=MGLLTD&mobile=${Number}&message=${Data}`
    this.http.post(url,'').subscribe(response=>{
      return alert("Otp Sent")
    })
  }

  getUserAddress(data:any){
    return this.http.post(this.baseUrl+'reg/getUserAddress',data)
  }

   getUserAddressNew(data:any){
    return this.http.post(this.baseUrl+'reg/getUserAddressNew',data)
  }

  updateUserAddress(data:any){
    return this.http.post(this.baseUrl+'reg/updateAddress',data)
  }

  ChangePass(data:any){
    return this.http.post(this.baseUrl+'reg/changeUserPass',data)
  }

  getCaNo(data:any){
    return this.http.post(this.baseUrl+'reg/getCaNo',data)
  }

  getCaNoNew(data:any){
    return this.http.post(this.baseUrl+'reg/getCaNoNew',data)
  }
  
  forgotPassword(cano: any, email: any){
    return this.http.post<AreaResp>(this.baseUrl + 'status/forgotPassword', {cano: cano, email: email})
    .pipe(map(user => {
      return user;
    }));
  }

  getNameTransfer(data:any){
    return this.http.post(this.baseUrl+'reg/getNameTransfer',data)
  }

   getUserReginfo(data:any){
    return this.http.post(this.baseUrl+'transaction/getUserReginfo',data)
  }

    getUserReginfoInstitution(data:any){
    return this.http.post(this.baseUrl+'transaction/getUserReginfoInstitution',data)
  }

   getUserReginfoTest(data:any){
    return this.http.post(this.baseUrl+'transaction/getUserReginfoTest',data)
  }

   openEnach1(data:any){
    return this.http.post(this.baseUrl+'NachMandate',data)
  }

   get_NachMandate_status(data:any){
    return this.http.post(this.baseUrl+'get_NachMandate_status',data)
  }

  getAllCsr(){
    return this.http.get(this.baseUrl+'csr/getAllCsr')
  }

  getpendingAmout(data:any){
    
    return this.http.post(this.baseUrl+'getQRPay',data)
  }

    openEnach(data:any){
    let clientID = 'AITSHSTRGNC1WZJ4TOZZ98TLHTZURNR7'
    let Secret = 'DCALB9HP1IGIOS23H2SA96NB2C37PJ4T'
    let baseurl = clientID + ':' + Secret 
    // let strBase64Encode = Base64Encode(baseurl);
    // console.log(strBase64Encode)
    let headers = new HttpHeaders({
      "content-type": "application/json",
      'Authorization': 'Basic' + btoa(baseurl),

      // "Access-Control-Allow-Origin":"https://ext.digio.in",
      // "Access-Control-Allow-Credentials":"true",
      // "Access-Control-Allow-Methods":"POST, PUT, PATCH, GET, DELETE, OPTIONS",
      // "Access-Control-Allow-Headers":"Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
      
    });
    let Msg = "{\"customer_identifier\":\"" + 'dhruveshkhidake.hoh@gmail.com' + "\"," +
    "\"auth_mode\":\"api\"," +
    "\"mandate_type\":\"create\"," +
    "\"corporate_config_id\":\"" + 'TSE200806083552068PDG76ZHNYMCQ6P' + "\"," +

    "\"mandate_data\":{" +
    "\"maximum_amount\":\"" + 5000.50 + "\"," +
    "\"instrument_type\":\"" + 'debit' + "\"," +
    "\"first_collection_date\":\"" + '2021-10-10' + "\"," +
    "\"is_recurring\":\"true\"," +
    "\"frequency\":\"Adhoc\"," +
    "\"management_category\":\"U003\"," +
    "\"customer_name\":\"" + 'Dhruvesh Khidake' + "\"," +
    "\"customer_account_type\":\"savings\"," +
    "\"customer_ref_number\":\"" + '210000246686' + "\"";

    Msg = Msg + "}}";
    let options = { headers: headers };
// let encoded = encodeURIComponent(uri);
    return this.http.post('https://api.digio.in/v3/client/mandate/create_form',Msg,options)
  }
  
  getUserInfoID(data: any) {
    return this.http.post(this.baseUrl + 'reg/getUserInfoID', {id: data})
  }

  resetUserPass(data: any) {
    return this.http.post(this.baseUrl + 'reg/resetUserPass', data)
  }

   registerNachCancel(data:any){
    return this.http.post(this.baseUrl+'RegNachMandateCancel',data) 
  }

  mglNetworklist(){

    return this.http.post(this.baseUrl+'mgl_network/mglNetworklist',{})
  }

  getUserAppnum(data:any){
    return this.http.post(this.baseUrl+'transaction/getUserAppnum',data)
  }


 postCampaigns(data:any){
        return this.http.post<any>(this.baseUrl+'campaigns/postCampaigns',data)
        }


  //***************Nach Mandate Cancel functionality start*************//
    
     insertNachcancel(data:any){
        return this.http.post(this.baseUrl+'insertNachcancel',data) 
      }
    //***************Nach Mandate Cancel functionality end*************//   





    //**********for app********//

    get_mgl_domestic_app_data(data:any){
    
    return this.http.post(this.baseUrl+'payutransaction/get_mgl_domestic_app_data',data)
  }



   app_user_reg(data:any){
    return this.http.post(this.baseUrl+'reg/app_user_reg',data)
  }

   check_app_user_reg_otp(data:any){
    return this.http.post(this.baseUrl+'reg/check_app_user_reg_otp',data)
  }

}
