<div id="rs-services" class="rs-services style6 bn-service">
  <div class="marketstats">
    <img src="assets/images/market-stat-bg.png" class="mk-bg">
    <div class="marketstats-content">
      <ul>
        <li><!--<i class="demo-icon uil-calling"></i>-->
          <p>Emergency No/ Dial before Dig </p>
          <!-- <h6 > 1800 266 9944 / 6867 4500</h6> -->
          <h6> 1800 266 9944 / 6875 9400</h6>
        </li>
        <li>
          <div class="mid-social">
            <a href=" https://www.facebook.com/mglltdofficial" target="_blank"><i class="fa fa-facebook"></i></a>
            <a href="https://twitter.com/mahanagargas" target="_blank"><i class="fa fa-twitter"></i></a>
            <a href="https://youtu.be/07Jxsttz6X8" target="_blank"><i class="fa fa-youtube-play"></i></a>
            <a href="https://instagram.com/mahanagargas?igshid=YmMyMTA2M2Y=" target="_blank"><i
                class="fa fa-instagram"></i></a>
            <a href="https://in.linkedin.com/company/mglltd" target="_blank"><i class="fa fa-linkedin"></i></a>
          </div>
        </li>
        <!--<li><p>MGL-NSE</p>
              <h6 ><i class="demo-icon uil-chart-line"></i> ₹{{stock.CurrentPrice}} <span [ngStyle]="{'color':NseColors}"  >({{stock.Changes}}%)</span></h6>
          </li>
          <li><p>MGL-BSE</p>
              <h6><i class="demo-icon uil-chart-line"></i> ₹{{stock.BseCurrentPrice}} <span [ngStyle]="{'color':BseColors}" >{{stock.BseChange}}</span> </h6>
          </li>
          <li><p>Call Us on</p>
              <h6><i class="demo-icon uil-phone-alt"></i> 68674500 / 61564500 </h6>
          </li> -->
      </ul>

    </div>
  </div>
  <div class="looking-for industrial-service-sect">
    <img src="assets/images/i-looking-for-bg.svg" class="looking-for-bgimg">
    <div class="looking-for-services">
      <h3>I am <span> looking for</span></h3>
      <ul>
        <li>
          <abbr>
            <img src="assets/images/looking-for/industrial/procedure-for-new-industrial-connection.png">
            <a
              [routerLink]="['/industrial-and-commercial/industrial-png/apply-for-commercial-connection/how-to-apply-ind']">
              <h6>Procedure for New<span>Connection </span></h6>
            </a>
          </abbr>
        </li>

        <li>
          <abbr>
            <img src="assets/images/looking-for/industrial/documents-required.png">
            <a
              [routerLink]="['/industrial-and-commercial/industrial-png/apply-for-commercial-connection/ind-document']">
              <h6>Documents<span>Required</span></h6>
            </a>
          </abbr>
        </li>

        <!-- <li>
          <abbr>
            <img src="assets/images/looking-for/industrial/payment-i&c-bill.png">
            <h6>
              <a [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">Payment </a>
              <span> <a [routerLink]="['/industrial-and-commercial/ic-bill']">I & C Bill</a></span>
            </h6>
          </abbr>
        </li> 
        <li>
          <abbr>
            <img src="assets/images/looking-for/residential-png/QR-pay.png">
            <a [routerLink]="['/industrial-and-commercial/qr-pay']">
              <h6>QR <span>Pay</span></h6>
            </a>
          </abbr>
        </li>-->
        <li>
          <abbr>
            <img src="assets/images/looking-for/industrial/tariff-card.png">
            <a [routerLink]="['/industrial-and-commercial/industrial-png/industrial-tariff-card']"> 
              <h6>Tariff<span>Card</span></h6>
            </a>
          </abbr>
        </li>
        <li>
          <abbr>
            <img src="assets/images/looking-for/industrial/view-your-bill.png">
            <a [routerLink]="['/industrial-and-commercial/ic-bill']">
              <h6>View<span>your Bill</span></h6>
            </a>
          </abbr>
        </li>
        <li>
          <abbr>
            <img src="assets/images/looking-for/industrial/payment-of-industrial-bill.png">
            <a [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">
              <h6>Payment of <span>Industrial Bill</span></h6>
            </a>
          </abbr>
        </li>
        <li>
          <abbr>
            <img src="assets/images/looking-for/industrial/contact-us.png">
            <a [routerLink]="['/industrial-and-commercial/contact-us']">
              <h6>Contact<span>US</span></h6>
            </a>
          </abbr>
        </li>
      </ul>
    </div>
  </div>
</div>