import { Component } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Oempromotion20Service } from 'src/app/Service/oempromotion20.service';

@Component({
  selector: 'app-oemsnewvehicles20leveltwoedit',
  templateUrl: './oemsnewvehicles20leveltwoedit.component.html',
  styleUrls: ['./oemsnewvehicles20leveltwoedit.component.css']
})
export class Oemsnewvehicles20leveltwoeditComponent {
previouspageData:any;

    promotionForm!: FormGroup
   submitted = false;
   captcha: any;

  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';

  deliveryImg:any;
  undertakingImg:any

   constructor(private pageLoaderService: PageLoaderService, 
    private activatedRoute: ActivatedRoute,public router: Router,
    private FormBuilder: FormBuilder, private service: Oempromotion20Service) { 
      this.activatedRoute.queryParams.subscribe((data: any) => {
       // console.log(data);
        this.previouspageData = JSON.parse(data.data);
        //console.log(this.previouspageData);
      });
      
    }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.captchaGenerate()
     this.promotionForm = this.FormBuilder.group({
      refranceNumber: ['', [Validators.required]],
      ContactNumber: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$"), Validators.required])],
      vehicledeliveryDate: ['', [Validators.required]],
      delivery_note: ['', Validators.required],
     // undertaking_copy: ['', Validators.required],
      Captcha: ['', [Validators.required]],
       vehicleNo: ['', Validators.compose([Validators.pattern("^[A-Za-z]{2}[0-9]{1,2}[A-Za-z]{1,2}[0-9]{4}$"), Validators.required])],
     

    });

     this.promotionForm.get('refranceNumber')?.setValue(this.previouspageData.refranceNumber);
     this.promotionForm.get('ContactNumber')?.setValue(this.previouspageData.ContactNumber);
      this.promotionForm.get('vehicledeliveryDate')?.setValue(this.previouspageData.delivery_date);
       this.promotionForm.get('vehicleNo')?.setValue(this.previouspageData.vehicleNo);
        this.promotionForm.get('delivery_note')?.setValue(this.previouspageData.delivery_note);
        // this.promotionForm.get('undertaking_copy')?.setValue(this.previouspageData.undertaking_copy);
     //console.log(this.promotionForm.get('refranceNumber')?.value)

  }

   ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

   captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }


    filechange(element: any, check: any) {
    //console.log(check);
    if (check == 'delivery') {
      this.undertakingImg = element.target.files
      //console.log(this.undertakingImg);
     
     }else if (check == 'undertaking'){
       this.deliveryImg = element.target.files
     // console.log(this.deliveryImg);
       
    }
 }

   reset() {
    this.promotionForm.reset()
    this.submitted = false;
   

  }

  onSubmit(){
     this.submitted = true
    if (this.promotionForm.get('Captcha')?.value != this.captcha) {
      this.promotionForm.get('Captcha')?.setValue('')
    }
    if (this.promotionForm.invalid) {
      return;
    }

    const form = new FormData()
    form.append("refranceNumber", this.promotionForm.get('refranceNumber')?.value)
    form.append("ContactNumber", this.promotionForm.get('ContactNumber')?.value)
    form.append("vehicledeliveryDate", this.promotionForm.get('vehicledeliveryDate')?.value)
    form.append("vehicleNo", this.promotionForm.get('vehicleNo')?.value)
  
 

   // if(this.deliveryImg !=undefined){
   //   let deliveryImgImg = this.deliveryImg[0].name.split('.').pop()
   //  let deliveryImgImg1  =this.deliveryImg[0].name.split('.').slice(0, -1).join('.')+this.previouspageData.panNo+ new Date()+'deliveryImg'+'.'+ deliveryImgImg;
   //  form.append("undertaking_copy", this.deliveryImg[0], deliveryImgImg1)

   // }

    if(this.undertakingImg !=undefined){
     let undertakingImgImg = this.undertakingImg[0].name.split('.').pop()
    let undertakingImgImg1  =this.undertakingImg[0].name.split('.').slice(0, -1).join('.')+this.previouspageData.panNo+ new Date()+'undertakingImg'+'.'+ undertakingImgImg;
    form.append("delivery_note", this.undertakingImg[0], undertakingImgImg1)
   }

   

   

   

        this.service.update_Vehicle_levle_two_OEM(form).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.submitted = false;
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        this.promotionForm.reset();
        setTimeout(() => {
          this.clearAlert()
        this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-oem-dashboard'])
        }, 5000);

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        //  this.promotionForm.reset();
        setTimeout(() => {
          this.clearAlert()

        }, 5000);
      }



    }, err => {
     // console.log(err)
    })


  }

   clearAlert() {
    this.alertType = ''
  }


}

