<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/awards-banner.jpg">
  <div class="banner-heading">
    <h1>Disclaimer</h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a href="#">Home</a></li>
        <li class="active" aria-current="page"><a href="#">Disclaimer</a></li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <app-recent-post></app-recent-post>
        <app-tips></app-tips>
        <div class="mt-30">
          <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
            mahanagargas</a>
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12">
        <div class="mt-40">
          <div class="con">
            <p>The information contained in this website is for general information purposes only. The information is
              provided by Mahanagar Gas Limited and while we endeavour to keep the information up to date and correct,
              we make no representations or warranties of any kind, express or implied, about the completeness,
              accuracy, reliability, suitability or availability with respect to the website or the information,
              products, services, or related graphics contained on the website for any purpose. Any reliance you place
              on such information is therefore strictly at your own risk.</p>
            <p>In no event will we be liable for any loss or damage including without limitation, indirect or
              consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits
              arising out of, or in connection with, the use or performance of this website.</p>
            <p>Through this website you are able to link to other websites which are not under the control of Mahanagar
              Gas Limited. We have no control over the nature, content and availability of those sites. The inclusion of
              any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
            <p>Every effort is made to keep the website up and running smoothly. However, Mahanagar Gas Limited takes no
              responsibility for, and will not be liable for, the website being temporarily unavailable due to technical
              issues beyond our control.</p>
            <h5>Copyright Notice</h5>
            <p>This website and its content is copyright of Mahanagar Gas Limited.</p>
            <p>Any redistribution or reproduction of part or all of the contents in any form is prohibited other than
              the following:</p>
            <div class="blue-tick-list">
              <ul>
                <li>You may print or download to a local hard disk extracts for your personal and non-commercial use
                  only.
                </li>
                <li>You may copy the content to individual third parties for their personal use, but only if you
                  acknowledge the website as the source of the material.</li>
                <li>You shall not, except with our express written permission, distribute or commercially exploit the
                  content. Nor shall you transmit it or store it in any other website or other form of electronic
                  retrieval system.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>