import { Component } from '@angular/core';

@Component({
  selector: 'app-mglcngmaostav20oemdashboard',
  templateUrl: './mglcngmaostav20oemdashboard.component.html',
  styleUrls: ['./mglcngmaostav20oemdashboard.component.css']
})
export class Mglcngmaostav20oemdashboardComponent {

   ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

}
