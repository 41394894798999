<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Retrofitters Dashboard
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>CNG</a></li>
                 <li class=""><a>MGL CNG Mahostav 2.0</a></li>
                <li class="active" aria-current="page"><a>Retrofitters Dashboard</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                	  <div class=" customer-care-overview-icon">

                            <div id="rs-services" class="rs-services style4">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 md-mb-30">
                                        <a [routerLink]="['/cng/cng-offers-schemes/retrofitters20-booking-intimation']">
                                        <div class="services-item text-center">
                                            <div class="services-icon">
                                                <img class="dance_hover"
                                                    src="assets/images/domestic-connection-form-icons/Network-is-Available.png"
                                                    alt="Customer Care">
                                            </div>
                                            <div class="services-content">
                                                <p class="margin-0"><strong>Retrofitter Level 1</strong></p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>


                                    <div class="col-lg-6 col-md-6 md-mb-30">
                                        <a
                                            [routerLink]="['/cng/cng-offers-schemes/retrofitters20-booking-intimation-status']">
                                            <div class="services-item text-center">
                                                <div class="services-icon">
                                                    <img class="dance_hover"
                                                        src="assets/images/domestic-connection-form-icons/No-Network-is-Available.png"
                                                        alt="Distribution Company">
                                                </div>
                                                <div class="services-content">
                                                    <p class="margin-0"><strong>Retrofitter Level 1 Status</strong></p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-lg-6 col-md-6 md-mb-30">
                                        <a
                                            [routerLink]="['/cng/cng-offers-schemes/retrofitters20-bill-processing-status']">
                                            <div class="services-item text-center" style="margin-top: 20px;" >
                                                <div class="services-icon">
                                                    <img class="dance_hover"
                                                        src="assets/images/domestic-connection-form-icons/No-Network-is-Available.png"
                                                        alt="Distribution Company">
                                                </div>
                                                <div class="services-content">
                                                    <p class="margin-0"><strong>Retrofitter Level 2 Status</strong></p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                </div>

                            </div>

                           
                        </div>
                   
                </div>
            </div>
        </div>
    </div>
</div>





