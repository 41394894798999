import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { MenuService } from '../Service/menu.service';
import { Router } from '@angular/router';
import { UserRegistrationService } from '../Service/user-registration.service';
import { CngSavingCalculatorService } from '../Service/cng-saving-calculator.service';


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  dialog:boolean=false
  SearchStatus:boolean=false
  addForm= new FormGroup({
    MainTab: new FormControl(''),
    MenuName : new FormControl('')

  })

  langauge:any[]=[{lang:'English',url:''},{lang:'हिंदी',url:'https://hindi.mahanagargas.com/'},{lang:'मराठी',url:'https://marathi.mahanagargas.com/'}]
  selected:any

  AllMenu:any[]=[]

  FilterAllMenu:any[]=[]
  SubMenu : any[] =[]
  initialData: string[] = [];
  isLogin = false;

  userForm! :FormGroup
  routerUrl: any[] = []
  CngPrice:any
  
  
  constructor(private CngService : CngSavingCalculatorService,private FormBuilder : FormBuilder,private Service : MenuService, public router: Router, public UserService : UserRegistrationService) { 
    this.initialData = this.Service.rootLevelNodes.slice();
    //this.Service.getAllMenus()
  }

  modelChanged(val:any){

    if(this.userForm.get('inputs')?.value.length > 1){
      this.routerUrl = this.router.config.filter((x: any) => {
        return x.data !== undefined
      })
    }else{
      this.routerUrl=[]
    }

  }

  redirecturl(val:any){
    if(val != ''){
      window.open(val,'_blank');
    }
    
  }

  ngOnInit(): void {
    //this.getAllMenu();

    this.getCngPrice()

    this.userForm = this.FormBuilder.group({
      inputs:['']
    })
    
    
	let data = sessionStorage.getItem('UserCredential')
    if(!data){
      this.isLogin = false;
      this.UserService.loginStatus=false
    } else {
      this.UserService.loginStatus=true
    }
  }

  getCngPrice(){
    this.CngService.getCngPrice().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details

      this.CngPrice=data.CurrentPrice
      //console.log(this.CngPrice)
    })
  }


  dialogOpen(){
    if(this.dialog===false){
      this.dialog=true
    }else{
      this.dialog=false
    }
  }

  navigateTo(val:any){
    
    let filtered = this.routerUrl.filter((x:any)=>{
      return x.data.name == val
    })
    this.router.navigate(['/'+filtered[0].path])
    this.openSearch()
    this.userForm.get('inputs')?.setValue('')
    //console.log(filtered)
  }

  openSearch(){
    if(this.SearchStatus===false){
      this.SearchStatus=true
    }else{
      this.SearchStatus=false
    }
  }

  getAllMenu(){

    this.Service.getAllMenu().subscribe((response)=>{
      var data = JSON.parse(JSON.stringify(response));
      
      for(let i of data){
        this.AllMenu.push(i.MainMenu)
        this.FilterAllMenu.push({MainMenu:i.MainMenu.split('>'),SubMenu :i.SubMenu.split('>') ,Child:i.ChildMenu.split('>')})
        
      }
      
      this.FilterAllMenu=[...new Set(this.FilterAllMenu)]
      for(let i of this.FilterAllMenu){
        if(i.MainMenu[0]===i.SubMenu[0]){
          i.SubMenu.shift()
        }
        i.Child.shift()
        // i.Child.filter((x:any)=>{
        //   i.SubMenu.push(x)
        // })
        
      }

      
      
      //console.log(this.FilterAllMenu);

      for(let i of data){
        if(i.ChildMenu){
          this.AllMenu.push(i.SubMenu+i.ChildMenu)
        }

      }
      this.AllMenu=[...new Set(this.AllMenu)]
      let arr = _.uniqBy(this.FilterAllMenu, obj => JSON.stringify(obj.SubMenu))
      
      //[...new Set(())]
      let array=[]

      for(let i of arr){
        array.push(i.SubMenu)
      }

        
      
      })

  }

  checkValue(val:any){
    let result = false
    this.FilterAllMenu.filter((x:any)=>{
      if(x[2]==val){
        result = true
      }
    })
    
    //console.log(result)
  }

  submit(){
    this.Service.postMenu(this.addForm.value)
  }
  
  login(){
	  localStorage.clear();
	  sessionStorage.clear();
          this.UserService.loginStatus=false
          this.router.navigate(['/residential-png/customer-zone/customer-login'])
  }

    login1(){
    localStorage.clear();
    sessionStorage.clear();
          this.UserService.loginStatus1=false
          this.router.navigate(['/cng/cng-offers-schemes/retrofitters-login'])
  }

}
