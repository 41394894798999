<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/cng-rate-inner-banner.jpg">
    <div class="banner-heading">
        <h1>Retrofitment</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav</a></li>
                <li class="active" aria-current="page"><a>Retrofitment</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips> 
                <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div>
            </div>

             <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                 <h5>   Mahanagar Gas Limited launches “MGL CNG Mahotsav”  </h5> 
                  <div class="blue-tick-list">
                     <ul>
                         <!-- <li>Benefits worth INR 19,999/- for new or retrofitted CNG cars</li> -->
                        <li>Incentive of up to INR ₹ 4.5 lakhs for retrofitted CNG Commercial Vehicles</li>
                        <p>Mahanagar Gas Limited (MGL), one of the largest City Gas Distribution (CGD) companies in India, launched a new scheme ‘MGL CNG Mahotsav’ for retrofitment of existing petrol or diesel vehicles to CNG in Mumbai, Thane &amp; Raigad Districts</p>
                        <p>The scheme, which can be availed on retrofitment Commercial CNG vehicles, is launched to further encourage use of CNG, a clean &amp; green energy. Mahanagar Gas Limited has partnered with leading CNG kit distributors to promote quality retro-fitment</p>
                     </ul>
                  </div>      
              <!--   <div class="mb-30">
                    <img src="assets/images/MGL CNG Mahotsav.jpg" class="w-100">
                </div> -->
                 <p>The lucrative scheme offers the Commercial vehicle owners, incentives up to ₹ 4.5 lakhs depending on Vehicle model on retrofitting to CNG as fuel.</p>

                <div class="innerpage-right-content awards-section">
                    <p>
                        The incentive will be available as an upfront Incentive for retrofitment:
                    </p>
                      <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Gross Vehicle Weight (GVW) in Tons</th>
                                    <th>Incentive Value (₹/ Vehicle)</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr>
                                    <td>Commercial Vehicle</td>
                                    <td>> 3.5, < 10 </td>
                                    <td>1,80,000</td>      
                                </tr>
                                <tr>                              
                                    <td>Commercial Vehicle</td>
                                    <td>≥10, < 15 tons</td>
                                    <td>3,15,000</td>      
                                </tr>
                                <tr>                              
                                    <td>Commercial Vehicle</td>
                                    <td>≥15</td>
                                    <td>4,50,000</td>      
                                </tr>
                                 <tr>                              
                                    <td>Pvt Buses</td>
                                    <td></td>
                                    <td>2,70,000</td>      
                                </tr>
                            </tbody>
                        </table>
                    <h3>List of CNG Retrofitters:</h3>
                  <!--   <div class="table-responsive">
                        <p > <span>CNG Kit : LOVATO<br>Distributor : Eco Fuel Systems (I) Pvt Ltd</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th>                                   
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>BLUE DRIVE TRADERS</td>
                                    <td>PLOT NO -195, GURUNNAK NAGAR, LBS MARG, NR MAHINDRA PARK GATE, GHATKOPAR WEST, MUMBAI-86</td> 
                                    <td>7715895555</td>     
                                    <td>CAR & COMMERCIAL VEHICLE</td>     
                                </tr>
                               
                            </tbody>
                        </table>
                        
                    </div> -->
                 <!--    <div class="table-responsive">
                        <p > <span>CNG Kit : GRECCO<br>Distributor : Greenglobe Fuel Solutions Pvt Ltd</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th> 
                                    <th>CONTACT PERAON</th>                                  
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                               
                                <tr>
                                    <td>1</td>
                                    <td>Greenglobe Autotech Pvt. Ltd.</td>
                                    <td>Plot No.36/1/A Opposite Runwal Galleria ICICI Bank, Next To Runwal Olive Off Mulund Goregaon Link Road Subhash Nagar Road, Nahur (West), Mumbai :- 400078</td> 
                                     <td>Manisha / Rajesh</td>
                                    <td>9819749370 / 7718801166</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>EcoDrive CNG Works</td>
                                    <td>15 S.N Park Sec 3 Kharghar Rly Stn Belpada Kharghar 410210</td> 
                                     <td>Santosh Thakkar</td>
                                    <td>9833161076</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Fortune Distributors</td>
                                    <td>32V4+MG7, Klash Vaibhav Building, Shop No 13/14, Beside Dominos Pizza, Vashi Kopar Khairane Rd, Kalash Udyan Sector 11, Kopar Khairane Navi Mumbai :- 4000709</td> <td>Pankaj Aichwar</td>
                                    <td>9326015255</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>Fortune Distributors</td>
                                    <td>Shop No 7, Goodwill Garden CHS Ltd, Near Little World Mall, Plot No 15/16, Sector -8 , Kharghar Navi Mumbai - 410210</td> 
                                     <td>Pankaj Aichwar</td>
                                    <td>9326015255</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>Patil CNG Works</td>
                                    <td>Shop No 8 Karnala Sports Academy, Near Panvel RTO Plot 7, Sector 16 Panvel :- 410206</td> 
                                     <td>Pritam Patil</td>
                                    <td>9867396782 / 7208579505</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>Patil CNG Works</td>
                                    <td>Alibag Road Near Police Station Wadkhal Tal Pen Dist Raigad 402107</td> 
                                     <td>Girdhari / Hitesh</td>
                                    <td>9322176500 / 9890313616</td>     
                                    <td>CAR</td>     
                                </tr>
                                
                                  <tr>
                                    <td>7</td>
                                    <td>Park Associates</td>
                                    <td>Shop No 9 Ground Floor, Himachal Mitra Mandal Bhawan, Behind Ravi Petrol Pump, Pestom Sagar Rd No 5 Chembur (w) Mumbai :- 400089</td> 
                                     <td>Purav Gandhi</td>
                                    <td>9821488787 / 9833571737</td>     
                                    <td>CAR</td>     
                                </tr>
                                
                                  <tr>
                                    <td>8</td>
                                    <td>Fortune Fuel Solutions.</td>
                                    <td>Survey No. 262, Behind New Horizon School Kasarvadavali, Ghodbunder Road, near Anand Nagar, Thane, Maharashtra 400615</td> 
                                     <td>Mr.Ali.</td>
                                    <td>9768112786</td>     
                                    <td>CAR</td>     
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Greenglobe Autotech Pvt Ltd</td>
                                    <td>Akshar residency , Cream Centre Lane, Near JVPD Junction,Parle west, Mumbai</td> 
                                     <td>Piyush Tanna</td>
                                    <td>7718801177</td>     
                                    <td>CAR</td>     
                                </tr>
                            </tbody>
                        </table>
                        
                    </div> -->
                    <div class="table-responsive">
                        <p > <span>CNG Kit : OMNITEK<br>Distributor : Amol Prala Clean Energy Pvt Ltd</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th>                                   
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>BALAJI ENTERPRISES</td>
                                    <td>SURVEY NO. 10, VILLAGE NAWALI, DAHISAR WAKLAN ROAD, THANE - 400612</td> 
                                    <td>9212444949</td>     
                                    <td>COMMERCIAL VEHICLE</td>     
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>MARS IGNITION</td>
                                    <td>SURVEY NO 83, NEAR COCO COLA GODOWN, KAJU PADA\, GHODBUNDER ROAD, THANE - 401107</td> 
                                    <td>9769414648</td>     
                                    <td>COMMERCIAL VEHICLE</td>     
                                </tr>
                                <tr>                              
                                    <td>3</td>
                                    <td>JIOLAT AUTO GAS INDUSTRIES</td>
                                    <td>SURVEY NO.76, RAMDAS WEAR HOUSE, DAHISAR, THANE - 400612</td>      
                                    <td>9161482148</td>      
                                    <td>COMMERCIAL VEHICLE</td>      
                                </tr>
                                 <tr>                              
                                    <td>4</td>
                                    <td>M/s Chunnilal Auto Works</td>
                                    <td>Mulund Goregaon Link Road,Opp. Nirmal Lifestyle,Bhandup (W), Mumbai - 400078</td>      
                                    <td>9322809968</td>      
                                    <td>Commercial Vehicle</td>      
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                     <div class="table-responsive">
                        <p > <span>CNG Kit : Shigan<br>Distributor :Shigan Telematics Private Limited</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th>                                   
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Green Fuel Solutions Pvt. Ltd</td>
                                    <td>Pap/A-275, M.I.D.C., TTC Industrial Area,Mahape, Navi Mumbai-400710</td> 
                                    <td>Mr. Devender Dhingra
                                    9892988752/ 8928689842</td>     
                                    <td>Commercial Vehicle</td>     
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Green Fuel Solutions Pvt. Ltd</td>
                                    <td>Survey No. 96/2,Kalyan Shil Daighar Cross Road,Behind Konkan King Hotel,Khidkali, Post Padle District Thane – 421204</td> 
                                    <td>Mr. Devender Dhingra
                                    9892988752/ 8928689842</td>     
                                    <td>Commercial Vehicle</td>     
                                </tr>
                                 <tr>
                                    <td>3</td>
                                    <td>Apna Motors</td>
                                    <td>78/2, 79/7, Mandavi Pada,B/h. St. Xavier’s High School,Kashigaon, Miraroad (East)Mumbai-401107</td> 
                                    <td>9820878544, 9820878544</td>     
                                    <td>Commercial Vehicle</td>     
                                </tr>
                                 <tr>
                                    <td>4</td>
                                    <td>VKS Product Pvt. Ltd.</td>
                                    <td>Shed 2, Survey no.- 221 Hissa No.-3, Nr. Snehalay Bunglow Behind Western Hotel, Kashimira, Miraroad – East,Thane – 401107</td> 
                                    <td>Mr. Pramod Singh
                                     9820185207, 8291224197</td>     
                                    <td>Commercial Vehicle</td>     
                                </tr>
                            </tbody>
                        </table>
                    </div>
                      <div class="table-responsive">
                        <p > <span>CNG Kit : Advantek<br>Distributor :Advantek Fuel System Pvt. Ltd</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th>                                   
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>M/s Bafna Maxx Refurb Vehicles Pvt Ltd </td>
                                    <td>Survey No – 157/158/159 At & post – Nadhal, Mumbai – Pune Road,Taluka – Khalapur, Dist – Raigad 410 206</td> 
                                    <td>Mr Shivprasad Vernekar 80133 35666</td>     
                                    <td>Commercial Vehicle</td>     
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                   <!--   <div class="table-responsive">
                        <p > <span>CNG  Kit : MOTOZEN<br>Distributor : Motozen Fuel Systems Pvt. Ltd.</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th>                                   
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Automoto Sequential Systems </td>
                                    <td>shop no.788, pazshorickal bhavan naushibhai compound near carmel high school manpada thane west</td> 
                                    <td>9892486781</td>     
                                    <td>CAR</td>     
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Autofuel Solutions</td>
                                    <td>shop no.07, ground floor, narmada apartmnet, next to netevali, kalyan shil road,kalyan-421306</td> 
                                    <td>9892656101</td>     
                                    <td>CAR</td>     
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>Mumbai Auto Gas</td>
                                  <td>building no.19, shop no.865,near hari mandir, guru teg bahadur nagar, mumbai-400022</td>
                                  <td>9773169814</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>Mars Ignition</td>
                                  <td>Shop   No. 3 & 5, Ground Floor, A-Wing, Shikhar Building, Mira Bhayandar   Road, Sangvi Nagar, Mira Road East, Mira Bhayandar, opposite Hatkesh,   Thane, Maharashtra 401107</td>
                                  <td>9833876820</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>5</td>
                                  <td>Shreeji Automobiles</td>
                                  <td>Government Industrial Estate, Plot No. 97 B, near Ajanta Pharma, Kandivali, Charkop, Mumbai, Maharashtra 400067</td>
                                  <td>9867936398</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>Konkan CNG Workshop</td>
                                  <td>RCF kurul colony gate, alibaugh rev danda road, alibaugh-402201</td>
                                  <td>9225771001</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>7</td>
                                  <td>Kohli Motors</td>
                                  <td>Shop No 22, Plot No 47, Shivam Complex, opposite UTI Tower, Sector 19E, Vashi, Mumbai, Maharashtra 400703</td>
                                  <td>9867672382</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>8</td>
                                  <td>Shivam Car Solutions NX</td>
                                  <td>Plot No.5, Jigna Arcade, Shop No.4, juhu tara marg, near Bharat Petrol Pump, Sector 13, Kopar Khairane, Navi Mumbai, Maharashtra 400709</td>
                                  <td>9870455075</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>9</td>
                                  <td>Green Gas Technologies</td>
                                  <td>Ramji Savji Compound, Kon Police Chowke, Near Mental Hospital, Kalyan -Bhiwandi Road, Kongaon, Kalyan(W)</td>
                                  <td>7400660066</td>
                                  <td>CAR</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
               
                 <p>For more information contact our customer care helpline nos. 022 6867 4500 / 6156 4500 or write to us at <a href="mailto:support@mahanagargas.com">support@mahanagargas.com.</a></p>
            </div>

          
           <!--  <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                 <h5>   Mahanagar Gas Limited launches “MGL CNG Mahotsav”  </h5> 
                  <div class="blue-tick-list">
                     <ul>
                         <li>Benefits worth INR 19,999/- for new or retrofitted CNG cars</li>
                        <li>Incentive of up to INR 5 lakhs for new or retrofitted CNG Commercial Vehicles</li>
                        <p>Mahanagar Gas Limited (MGL), one of the largest City Gas Distribution (CGD) companies in India, launched a new scheme ‘MGL CNG Mahotsav’ for purchase of new Compressed Natural Gas (CNG) vehicles as well as for retrofitment of existing petrol or diesel vehicles to CNG in Mumbai, Thane & Raigad Districts </p>
                        <p>The scheme, which can be availed on purchase of cars as well as Commercial CNG vehicles, is launched to further encourage use of CNG, a clean & green energy. Mahanagar Gas Limited has partnered with OEM’s for promoting new CNG vehicles and with leading CNG kit distributors to promote quality retro-fitment</p>
                     </ul>
                  </div>      
                <div class="mb-30">
                    <img src="assets/images/MGLHalf.jpeg" class="w-100">
                </div>
                 <p>The lucrative scheme offers the car owners of select models benefit worth ₹19,999/- and for Commercial vehicle owners, incentives of ₹ 2 to 5 lakhs depending on Vehicle model on retrofitting to CNG as fuel.</p>

                <div class="innerpage-right-content awards-section">
                    <p>
                        The incentive will be available as an upfront Incentive for retrofitment:
                    </p>
                      <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Gross Vehicle Weight (GVW) in Tons</th>
                                    <th>Incentive Value (₹/ Vehicle)</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Car</td>
                                    <td>NA</td>
                                    <td>19,999</td>      
                                </tr>
                                <tr>
                                    <td>Commercial Vehicle</td>
                                    <td>> 3.5, < 10 </td>
                                    <td>2,00,000</td>      
                                </tr>
                                <tr>                              
                                    <td>Commercial Vehicle</td>
                                    <td>≥10, < 15 tons</td>
                                    <td>3,50,000</td>      
                                </tr>
                                <tr>                              
                                    <td>Commercial Vehicle</td>
                                    <td>≥15</td>
                                    <td>5,00,000</td>      
                                </tr>
                                 <tr>                              
                                    <td>Pvt Buses</td>
                                    <td></td>
                                    <td>3,00,000</td>      
                                </tr>
                            </tbody>
                        </table>
                    <h3>List of CNG Retrofitters:</h3>
                    <div class="table-responsive">
                        <p > <span>CNG Kit : LOVATO<br>Distributor : Eco Fuel Systems (I) Pvt Ltd</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th>                                   
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>BLUE DRIVE TRADERS</td>
                                    <td>PLOT NO -195, GURUNNAK NAGAR, LBS MARG, NR MAHINDRA PARK GATE, GHATKOPAR WEST, MUMBAI-86</td> 
                                    <td>7715895555</td>     
                                    <td>CAR & COMMERCIAL VEHICLE</td>     
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>BLUE DRIVE TRADERS THANE</td>
                                    <td>SHOP NO -1, PLOT NO -12, C-8, NR PASSPORT OFFICE, WAGLE IND ESTATE, THANE</td> 
                                    <td>7715895555</td>     
                                    <td>CAR</td>     
                                </tr>
                                <tr>                              
                                    <td>3</td>
                                    <td>ECO FUEL SOLUTION</td>
                                    <td>SHOP NO -3, BUS STOP-2, KALYAN SHILPHATA RD, OPP SONARPADA, DOMBIVALI E</td>      
                                    <td>9820093535</td>      
                                    <td>CAR</td>      
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>ECO TECH NX</td>
                                    <td>SHOP NO -14, THARWANI HERITAGE, SEC-7, PLOT NO -24, NEXT TO KHARGHAR POLICE STN, KHARGHAR</td>      
                                    <td>9820093535</td>      
                                    <td>CAR</td>       
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>SUMAN ENTERPRISES</td>
                                    <td>SHOP NO -1273, BHAGAT SINGH NGAR NO -2, NEW LINK RD, MOTILAL NAGARNO-2, GOREGAON W, MUMBAI-400104</td>      
                                    <td>9820093535</td>      
                                    <td>CAR</td>   
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>SHANTI ENTERPRISES</td>
                                    <td>SHOP NO -1&2, JAISWAL COMPD, NEXT TO INDUSIND BANK, S V ROAD, BORIVALI (W), MUMBAI-92</td>      
                                    <td>9820222611</td>      
                                    <td>CAR</td>      
                                </tr>
                               
                                <tr>
                                    <td>7</td>
                                    <td>N S AUTO</td>
                                    <td>PLOT NO -30, SEC-15, NEW PANVEL</td>      
                                    <td>9819953910</td>      
                                    <td>CAR</td>     
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>RAJU AUTO GARAGE</td>
                                    <td>AZAD ROAD, NR FIRE BRIGADE, VILE PARLE E, MUMBAI-400057</td>      
                                    <td>9320083357</td>      
                                    <td>CAR</td>     
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                    <div class="table-responsive">
                        <p > <span>CNG Kit : GRECCO<br>Distributor : Greenglobe Fuel Solutions Pvt Ltd</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th> 
                                    <th>CONTACT PERAON</th>                                  
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                               
                                <tr>
                                    <td>1</td>
                                    <td>Greenglobe Autotech Pvt. Ltd.</td>
                                    <td>Plot No.36/1/A Opposite Runwal Galleria ICICI Bank, Next To Runwal Olive Off Mulund Goregaon Link Road Subhash Nagar Road, Nahur (West), Mumbai :- 400078</td> 
                                     <td>Manisha / Rajesh</td>
                                    <td>9819749370 / 7718801166</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>EcoDrive CNG Works</td>
                                    <td>15 S.N Park Sec 3 Kharghar Rly Stn Belpada Kharghar 410210</td> 
                                     <td>Santosh Thakkar</td>
                                    <td>9833161076</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Fortune Distributors</td>
                                    <td>32V4+MG7, Klash Vaibhav Building, Shop No 13/14, Beside Dominos Pizza, Vashi Kopar Khairane Rd, Kalash Udyan Sector 11, Kopar Khairane Navi Mumbai :- 4000709</td> <td>Pankaj Aichwar</td>
                                    <td>9326015255</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>Fortune Distributors</td>
                                    <td>Shop No 7, Goodwill Garden CHS Ltd, Near Little World Mall, Plot No 15/16, Sector -8 , Kharghar Navi Mumbai - 410210</td> 
                                     <td>Pankaj Aichwar</td>
                                    <td>9326015255</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>Patil CNG Works</td>
                                    <td>Shop No 8 Karnala Sports Academy, Near Panvel RTO Plot 7, Sector 16 Panvel :- 410206</td> 
                                     <td>Pritam Patil</td>
                                    <td>9867396782 / 7208579505</td>     
                                    <td>CAR</td>     
                                </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>Patil CNG Works</td>
                                    <td>Alibag Road Near Police Station Wadkhal Tal Pen Dist Raigad 402107</td> 
                                     <td>Girdhari / Hitesh</td>
                                    <td>9322176500 / 9890313616</td>     
                                    <td>CAR</td>     
                                </tr>
                                
                                  <tr>
                                    <td>7</td>
                                    <td>Park Associates</td>
                                    <td>Shop No 9 Ground Floor, Himachal Mitra Mandal Bhawan, Behind Ravi Petrol Pump, Pestom Sagar Rd No 5 Chembur (w) Mumbai :- 400089</td> 
                                     <td>Purav Gandhi</td>
                                    <td>9821488787 / 9833571737</td>     
                                    <td>CAR</td>     
                                </tr>
                                
                                  <tr>
                                    <td>8</td>
                                    <td>Fortune Fuel Solutions.</td>
                                    <td>Survey No. 262, Behind New Horizon School Kasarvadavali, Ghodbunder Road, near Anand Nagar, Thane, Maharashtra 400615</td> 
                                     <td>Mr.Ali.</td>
                                    <td>9768112786</td>     
                                    <td>CAR</td>     
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Greenglobe Autotech Pvt Ltd</td>
                                    <td>Akshar residency , Cream Centre Lane, Near JVPD Junction,Parle west, Mumbai</td> 
                                     <td>Piyush Tanna</td>
                                    <td>7718801177</td>     
                                    <td>CAR</td>     
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                    <div class="table-responsive">
                        <p > <span>CNG Kit : OMNITEK<br>Distributor : Amol Prala Clean Energy Pvt Ltd</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th>                                   
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>BALAJI ENTERPRISES</td>
                                    <td>SURVEY NO. 10, VILLAGE NAWALI, DAHISAR WAKLAN ROAD, THANE - 400612</td> 
                                    <td>9212444949</td>     
                                    <td>COMMERCIAL VEHICLE</td>     
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>MARS IGNITION</td>
                                    <td>SURVEY NO 83, NEAR COCO COLA GODOWN, KAJU PADA\, GHODBUNDER ROAD, THANE - 401107</td> 
                                    <td>9769414648</td>     
                                    <td>COMMERCIAL VEHICLE</td>     
                                </tr>
                                <tr>                              
                                    <td>3</td>
                                    <td>JIOLAT AUTO GAS INDUSTRIES</td>
                                    <td>SURVEY NO.76, RAMDAS WEAR HOUSE, DAHISAR, THANE - 400612</td>      
                                    <td>9161482148</td>      
                                    <td>COMMERCIAL VEHICLE</td>      
                                </tr>
                                 <tr>                              
                                    <td>4</td>
                                    <td>M/s Chunnilal Auto Works</td>
                                    <td>Mulund Goregaon Link Road,Opp. Nirmal Lifestyle,Bhandup (W), Mumbai - 400078</td>      
                                    <td>09322809968</td>      
                                    <td>Commercial Vehicle</td>      
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                     <div class="table-responsive">
                        <p > <span>CNG Kit : Shigan<br>Distributor :Shigan Telematics Private Limited</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th>                                   
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Green Fuel Solutions Pvt. Ltd</td>
                                    <td>Pap/A-275, M.I.D.C., TTC Industrial Area,Mahape, Navi Mumbai-400710</td> 
                                    <td>Mr. Devender Dhingra
                                    9892988752/ 8928689842</td>     
                                    <td>Commercial Vehicle</td>     
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Green Fuel Solutions Pvt. Ltd</td>
                                    <td>Survey No. 96/2,Kalyan Shil Daighar Cross Road,Behind Konkan King Hotel,Khidkali, Post Padle District Thane – 421204</td> 
                                    <td>Mr. Devender Dhingra
                                    9892988752/ 8928689842</td>     
                                    <td>Commercial Vehicle</td>     
                                </tr>
                                 <tr>
                                    <td>2</td>
                                    <td>Apna Motors</td>
                                    <td>78/2, 79/7, Mandavi Pada,B/h. St. Xavier’s High School,Kashigaon, Miraroad (East)Mumbai-401107</td> 
                                    <td>9820878544, 9820878544</td>     
                                    <td>Commercial Vehicle</td>     
                                </tr>
                            </tbody>
                        </table>
                    </div>
                     <div class="table-responsive">
                        <p > <span>CNG  Kit : MOTOZEN<br>Distributor : Motozen Fuel Systems Pvt. Ltd.</span></p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR.NO.</th>
                                    <th>NAME OF RETROFITMENT CENTER</th>
                                    <th>ADDRESS</th>                                   
                                    <th>CONTACT NO</th>                                   
                                    <th>VEHICLE SEGMENT</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Automoto Sequential Systems </td>
                                    <td>shop no.788, pazshorickal bhavan naushibhai compound near carmel high school manpada thane west</td> 
                                    <td>9892486781</td>     
                                    <td>CAR</td>     
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Autofuel Solutions</td>
                                    <td>shop no.07, ground floor, narmada apartmnet, next to netevali, kalyan shil road,kalyan-421306</td> 
                                    <td>9892656101</td>     
                                    <td>CAR</td>     
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>Mumbai Auto Gas</td>
                                  <td>building no.19, shop no.865,near hari mandir, guru teg bahadur nagar, mumbai-400022</td>
                                  <td>9773169814</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>Mars Ignition</td>
                                  <td>Shop   No. 3 & 5, Ground Floor, A-Wing, Shikhar Building, Mira Bhayandar   Road, Sangvi Nagar, Mira Road East, Mira Bhayandar, opposite Hatkesh,   Thane, Maharashtra 401107</td>
                                  <td>9833876820</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>5</td>
                                  <td>Shreeji Automobiles</td>
                                  <td>Government Industrial Estate, Plot No. 97 B, near Ajanta Pharma, Kandivali, Charkop, Mumbai, Maharashtra 400067</td>
                                  <td>9867936398</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>Konkan CNG Workshop</td>
                                  <td>RCF kurul colony gate, alibaugh rev danda road, alibaugh-402201</td>
                                  <td>9225771001</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>7</td>
                                  <td>Kohli Motors</td>
                                  <td>Shop No 22, Plot No 47, Shivam Complex, opposite UTI Tower, Sector 19E, Vashi, Mumbai, Maharashtra 400703</td>
                                  <td>9867672382</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>8</td>
                                  <td>Shivam Car Solutions NX</td>
                                  <td>Plot No.5, Jigna Arcade, Shop No.4, juhu tara marg, near Bharat Petrol Pump, Sector 13, Kopar Khairane, Navi Mumbai, Maharashtra 400709</td>
                                  <td>9870455075</td>
                                  <td>CAR</td>
                                </tr>
                                <tr>
                                  <td>9</td>
                                  <td>Green Gas Technologies</td>
                                  <td>Ramji Savji Compound, Kon Police Chowke, Near Mental Hospital, Kalyan -Bhiwandi Road, Kongaon, Kalyan(W)</td>
                                  <td>7400660066</td>
                                  <td>CAR</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <p>For more information  contact our customer care helpline nos. 022 6867 4500 / 6156 4500 or write to us at support@mahanagargas.com.</p>
            </div> -->

        </div>
    </div>
</div>
